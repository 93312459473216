<template>
    <v-app :style="{ background: $vuetify.theme.themes[theme].background }">
        <NavBar username="usn" />
        <SideBar />
        <v-main class="main-container">
            <v-container fluid style="width: 91%" dense>
                <!-- Note for navigating to this page.
                The lane drilldown is reliant on the stateAPI property "lane" being populated with the correct id,
                due to using the monolithic utils getData function. This is done in the Search.vue and DataTable.vue components.
                This is cursed but a fix is likely to be bundled with a refactor of the query system.
            -->
                <LaneDrilldown
                    :drilldown_object="drilldownObject"
                    :prop_broker="isBrokerUser"
                    :crumbs_prop="crumbs"
                    @stopLoading="isLoading = false"
                />
            </v-container>
        </v-main>
        <SplashScreen style="z-index: 220" :is_loading="isLoading" />
    </v-app>
</template>

<script>
import NavBar from '../components/NavBar';
import SideBar from '../components/SideBar';
import SplashScreen from '../components/SplashScreen';
import LaneDrilldown from '../components/LaneDrilldown';
import * as user_analytics from '../analytics/sendAnalyticsEvent';
import * as format from '../formatShipmentData';
import * as globalVariables from '../globalVariables';
import * as stateAPI from '../stateAPI';
import * as utils from '../utils';
import * as computeKeyMetrics from '../computeKeyMetrics';
import _ from 'lodash';

export default {
    name: 'Drilldown',
    props: ['prop_broker', 'prop_sb_id'],

    components: {
        NavBar,
        SideBar,
        SplashScreen,
        LaneDrilldown
    },

    computed: {
        theme() {
            return this.$vuetify.theme.dark ? 'dark' : 'light';
        }
    },

    methods: {
        formatScore: format.formatScore,
        formatPercent: format.formatPercent,
        formatDollars: format.formatDollars,
        formatDecimal: format.formatDecimal,

        getURLParams() {
            var url_string = window.location.href;
            var url = new URL(url_string);
            var c;
            c = url.searchParams.get('lane');
            return c.replace(/_/g, ' ');
        },

        getURLEquipmentType() {
            var url_string = window.location.href;
            var url = new URL(url_string);

            return url.searchParams.get('equipment_type');
        },

        parseURLPathName(isShipmentTableChange = false) {
            this.queryInProgress = isShipmentTableChange ? false : true;
            this.lane = this.getURLParams();
            this.drilldownObject = {
                origin: this.lane.split(' -> ')[0],
                destination: this.lane.split(' -> ')[1],
                equipmenttype: this.getURLEquipmentType()
            };
        },

        /**
         * refresh crumbs and the crumb ids
         * set broker/lane/shipper id depending on drilldowqn
         */
        getBreadCrumb() {
            var matchingIndex = null;
            var crumbIds = stateAPI.getStateProperty(this, 'crumbIds');

            var crumbText = this.lane;
            if (
                this.lane_equipment_type !== null &&
                this.lane_equipment_type !== undefined
            ) {
                crumbText = this.lane_equipment_type + ': ' + this.lane;
            }

            this.crumbs.forEach((crumb) => {
                if (crumb.text == crumbText) {
                    matchingIndex = this.crumbs.indexOf(crumb);
                }
                crumb.disabled = false;
            });

            if (matchingIndex != null) {
                // set crumbs
                this.crumbs.splice(
                    matchingIndex + 1,
                    this.crumbs.length - matchingIndex
                );
                this.crumbs[this.crumbs.length - 1].disabled = true;
                stateAPI.setStateProperty(this, 'crumbs', this.crumbs);

                // set crumb ids and broker/lane id
                crumbIds.splice(
                    matchingIndex + 1,
                    crumbIds.length - matchingIndex
                );
                var currCrumb = this.crumbs[this.crumbs.length - 1];
                var currCrumbId = crumbIds[crumbIds.length - 1];
                stateAPI.setStateProperty(this, 'lane', currCrumbId);
                stateAPI.setStateProperty(this, 'crumbIds', crumbIds);
                return;
            }

            if (this.crumbs.length >= 4) {
                this.crumbs.splice(3, 1);
                crumbIds.splice(3, 1);
            }

            const currentURL = new URL(window.location.href);
            this.crumbs.push({
                text: crumbText,
                disabled: true,
                href: currentURL.pathname + currentURL.search,
                equipmentType: this.lane_equipment_type
            });

            crumbIds.push(stateAPI.getStateProperty(this, 'lane'));
            stateAPI.setStateProperty(this, 'crumbs', this.crumbs);
            stateAPI.setStateProperty(this, 'crumbIds', crumbIds);
        }
    },

    beforeMount() {
        this.parseURLPathName();
        this.getBreadCrumb();
        this.getURLParams();

        var url = new URL(window.location.href);
        var page_title;
        page_title = 'Lane Drilldown';

        // Send pageView analytics event
        const email = stateAPI.getStateProperty(this, 'email');
        const company = stateAPI.getStateProperty(this, 'company');
        const role = stateAPI.getStateProperty(this, 'role');
        user_analytics.sendEvent(email, company, role, 'pageView', {
            pageSource: page_title.replaceAll(' ', '')
        });
    },

    mounted() {
        var metric_el = document.getElementById('remove-v-tooltip');
        if (metric_el) {
            metric_el.classList.remove('v-tooltip');
            metric_el.classList.remove('v-tooltip--top');
        }
    },

    data: function () {
        return {
            drilldownObject: null,
            isLoading: true,
            crumbs: stateAPI.getStateProperty(this, 'crumbs'),
            lane: '',
            isBrokerUser: stateAPI.getStateProperty(this, 'role') == 'broker',
            queryInProgress: false
        };
    }
};
</script>
<style>
.costSpendGraph {
    transition: 250ms;
    width: 100%;
}

.volumeGraph {
    transition: 250ms;
    width: 100%;
}
</style>
