<template>
    <v-menu top offset-y>
        <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" icon v-bind="attrs" v-on="on" x-small>
                <v-icon color="grey lighten-1">
                    mdi-information-variant-circle-outline
                </v-icon>
            </v-btn>
        </template>

        <v-card style="background-color: #5d5b5be6 !important" elevation="0">
            <v-card-subtitle class="pa-2" style="color: white">
                {{ tooltipText }}
            </v-card-subtitle>
        </v-card>
    </v-menu>
</template>
<script>
export default {
    name: 'InfoTooltip',
    props: ['tooltip_text'],

    watch: {
        tooltip_text(val) {
            this.tooltipText = val;
        }
    },
    data: function () {
        return {
            tooltipText: this.tooltip_text
        };
    }
};
</script>
