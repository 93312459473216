<template>
    <v-app :style="{ background: $vuetify.theme.themes[theme].background }">
        <NavBar :prop_username="usn" />
        <SideBar />
        <v-main id="CalculatorPage" class="main-container">
            <v-container fluid>
                <v-row dense>
                    <v-col cols="12">
                        <v-card elevation="5">
                            <v-card-title class="font-weight-light">
                                <v-icon
                                    v-if="!isBrokerUser"
                                    large
                                    :color="
                                        $vuetify.theme.themes[theme].headerIcon
                                    "
                                    class="mr-4"
                                    >$calc</v-icon
                                >
                                Pricing Calculator
                                <v-spacer></v-spacer>
                                <v-card-actions class="pb-0">
                                    <v-text-field
                                        id="fscField"
                                        class="fscField"
                                        v-model="fuelSurcharge"
                                        label="Fuel Surcharge"
                                        @focus="validateFSC"
                                        @input="validateFSC"
                                        @blur="blurFSC"
                                        dense
                                        :counter="false"
                                        style="width: 79px"
                                        type="number"
                                    >
                                        <template #prefix>$</template>
                                    </v-text-field>
                                </v-card-actions>
                                <v-col cols="3">
                                    <v-card-actions class="mr-4">
                                        <v-autocomplete
                                            :filter="customFilter"
                                            :items="searchData"
                                            :label="`Search for a ${
                                                isBrokerUser
                                                    ? 'Shipper'
                                                    : 'Broker'
                                            }`"
                                            hide-details
                                            dense
                                            item-text="name"
                                            item-value="id"
                                            v-model="selectedTarget"
                                            style="color: black !important"
                                            class="mt-n2"
                                        >
                                            <template>
                                                <v-icon
                                                    color="blue darken-4"
                                                    style="margin-right: 10px"
                                                    >mdi-lan-connect</v-icon
                                                >
                                            </template>
                                        </v-autocomplete>
                                    </v-card-actions>
                                </v-col>
                                <v-card-actions class="mr-4 pb-0">
                                    <v-menu :close-on-content-click="false">
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-text-field
                                                v-model="projectDateDisplay"
                                                style="width: 115px"
                                                label="Project until"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                dense
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-card>
                                            <v-date-picker
                                                v-model="projectDate"
                                                @input="loadPage"
                                                :allowed-dates="futureAllowed"
                                                reactive
                                            ></v-date-picker>
                                        </v-card>
                                    </v-menu>
                                </v-card-actions>
                                <v-card-actions class="pb-0">
                                    <v-text-field
                                        class="volumeFilter"
                                        v-model="volumeThreshold"
                                        label="Volume Threshold"
                                        @blur="volumeFilterBlur"
                                        @focus="volumeFilterFocus"
                                        dense
                                        :counter="false"
                                        style="width: 100px"
                                        type="number"
                                    ></v-text-field>
                                </v-card-actions>
                            </v-card-title>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <!-- data table -->
                        <v-card elevation="5" height="100%">
                            <v-card-title class="font-weight-light pr-0">
                                <v-row class="ma-4 pr-0">
                                    Calculator
                                    <v-menu
                                        content-class="elevation-2"
                                        offset-x
                                    >
                                        <template #activator="{ on, attrs }">
                                            <v-btn
                                                icon
                                                v-bind="attrs"
                                                v-on="on"
                                                :ripple="false"
                                                class="dateRangeBtn"
                                                plain
                                            >
                                                <v-icon
                                                    class="dateRangeBtn"
                                                    size="20px"
                                                    >mdi-information-outline</v-icon
                                                >
                                            </v-btn>
                                        </template>
                                        <v-card elevation-0 width="440px">
                                            <v-card-text>
                                                The fields shown below span from
                                                {{ startDate.split('-')[1] }}-{{
                                                    startDate.split('-')[2]
                                                }}-{{ startDate.split('-')[0] }}
                                                to
                                                {{
                                                    curDateISO.split('-')[1]
                                                }}-{{
                                                    curDateISO.split('-')[2]
                                                }}-{{
                                                    curDateISO.split('-')[0]
                                                }}
                                                and are used to generate volume
                                                and spend projections.
                                            </v-card-text>
                                        </v-card>
                                    </v-menu>
                                    <v-spacer></v-spacer>
                                    <v-chip
                                        class="ml-12 mr-4"
                                        :color="getTotalProjectedChangeColor()"
                                    >
                                        Total Projected Change:
                                        {{
                                            this.selectedTarget === undefined
                                                ? '--'
                                                : this.formatDollars(
                                                      this.totalChange,
                                                      2
                                                  )
                                        }}</v-chip
                                    >
                                    <v-divider vertical></v-divider>
                                    <v-card flat>
                                        <v-card-title
                                            class="pt-0 pb-2 font-weight-light"
                                            style="font-size: 1rem"
                                            >Projected Aggregates
                                            <v-menu
                                                offset-y
                                                content-class="elevation-2"
                                            >
                                                <template
                                                    #activator="{ on, attrs }"
                                                >
                                                    <v-btn
                                                        icon
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        :ripple="false"
                                                        class="dateRangeBtn"
                                                        style="
                                                            background-color: transparent !important;
                                                            box-shadow: 0px 0px !important;
                                                        "
                                                        plain
                                                    >
                                                        <v-icon
                                                            class="dateRangeBtn"
                                                            size="20px"
                                                            >mdi-information-outline</v-icon
                                                        >
                                                    </v-btn>
                                                </template>
                                                <v-card width="440px">
                                                    <v-card-text>
                                                        These are aggregate
                                                        projections from
                                                        {{
                                                            aggStartDateISO.split(
                                                                '-'
                                                            )[1]
                                                        }}-{{
                                                            aggStartDateISO.split(
                                                                '-'
                                                            )[2]
                                                        }}-{{
                                                            aggStartDateISO.split(
                                                                '-'
                                                            )[0]
                                                        }}
                                                        to
                                                        {{
                                                            projectDate.split(
                                                                '-'
                                                            )[1]
                                                        }}-{{
                                                            projectDate.split(
                                                                '-'
                                                            )[2]
                                                        }}-{{
                                                            projectDate.split(
                                                                '-'
                                                            )[0]
                                                        }}. You can adjust
                                                        defaults in the settings
                                                        page, or customize the
                                                        projection end date
                                                        above.</v-card-text
                                                    >
                                                </v-card>
                                            </v-menu>
                                            <v-chip
                                                class="ml-4"
                                                :color="
                                                    this.theme == 'light'
                                                        ? 'blue lighten-5'
                                                        : '#454647'
                                                "
                                            >
                                                % Margin:
                                                {{
                                                    this.avgMarginPercent
                                                }}</v-chip
                                            >
                                            <v-chip
                                                class="ml-4"
                                                :color="
                                                    this.theme == 'light'
                                                        ? 'blue lighten-5'
                                                        : '#454647'
                                                "
                                            >
                                                Flat Margin:
                                                {{ this.avgMarginFlat }}</v-chip
                                            >
                                            <v-chip
                                                class="ml-4"
                                                :color="
                                                    this.theme == 'light'
                                                        ? 'blue lighten-5'
                                                        : '#454647'
                                                "
                                            >
                                                Volume:
                                                {{ this.totalVol }}</v-chip
                                            ></v-card-title
                                        >
                                    </v-card>
                                    <v-divider vertical></v-divider>

                                    <!-- Export Button -->
                                    <v-card flat>
                                        <v-card-title
                                            class="pb-0 pt-1 pr-0 pl-1 mt-n2 font-weight-light"
                                            style="font-size: 1rem"
                                        >
                                            <v-dialog
                                                v-model="dialog"
                                                max-width="290"
                                            >
                                                <v-card>
                                                    <v-card-title
                                                        class="text-h5"
                                                    >
                                                        Export Proposal?
                                                    </v-card-title>
                                                    <v-card-text>
                                                        You have not entered an
                                                        estimated fuel
                                                        surcharge, so your
                                                        proposal is an all in
                                                        rate. Are you sure you
                                                        want to export?
                                                    </v-card-text>

                                                    <v-card-actions>
                                                        <v-spacer></v-spacer>

                                                        <v-btn
                                                            color="red darken-1"
                                                            text
                                                            @click="
                                                                dialog = false
                                                            "
                                                        >
                                                            No
                                                        </v-btn>

                                                        <v-btn
                                                            color="green darken-1"
                                                            text
                                                            @click="
                                                                createProposalExport
                                                            "
                                                        >
                                                            Yes
                                                        </v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-dialog>
                                            <v-menu
                                                v-model="menu"
                                                close-on-content-click
                                                offset-y
                                            >
                                                <template
                                                    v-slot:activator="{
                                                        on,
                                                        attrs
                                                    }"
                                                >
                                                    <v-btn
                                                        icon
                                                        large
                                                        color="#8c9eff"
                                                        :disabled="
                                                            Object.keys(
                                                                laneCache
                                                            ).length == 0
                                                                ? true
                                                                : false
                                                        "
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    >
                                                        <v-icon
                                                            >mdi-tray-arrow-down</v-icon
                                                        >
                                                    </v-btn>
                                                </template>

                                                <v-card elevation="0" rounded>
                                                    <v-list
                                                        style="
                                                            border-top: 1px
                                                                solid lightgrey;
                                                        "
                                                    >
                                                        <v-list-item
                                                            @click="
                                                                this.showDialogOnExport
                                                            "
                                                        >
                                                            <v-list-item-title
                                                                >Proposal
                                                                Export</v-list-item-title
                                                            >
                                                        </v-list-item>
                                                        <v-list-item
                                                            @click="
                                                                this.createExport
                                                            "
                                                        >
                                                            <v-list-item-title
                                                                >Full
                                                                Export</v-list-item-title
                                                            >
                                                        </v-list-item>
                                                    </v-list>
                                                </v-card>
                                            </v-menu>
                                        </v-card-title>
                                    </v-card>
                                </v-row>
                            </v-card-title>
                            <v-row>
                                <v-col cols="12" class="pt-3">
                                    <v-data-table
                                        ref="table"
                                        :headers="headers"
                                        class="elevation-0 calcTable pl-8 pr-8"
                                        :footer-props="{
                                            'items-per-page-options': [
                                                10, 25, 50, 100
                                            ]
                                        }"
                                        :server-items-length="totalLength"
                                        dense
                                        :items="lanes"
                                        :items-per-page="pageSize"
                                        @update:options="updatePageOptions"
                                        :sort-by.sync="sortColumn"
                                        :sort-desc.sync="sortDesc"
                                        :header-props="{ sortIcon: null }"
                                        item-key="laneId"
                                        :expanded.sync="expanded"
                                    >
                                        <template #item.arrow>
                                            <v-icon
                                                >mdi-arrow-right-thin</v-icon
                                            >
                                        </template>
                                        <template #item.details="{ item }">
                                            <v-menu offset-y nudge-bottom="10">
                                                <template
                                                    #activator="{ on, attrs }"
                                                >
                                                    <v-btn
                                                        color="primary"
                                                        dark
                                                        x-small
                                                        plain
                                                        icon
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    >
                                                        <v-icon>
                                                            mdi-list-box-outline
                                                        </v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-card class="pb-2">
                                                    <v-card-title class="mb-2">
                                                        {{ item.origin }} to
                                                        {{ item.destination }} -
                                                        {{ item.equipmenttype }}
                                                    </v-card-title>
                                                    <v-card-subtitle
                                                        class="mb-4"
                                                    >
                                                        Lane History from
                                                        {{
                                                            startDate.split(
                                                                '-'
                                                            )[1]
                                                        }}-{{
                                                            startDate.split(
                                                                '-'
                                                            )[2]
                                                        }}-{{
                                                            startDate.split(
                                                                '-'
                                                            )[0]
                                                        }}
                                                        to
                                                        {{
                                                            curDateISO.split(
                                                                '-'
                                                            )[1]
                                                        }}-{{
                                                            curDateISO.split(
                                                                '-'
                                                            )[2]
                                                        }}-{{
                                                            curDateISO.split(
                                                                '-'
                                                            )[0]
                                                        }}
                                                    </v-card-subtitle>
                                                    <v-card-text>
                                                        <v-row>
                                                            <!-- <v-col cols="4"
                            v-for="(value, key) in detailsToShow(item.hist)"
                            :key="key"
                          >
                            <v-row v-if="key === 'volume'" class="justify-center metricStyle">
                              {{ fullDetailMetadata[key].formatter(value, 0) }}
                            </v-row>
                            <v-row v-else class="justify-center metricStyle">
                              {{ fullDetailMetadata[key].formatter(value, 2) }}
                            </v-row>
                            <v-row class="justify-center font-weight-light pb-4">
                              {{ fullDetailMetadata[key].name }}
                            </v-row>
                          </v-col> -->
                                                            <v-col
                                                                cols="4"
                                                                v-for="(
                                                                    value, key
                                                                ) in detailsToShow(
                                                                    item
                                                                )"
                                                                :key="key"
                                                            >
                                                                <v-row
                                                                    v-if="
                                                                        key ===
                                                                        'volume'
                                                                    "
                                                                    class="justify-center metricStyle"
                                                                >
                                                                    {{
                                                                        fullDetailMetadata[
                                                                            key
                                                                        ].formatter(
                                                                            value,
                                                                            0
                                                                        )
                                                                    }}
                                                                </v-row>
                                                                <v-row
                                                                    v-else
                                                                    class="justify-center metricStyle"
                                                                >
                                                                    {{
                                                                        fullDetailMetadata[
                                                                            key
                                                                        ].formatter(
                                                                            value,
                                                                            2
                                                                        )
                                                                    }}
                                                                </v-row>
                                                                <v-row
                                                                    class="justify-center font-weight-light pb-4"
                                                                >
                                                                    {{
                                                                        fullDetailMetadata[
                                                                            key
                                                                        ].name
                                                                    }}
                                                                </v-row>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-text>
                                                </v-card>
                                            </v-menu>
                                        </template>

                                        <template
                                            v-slot:header.new_spend="{ header }"
                                        >
                                            {{
                                                (header.text = noFSC()
                                                    ? 'Spend/L (All' +
                                                      `&#8209;` +
                                                      'In)'
                                                    : 'Spend/L (W/O Fuel)')
                                            }}
                                        </template>

                                        <template
                                            v-slot:header.rpm="{ header }"
                                        >
                                            {{
                                                (header.text = noFSC()
                                                    ? 'RPM (All' +
                                                      `&#8209;` +
                                                      'In)'
                                                    : 'RPM (W/O Fuel)')
                                            }}
                                        </template>

                                        <template
                                            v-slot:header.avg_revenue="{
                                                header
                                            }"
                                        >
                                            {{
                                                (header.text = noFSC()
                                                    ? 'Spend/L (All' +
                                                      `&#8209;` +
                                                      'In)'
                                                    : 'Spend/L (W/O Fuel)')
                                            }}
                                        </template>

                                        <template
                                            v-slot:header.avg_cogs="{ header }"
                                        >
                                            {{
                                                (header.text = noFSC()
                                                    ? 'Cost/L (All' +
                                                      `&#8209;` +
                                                      'In)'
                                                    : 'Cost/L (W/O Fuel)')
                                            }}
                                        </template>

                                        <!-- Empty table override -->
                                        <template #no-data>
                                            <div>
                                                Select a broker from the
                                                dropdown menu above
                                            </div>
                                        </template>

                                        <!-- Suggest buttons -->
                                        <template #header.suggest>
                                            <v-btn
                                                elevation="0"
                                                outlined
                                                class="suggestBtn"
                                                x-small
                                                :color="
                                                    suggestAllToggles[
                                                        pageNumber
                                                    ]
                                                        ? 'white'
                                                        : 'primary'
                                                "
                                                :style="
                                                    getAutoFixStyle(
                                                        suggestAllToggles[
                                                            pageNumber
                                                        ]
                                                    )
                                                "
                                                @click="suggestAll"
                                                ><v-icon small
                                                    >mdi-auto-fix</v-icon
                                                >
                                            </v-btn>
                                        </template>
                                        <template
                                            #item.suggest="{ item, index }"
                                        >
                                            <v-btn
                                                :ref="'btn' + index"
                                                class="suggestBtn"
                                                elevation="0"
                                                outlined
                                                :color="
                                                    suggested.has(item.laneId)
                                                        ? 'white'
                                                        : 'primary'
                                                "
                                                x-small
                                                plain
                                                :ripple="false"
                                                :style="
                                                    getAutoFixStyle(
                                                        suggested.has(
                                                            item.laneId
                                                        )
                                                    )
                                                "
                                                @click="
                                                    suggested.has(item.laneId)
                                                        ? unsuggest(item, index)
                                                        : suggest(item, index)
                                                "
                                                ><v-icon small
                                                    >mdi-auto-fix</v-icon
                                                >
                                            </v-btn>
                                        </template>

                                        <!-- Computed Columns -->
                                        <template
                                            #item.margin="{ item, index }"
                                        >
                                            <v-text-field
                                                style="
                                                    justify-self: end;
                                                    max-width: 80px;
                                                    float: right;
                                                "
                                                hide-details
                                                dense
                                                suffix="%"
                                                class="pt-0 mb-2 inputRight"
                                                v-model="item.margin_display"
                                                @change="
                                                    processInput(
                                                        'frac',
                                                        item,
                                                        index
                                                    )
                                                "
                                                @keyup.enter.prevent="
                                                    processInput(
                                                        'frac',
                                                        item,
                                                        index
                                                    )
                                                "
                                            >
                                            </v-text-field>
                                        </template>
                                        <template
                                            #item.new_margin="{ item, index }"
                                        >
                                            <v-text-field
                                                style="
                                                    justify-self: end;
                                                    max-width: 80px;
                                                    float: right;
                                                "
                                                hide-details
                                                dense
                                                prefix="$"
                                                class="pt-0 mb-2 inputRight"
                                                v-model="
                                                    item.new_margin_display
                                                "
                                                @change="
                                                    processInput(
                                                        'flat',
                                                        item,
                                                        index
                                                    )
                                                "
                                                @keyup.enter.prevent="
                                                    processInput(
                                                        'flat',
                                                        item,
                                                        index
                                                    )
                                                "
                                            >
                                            </v-text-field>
                                        </template>
                                        <template #item.new_spend="{ value }">
                                            {{ formatDollars(value) }}
                                        </template>
                                        <template
                                            #item.projected_savings="{ value }"
                                        >
                                            <span class="ma-0 savingsCell">
                                                {{ formatDollars(value) }}
                                            </span>
                                        </template>
                                        <template
                                            #item.expected_volume="{
                                                item,
                                                index
                                            }"
                                        >
                                            <v-text-field
                                                style="
                                                    justify-self: end;
                                                    max-width: 80px;
                                                    float: right;
                                                "
                                                hide-details
                                                dense
                                                class="pt-0 mb-2 inputCenter"
                                                :class="
                                                    volChanged.has(item.laneId)
                                                        ? 'font-weight-bold'
                                                        : ''
                                                "
                                                v-model="item.expected_volume"
                                                type="number"
                                                @change="
                                                    processInput(
                                                        'vol',
                                                        item,
                                                        index
                                                    )
                                                "
                                                @keyup.enter.prevent="
                                                    processInput(
                                                        'vol',
                                                        item,
                                                        index
                                                    )
                                                "
                                            ></v-text-field>
                                        </template>

                                        <!-- Static Columns -->
                                        <template
                                            #item.total_margin="{ value }"
                                        >
                                            {{ formatDollars(value) }}
                                        </template>
                                        <template #item.avg_margin="{ value }">
                                            {{ formatPercent(value, 1) }}
                                        </template>
                                        <template
                                            #item.avg_margin_dollars="{ value }"
                                        >
                                            {{ formatDollars(value) }}
                                        </template>
                                        <template #item.avg_cogs="{ value }">
                                            {{ formatDollars(value) }}
                                        </template>
                                        <template #item.avg_revenue="{ value }">
                                            {{ formatDollars(value) }}
                                        </template>
                                        <template #item.avg_miles="{ value }">
                                            {{ formatDecimal(value, 0) }}
                                        </template>
                                        <template
                                            #item.equipmenttype="{ item }"
                                        >
                                            {{
                                                item.shipmentmode &&
                                                item.shipmentmode == 'IMDL'
                                                    ? 'IMDL - ' +
                                                      item.equipmenttype
                                                    : item.equipmenttype
                                            }}
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
        <SplashScreen :is_loading="isLoading" />
    </v-app>
</template>

<script>
import NavBar from '../components/NavBar';
import SideBar from '../components/SideBar';
import * as user_analytics from '../analytics/sendAnalyticsEvent';
import * as globalVariables from '../globalVariables';
import * as fetch from '../fetchShipments';
import * as stateAPI from '../stateAPI';
import * as format from '../formatShipmentData';
import SplashScreen from '../components/SplashScreen';
import Excel from 'exceljs';
import { saveAs } from 'file-saver';

const { DEFAULT_PROJECTION_PERIOD } = require('../utils');

export default {
    name: 'Savings',

    components: {
        NavBar,
        SideBar,
        SplashScreen
    },

    props: ['prop_selected', 'prop_id'],

    computed: {
        projectionPeriod() {
            return (
                Math.ceil(
                    (new Date(Date.parse(this.projectDate)) - new Date()) /
                        8.64e7
                ) + 1
            );
        },
        pastMinBound() {
            return new Date(stateAPI.getStateProperty(this, 'startDate'));
        },
        aggStartDateISO() {
            return this.aggStartDate.toISOString().split('T')[0];
        },
        curDateISO() {
            return this.curDate.toISOString().split('T')[0];
        },
        projectDateDisplay() {
            let temp = this.projectDate.split('-');
            return `${temp[1]}-${temp[2]}-${temp[0]}`;
        },
        theme() {
            return this.$vuetify.theme.dark ? 'dark' : 'light';
        }
    },

    watch: {
        futureOffset(val) {
            if (val !== 0) {
                var d = new Date();
                d.setDate(d.getDate() + this.tickValues[val]);
                this.projectDate = d.toISOString().split('T')[0];
            } else {
                this.projectDate = new Date(new Date().setMonth(11, 31))
                    .toISOString()
                    .split('T')[0];
            }
            this.loadPage();
        },

        projectDate() {
            this.volChanged = new Set();
            this.loadPage();
        },

        selectedTarget(val) {
            if (val !== this.prop_id) {
                this.selected = null;
            }
            if (val) {
                if (this.isBrokerUser) {
                    this.shipperId = val;
                } else {
                    this.brokerId = val;
                }
                this.suggestAllToggles = new Array(
                    Math.ceil(this.totalLength / this.pageSize)
                ).fill(0);
                this.getSavingsData();
                this.getHistoricalData();

                this.suggested = new Set();
                this.volChanged = new Set();
                this.laneCache = {};
                this.getAggMetrics();
            }
        },

        volumeThreshold: {
            handler(val) {
                clearTimeout(this.volFilterTimeout);
                let self = this;
                this.volFilterTimeout = setTimeout(
                    function () {
                        if (val !== '' && self.volCache !== val) {
                            self.volCache = val;
                            self.getSavingsData();
                            self.suggested = new Set();
                            self.volChanged = new Set();
                            self.laneCache = {};
                            self.getAggMetrics();
                        }
                    },
                    250,
                    self
                );
            },

            flush: 'post'
        },

        fuelSurcharge() {
            this.loadPage();
        }
    },

    methods: {
        formatScore: format.formatScore,
        formatPercent: format.formatPercent,
        formatDollars: format.formatDollars,
        formatDecimal: format.formatDecimal,

        loadPage() {
            this.resetRowStyling();
            this.lanes.forEach((lane) => {
                const cacheObj = this.laneCache[lane.laneId];
                if (this.volChanged.has(lane.laneId)) {
                    lane.expected_volume = cacheObj.volField;
                } else {
                    lane.expected_volume = parseInt(
                        (lane.volume / this.num_historical_days) *
                            this.projectionPeriod
                    );
                }
                if (lane.laneId in this.laneCache) {
                    lane.margin_display = cacheObj.margin_perc;
                    lane.new_margin_display = cacheObj.margin_flat;
                    this.calculate(lane);
                } else {
                    this.initializeLane(lane);
                }
            });
        },

        noFSC() {
            return (
                isNaN(this.fuelSurcharge) ||
                parseFloat(this.fuelSurcharge) <= 0 ||
                this.fuelSurcharge === ''
            );
        },

        updateAggregates() {
            // This guarantees that when the db is queried, the function waits for the required promises
            if (this.aggPromise) {
                this.aggPromise.then((result) => {
                    this.updateAggregates();
                    this.aggPromise = null;
                });
                return;
            }

            // Abort if there are no entries
            if (this.lanes.length === 0) {
                this.totalVol = '0';
                this.avgMarginPercent = '--';
                this.avgMarginFlat = '--';
                return;
            }

            // Sums all "savings" entries in the laneCache
            const agg = Object.values(this.laneCache).reduce(
                (pre, cur) => {
                    return [
                        pre[0] + cur.savings,
                        pre[1] + cur.volExp,
                        pre[2] + cur.totalSpend,
                        pre[3] + cur.volField,
                        pre[4] + cur.totalCogs,
                        pre[5] + cur.baseCogs,
                        pre[6] + cur.baseRev,
                        pre[7] + cur.baseVol
                    ];
                },
                [0, 0, 0, 0, 0, 0, 0, 0, 0]
            );
            this.totalChange = agg[0];
            let volExp = agg[1];
            let spend = agg[2];
            let volField = agg[3];
            let cogs = agg[4];
            let baseCogs = agg[5];
            let baseRev = agg[6];
            let baseVol = agg[7];
            let aggHistCogs = this.aggHistCogs + this.histCogs;
            let aggHistRev = this.aggHistRev + this.histRev;
            let aggHistVol = this.aggHistVol + this.histVol;
            this.baseExpVol = parseFloat(
                (this.histVol / this.num_historical_days) *
                    this.projectionPeriod
            );
            if (this.histVol - baseVol !== 0) {
                this.revProj =
                    ((this.histRev - baseRev) / (this.histVol - baseVol)) *
                        (this.baseExpVol - volExp) +
                    spend;
                this.cogsProj =
                    ((this.histCogs - baseCogs) / (this.histVol - baseVol)) *
                        (this.baseExpVol - volExp) +
                    cogs;
            } else {
                this.revProj = spend;
                this.cogsProj = cogs;
            }
            this.totalVol = Math.round(
                aggHistVol + volField + this.baseExpVol - volExp
            );
            this.avgMarginPercent = this.formatPercent(
                (this.revProj - this.cogsProj + aggHistRev - aggHistCogs) /
                    (this.revProj + aggHistRev),
                2
            );
            this.avgMarginFlat = this.formatDecimal(
                (this.revProj - this.cogsProj + aggHistRev - aggHistCogs) /
                    this.totalVol,
                2
            );
        },

        trimLaneCache() {
            var fresh = new Set();
            for (let lane of this.lanes) {
                fresh.add(lane.laneId);
            }
            for (let laneId in this.laneCache) {
                if (!fresh.has(laneId)) {
                    delete this.laneCache.laneId;
                }
            }
        },

        getCustomerList() {
            this.populateQuery();
            this.query_params.user_type = this.isBrokerUser
                ? 'broker'
                : 'shipper';
            this.query_params.is_broker_user = this.isBrokerUser;
            this.query_params.search_list = 1;
            // Need this extra check because when shipperid=undefined, it causes issues in the lambda
            if (this.query_params.shipperId == undefined) {
                this.query_params.shipperId = 'None';
            }
            fetch.fetchShipmentData(this.query_params).then((result) => {
                if (result.search_list.length !== 0) {
                    this.searchData = result.search_list;
                }
            });
            this.query_params.search_list = 0;
        },

        getSavingsData() {
            if (!this.brokerId && !this.shipperId) {
                return;
            }
            this.getTotalLength();
            this.isLoading = true;
            this.populateQuery();
            this.lanes = [];
            this.dataPromise = fetch
                .fetchShipmentData(this.query_params)
                .then((result) => {
                    if (result.savings_table.length !== 0) {
                        this.lanes = result.savings_table;
                        this.trimLaneCache();
                        this.loadPage();
                    } else {
                        this.lanes = [];
                    }
                    this.isLoading = false;
                });
        },

        getHistoricalData() {
            // Retrieves aggregate to current historical detail stats
            // Assumes this.lanes is populated
            if (this.dataPromise) {
                this.dataPromise.then(() => {
                    this.populateQuery();
                    const params = {};
                    Object.assign(params, this.query_params);
                    params.start_date = this.aggStartDate;
                    fetch.fetchShipmentData(params).then((result) => {
                        if (result.savings_table.length !== 0) {
                            let displayedLanes = this.lanes.map(
                                (elem) => elem.laneId
                            );
                            for (const lane of result.savings_table) {
                                let ind = displayedLanes.indexOf(lane.laneId);
                                if (ind !== -1) {
                                    this.lanes[ind].hist = lane;
                                }
                            }
                            this.$forceUpdate();
                        }
                    });
                });
            } else {
                setTimeout(this.getHistoricalData, 250);
            }
        },

        getTotalLength() {
            if (this.selected) {
                this.totalLength = Object.keys(this.selected).length;
            } else {
                this.populateQuery();
                this.query_params.count = 1;
                fetch.fetchShipmentData(this.query_params).then((result) => {
                    this.totalLength = result[0].count;
                });
                this.query_params.count = 0;
            }
        },

        getAggMetrics() {
            this.populateQuery();
            this.aggHistCogs = 0;
            this.aggHistRev = 0;
            this.aggHistVol = 0;
            this.histCogs = 0;
            this.histRev = 0;
            this.histVol = 0;
            const paramsA = {};
            Object.assign(paramsA, this.query_params);
            const paramsB = {};
            Object.assign(paramsB, this.query_params);

            paramsA.start_date = new Date(
                stateAPI.getStateProperty(this, 'startDate')
            );
            paramsA.end_date = new Date(
                new Date(this.startDate).setDate(
                    new Date(this.startDate).getDate() - 1
                )
            );
            paramsA.sum = 1;
            let aggPromiseA = fetch
                .fetchShipmentData(paramsA)
                .then((result) => {
                    if (result[0].sum_vol === 0) {
                        return;
                    }
                    this.aggHistCogs = result[0].sum_cogs;
                    this.aggHistRev = result[0].sum_revenue;
                    this.aggHistVol = result[0].sum_vol;
                });
            paramsB.start_date = new Date(this.startDate);
            paramsB.end_date = this.curDate;
            paramsB.sum = 1;
            let aggPromiseB = fetch
                .fetchShipmentData(paramsB)
                .then((result) => {
                    if (result[0].sum_vol === 0) {
                        return;
                    }
                    this.histCogs = result[0].sum_cogs;
                    this.histRev = result[0].sum_revenue;
                    this.histVol = result[0].sum_vol;
                });
            this.aggPromise = Promise.all([aggPromiseA, aggPromiseB]).then();
        },

        populateQuery() {
            if (this.isBrokerUser) {
                this.query_params.shipperId = this.shipperId;
                this.query_params.brokerId = stateAPI.getStateProperty(
                    this,
                    'user_id'
                );
            } else {
                this.query_params.brokerId = this.brokerId;
                this.query_params.shipperId = stateAPI.getStateProperty(
                    this,
                    'user_id'
                );
            }
            this.query_params.start_date = this.startDate;
            this.query_params.end_date = new Date(
                new Date(this.curDate) - new Date().getTimezoneOffset() * 60000
            )
                .toISOString()
                .substring(0, 10);
            this.query_params.equipment_type = this.selectedEquipmentTypes;
            this.query_params.page_number = this.pageNumber;
            this.query_params.page_size = this.pageSize;
            this.query_params.order_by = this.sortColumn;
            this.query_params.index_by = this.index_by;
            this.query_params.include_accessorials = this.includeAcc ? 1 : 0;
            this.query_params.order_by_direction = this.sortDesc
                ? 'DESC'
                : 'ASC';
            this.query_params.shipment_page_number = this.pageNumberShipments;
            this.query_params.shipment_page_size = this.pageSizeShipments;
            this.query_params.shipment_order_by_direction =
                this.sortDirectionShipments;
            this.query_params.shipment_order_by = this.sortColumnShipments;
            this.query_params.volume_threshold = this.volumeThreshold;

            if (this.selected != null) {
                this.query_params.savings_lanes = Object.keys(this.selected);
            } else {
                delete this.query_params.savings_lanes;
            }
        },

        processInput(type, lane, index) {
            if (type === 'frac') {
                if (lane.margin_display >= 100) {
                    lane.margin_display = 99.99;
                }
                lane.margin_display = this.formatDecimal(
                    lane.margin_display,
                    2
                );
                if (
                    lane.margin_display ===
                    this.formatDecimal(lane.margin * 100, 2)
                ) {
                    return;
                }
                lane.new_margin =
                    lane.avg_cogs / (1 - lane.margin_display / 100) -
                    lane.avg_cogs;
                lane.new_margin_display = this.formatDecimal(
                    lane.new_margin,
                    2
                );
            } else if (type === 'flat') {
                lane.new_margin_display = this.formatDecimal(
                    lane.new_margin_display,
                    2
                );
                if (
                    lane.new_margin_display ===
                    this.formatDecimal(lane.new_margin, 2)
                ) {
                    return;
                }
                lane.margin =
                    (parseFloat(lane.new_margin_display) /
                        (lane.avg_cogs + parseFloat(lane.new_margin_display))) *
                    100;
                lane.margin_display = this.formatDecimal(lane.margin, 2);
            } else if (type === 'vol') {
                this.calculate(lane);
                if (
                    lane.expected_volume === '' ||
                    isNaN(lane.expected_volume)
                ) {
                    lane.expected_volume = 0;
                    this.$forceUpdate();
                } else {
                    lane.expected_volume = parseInt(lane.expected_volume);
                }
                this.volChanged.add(lane.laneId);
                return;
            }
            this.calculate(lane);
        },

        calculate(lane) {
            if (this.fuelSurcharge !== '' && this.fuelSurcharge !== 0) {
                lane.new_spend =
                    lane.avg_cogs +
                    parseFloat(lane.new_margin_display) -
                    this.fuelSurcharge * lane.avg_miles;
                lane.rpm = this.formatDecimal(
                    lane.new_spend / lane.avg_miles,
                    2
                );
                lane.spend_nofsc =
                    lane.avg_cogs + parseFloat(lane.new_margin_display);
            } else {
                lane.spend_nofsc =
                    lane.avg_cogs + parseFloat(lane.new_margin_display);
                lane.new_spend = lane.spend_nofsc;
                lane.rpm = this.formatDecimal(
                    lane.spend_nofsc / lane.avg_miles,
                    2
                );
            }
            if (Math.abs(lane.spend_nofsc - lane.avg_revenue) * 100 >= 1) {
                lane.projected_savings =
                    (lane.spend_nofsc - lane.avg_revenue) *
                    parseInt(lane.expected_volume);
            } else {
                lane.projected_savings = 0;
            }
            this.laneCache[lane.laneId] = {};
            const cacheObj = this.laneCache[lane.laneId];
            cacheObj.margin_perc = lane.margin_display;
            cacheObj.margin_flat = lane.new_margin_display;
            cacheObj.spend = lane.spend_nofsc;
            cacheObj.totalSpend = lane.spend_nofsc * lane.expected_volume;
            cacheObj.savings = lane.projected_savings;
            cacheObj.volField = parseInt(lane.expected_volume);
            cacheObj.volExp = parseInt(
                (lane.volume / this.num_historical_days) * this.projectionPeriod
            );
            cacheObj.totalCogs = lane.avg_cogs * lane.expected_volume;
            cacheObj.baseCogs = lane.avg_cogs * lane.volume;
            cacheObj.baseRev = lane.avg_revenue * lane.volume;
            cacheObj.baseVol = lane.volume;

            // TODO - find more memory efficient way to do this?
            cacheObj.lane = lane;

            this.$forceUpdate();
        },

        createProposalExportSheetEntry(cacheObj) {
            var spend_header;
            var rpm_header;
            if (
                isNaN(this.fuelSurcharge) ||
                parseFloat(this.fuelSurcharge) <= 0 ||
                this.fuelSurcharge === ''
            ) {
                spend_header = 'Spend/L (All-In)';
                rpm_header = 'RPM (All-In)';
            } else {
                spend_header = 'Spend/L (W/O Fuel)';
                rpm_header = 'RPM (W/O Fuel)';
            }

            return {
                //Identifiers
                Origin: cacheObj.lane.origin,
                'Origin Zips':
                    cacheObj.lane.originZips == null
                        ? ''
                        : cacheObj.lane.originZips,
                Destination: cacheObj.lane.destination,
                'Destination Zips':
                    cacheObj.lane.destinationZips == null
                        ? ''
                        : cacheObj.lane.destinationZips,
                'Equip. Type': cacheObj.lane.equipmenttype,

                // Projections
                [spend_header]: parseFloat(cacheObj.spend),
                [rpm_header]: parseFloat(cacheObj.lane.rpm),

                // Empty Columns
                Accepted: '',
                Counter: ''
            };
        },

        createExportSheetEntry(cacheObj) {
            var spend_header;
            var rpm_header;
            if (
                isNaN(this.fuelSurcharge) ||
                parseFloat(this.fuelSurcharge) <= 0 ||
                this.fuelSurcharge === ''
            ) {
                spend_header = 'New Spend/L (All-In)';
                rpm_header = 'New RPM (All-In)';
            } else {
                spend_header = 'New Spend/L (W/O Fuel)';
                rpm_header = 'New RPM (W/O Fuel)';
            }

            var cur_f_date = new Date(this.curDate)
                .toISOString()
                .replace(/T.*/, '');
            var project_title =
                'Projected Volume (' +
                cur_f_date +
                ' -- ' +
                this.projectDate +
                ')';
            var historical_title =
                'Historical Volume (' +
                this.startDate +
                ' -- ' +
                cur_f_date +
                ')';

            return {
                //Identifiers
                Origin: cacheObj.lane.origin,
                'Origin Zips':
                    cacheObj.lane.originZips == null
                        ? ''
                        : cacheObj.lane.originZips,
                Destination: cacheObj.lane.destination,
                'Destination Zips':
                    cacheObj.lane.destinationZips == null
                        ? ''
                        : cacheObj.lane.destinationZips,
                'Equip. Type': cacheObj.lane.equipmenttype,

                // Projections
                'New % Margin/L': parseFloat(cacheObj.margin_perc / 100),
                'New $ Margin/L': parseFloat(cacheObj.margin_flat),
                [spend_header]: parseFloat(cacheObj.spend),
                [rpm_header]: parseFloat(cacheObj.lane.rpm),
                'Projected Change': parseFloat(cacheObj.savings),
                [project_title]: parseInt(cacheObj.volField),

                // Lane values
                'Avg. Miles': parseInt(cacheObj.lane.avg_miles),
                'Current Spend/L (All-In)': parseFloat(
                    cacheObj.lane.avg_revenue
                ),
                'Current Cost/L (All-In)': parseFloat(cacheObj.lane.avg_cogs),
                'Current $ Margin/L': parseFloat(
                    cacheObj.lane.avg_margin_dollars
                ),
                'Current % Margin/L': parseFloat(cacheObj.lane.avg_margin),
                'Current Total Margin': parseFloat(cacheObj.lane.total_margin),
                [historical_title]: parseInt(cacheObj.lane.volume)
            };
        },

        getColumnHeaders(rows) {
            var headers = [];
            var max_width;
            for (let field in rows[0]) {
                if (field.toLowerCase() == 'origin') {
                    max_width = rows.reduce(
                        (_, r) =>
                            Math.max(
                                field.length,
                                String(r[field]).length + 15
                            ),
                        0
                    );
                } else if (field.toLowerCase() == 'destination') {
                    max_width = rows.reduce(
                        (_, r) =>
                            Math.max(
                                field.length,
                                String(r[field]).length + 14
                            ),
                        0
                    );
                } else {
                    max_width = field.length + 5;
                }

                headers.push({
                    header: field,
                    key: field,
                    width: max_width
                });
            }
            return headers;
        },

        createProposalExport() {
            // For more complex export functionality, may move to external library
            // For now, let's roll with this
            this.dialog = false;

            // Workbook
            const workbook = new Excel.Workbook();
            const reprice_worksheet = workbook.addWorksheet('RepriceLanes');

            // Reprice Sheet
            var rows = [];
            for (let id in this.laneCache) {
                rows.push(
                    this.createProposalExportSheetEntry(this.laneCache[id])
                );
            }

            // Styling
            reprice_worksheet.columns = this.getColumnHeaders(rows);
            const proj_start_col = 'E';
            const proj_end_col = 'G';
            reprice_worksheet.getCell(proj_start_col + String(1)).border = {
                right: { style: 'dashDot', color: { argb: '00000000' } }
            };
            reprice_worksheet.getCell(proj_end_col + String(1)).border = {
                right: { style: 'dashDot', color: { argb: '00000000' } }
            };
            for (var i = 0; i < rows.length; i++) {
                reprice_worksheet.addRow(rows[i]);
                reprice_worksheet.getCell(
                    proj_start_col + String(i + 2)
                ).border = {
                    right: { style: 'dashDot', color: { argb: '00000000' } }
                };
                reprice_worksheet.getCell(proj_end_col + String(i + 2)).border =
                    {
                        right: { style: 'dashDot', color: { argb: '00000000' } }
                    };
            }
            reprice_worksheet.getRow(1).font = { bold: true };

            // Column formatting
            const dollarFmt = '$0.00';
            reprice_worksheet.getColumn(6).numFmt = dollarFmt;
            reprice_worksheet.getColumn(7).numFmt = dollarFmt;

            var client = this.searchData.filter(
                (broker) => broker.id == this.selectedTarget
            );
            var today = new Date().toISOString().replace(/T.*/, '');
            const shipperName = stateAPI
                .getStateProperty(this, 'company')
                .replace(/[^a-zA-Z0-9\s]+/g, '');
            this.saveFile(
                shipperName +
                    ' - ' +
                    client[0].name +
                    ' Pricing Proposal ' +
                    String(today),
                workbook
            );
        },

        createExport() {
            // For more complex export functionality, may move to external library
            // For now, let's roll with this

            // Workbook
            const workbook = new Excel.Workbook();
            const reprice_worksheet = workbook.addWorksheet('RepriceLanes');
            const agg_worksheet = workbook.addWorksheet('AggResults');

            // Reprice Sheet
            var rows = [];
            for (let id in this.laneCache) {
                rows.push(this.createExportSheetEntry(this.laneCache[id]));
            }

            // Sort export sheet by volume
            // Should we do this by selection order instead?
            var cur_f_date = new Date(this.curDate)
                .toISOString()
                .replace(/T.*/, '');
            var historical_title =
                'Historical Volume (' +
                this.startDate +
                ' -- ' +
                cur_f_date +
                ')';
            rows.sort(function (a, b) {
                var keyA = a[historical_title],
                    keyB = b[historical_title];
                if (keyA < keyB) return 1;
                if (keyA > keyB) return -1;
                return 0;
            });

            // Styling
            reprice_worksheet.columns = this.getColumnHeaders(rows);
            const proj_start_col = 'E';
            const proj_change_col = 'J';
            const proj_end_col = 'K';
            reprice_worksheet.getCell(proj_start_col + String(1)).border = {
                right: { style: 'dashDot', color: { argb: '00000000' } }
            };
            reprice_worksheet.getCell(proj_end_col + String(1)).border = {
                right: { style: 'dashDot', color: { argb: '00000000' } }
            };

            for (var i = 0; i < rows.length; i++) {
                reprice_worksheet.addRow(rows[i]);

                reprice_worksheet.getCell(
                    proj_start_col + String(i + 2)
                ).border = {
                    right: { style: 'dashDot', color: { argb: '00000000' } }
                };
                reprice_worksheet.getCell(proj_end_col + String(i + 2)).border =
                    {
                        right: { style: 'dashDot', color: { argb: '00000000' } }
                    };

                if (rows[i]['Projected Change'] > 0) {
                    reprice_worksheet.getCell(
                        proj_change_col + String(i + 2)
                    ).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '00FFF3F2' }
                    };
                } else if (rows[i]['Projected Change'] < 0) {
                    reprice_worksheet.getCell(
                        proj_change_col + String(i + 2)
                    ).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '00E4FFF7' }
                    };
                }
            }

            // Column formatting
            const dollarFmt = '$0.00';
            const percentFmt = '0.00%';
            reprice_worksheet.getRow(1).font = { bold: true };
            reprice_worksheet.getColumn(6).numFmt = percentFmt;
            reprice_worksheet.getColumn(7).numFmt = dollarFmt;
            reprice_worksheet.getColumn(8).numFmt = dollarFmt;
            reprice_worksheet.getColumn(9).numFmt = dollarFmt;
            reprice_worksheet.getColumn(10).numFmt = dollarFmt;
            reprice_worksheet.getColumn(13).numFmt = dollarFmt;
            reprice_worksheet.getColumn(14).numFmt = dollarFmt;
            reprice_worksheet.getColumn(15).numFmt = dollarFmt;
            reprice_worksheet.getColumn(16).numFmt = percentFmt;
            reprice_worksheet.getColumn(17).numFmt = dollarFmt;

            // Agg Sheet
            var agg_rows = [
                {
                    'Total Projected Change': parseFloat(this.totalChange),
                    '% Margin':
                        parseFloat(
                            this.avgMarginPercent.substring(
                                0,
                                this.avgMarginPercent.length - 1
                            )
                        ) / 100,
                    'Flat margin': parseFloat(this.avgMarginFlat.substring(1)),
                    Volume: parseInt(this.totalVol)
                }
            ];

            agg_worksheet.columns = this.getColumnHeaders(agg_rows);
            for (const row of agg_rows) {
                agg_worksheet.addRow(row);
            }
            agg_worksheet.getRow(1).font = { bold: true };

            agg_worksheet.getColumn(1).numFmt = dollarFmt;
            agg_worksheet.getColumn(2).numFmt = percentFmt;
            agg_worksheet.getColumn(3).numFmt = dollarFmt;

            var client = this.searchData.filter(
                (broker) => broker.id == this.selectedTarget
            );
            var today = new Date().toISOString().replace(/T.*/, '');
            this.saveFile(
                client[0].name + ' Pricing Data Export ' + String(today),
                workbook
            );
        },

        async saveFile(fileName, workbook) {
            const xls64 = await workbook.xlsx.writeBuffer({ base64: true });
            saveAs(
                new Blob([xls64], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                }),
                fileName
            );
        },

        initializeLane(lane) {
            // Sets computed values of a lane to default values
            if (lane.avg_revenue === 0) {
                lane.margin = '--';
                lane.margin_display = '--';
                lane.new_margin_display = '--';
            }
            lane.margin = lane.avg_margin;
            lane.margin_display = this.formatDecimal(lane.margin * 100, 2);
            lane.new_margin = lane.avg_margin_dollars;
            lane.new_margin_display = this.formatDecimal(lane.new_margin, 2);
            if (this.fuelSurcharge !== '' && this.fuelSurcharge !== 0) {
                lane.new_spend =
                    lane.avg_cogs +
                    parseFloat(lane.new_margin_display) -
                    this.fuelSurcharge * lane.avg_miles;
            } else {
                lane.new_spend =
                    lane.avg_cogs + parseFloat(lane.new_margin_display);
            }
            lane.rpm = this.formatDecimal(lane.new_spend / lane.avg_miles, 2);
            lane.expected_volume = parseInt(
                (lane.volume / this.num_historical_days) * this.projectionPeriod
            );
            lane.projected_savings = 0;
        },

        highlightLane(index, projected_change) {
            // Highlights lane on the page at a given index
            const row =
                this.$refs['btn' + index].$el.parentElement.parentElement;
            if (projected_change > 0) {
                row.classList.remove('shadeGreen');
                row.classList.add('shadeRed');
            } else if (projected_change < 0) {
                row.classList.remove('shadeRed');
                row.classList.add('shadeGreen');
            } else {
                row.classList.remove('shadeRed');
                row.classList.remove('shadeGreen');
            }
        },

        suggest(lane, index) {
            var margin = 10;
            this.suggested.add(lane.laneId);

            lane.new_spend = lane.avg_cogs / (1 - margin / 100);
            lane.new_margin = lane.new_spend - lane.avg_cogs;
            if (lane.new_margin < 75) {
                lane.new_margin = 75;
            } else if (lane.new_margin > 150) {
                lane.new_margin = 150;
            }
            lane.margin = (lane.new_margin / lane.new_spend) * 100;
            lane.new_spend =
                lane.avg_revenue + lane.new_margin - lane.avg_margin_dollars;

            lane.margin_display = this.formatDecimal(lane.margin, 2);
            lane.new_margin_display = this.formatDecimal(lane.new_margin, 2);

            this.calculate(lane);
            this.$forceUpdate();
        },

        unsuggest(lane, index) {
            this.suggested.delete(lane.laneId);
            this.volChanged.delete(lane.laneId);
            delete this.laneCache[lane.laneId];
            this.initializeLane(lane);
            this.resetRowStyling(index);
            this.$forceUpdate();
        },

        suggestAll() {
            if (this.suggestAllToggles[0] === undefined) {
                this.suggestAllToggles = new Array(
                    Math.ceil(this.totalLength / this.pageSize)
                ).fill(0);
            }
            for (let i = 0; i < this.lanes.length; i++) {
                if (!this.suggestAllToggles[this.pageNumber]) {
                    if (!this.suggested.has(this.lanes[i].laneId)) {
                        this.$refs['btn' + i].$el.click();
                    }
                }
                if (this.suggestAllToggles[this.pageNumber]) {
                    if (this.suggested.has(this.lanes[i].laneId)) {
                        this.$refs['btn' + i].$el.click();
                    }
                }
            }
            this.suggestAllToggles[this.pageNumber] =
                !this.suggestAllToggles[this.pageNumber];
        },

        updatePageOptions(options) {
            if (options.sortBy.length > 0) {
                this.sortColumn = options.sortBy[0];
                this.sortDesc = options.sortDesc[0];
            }
            this.pageSize = options.itemsPerPage;
            if (options.page - 1 === this.pageNumber) {
                this.suggestAllToggles = new Array(
                    Math.ceil(this.totalLength / this.pageSize)
                ).fill(0);
            }
            this.pageNumber = options.page - 1;
            this.getSavingsData();
            this.getHistoricalData();
        },

        resetRowStyling(index) {
            if (typeof index === 'undefined') {
                const rows = document.getElementsByTagName('tr');
                for (let row of rows) {
                    row.classList.remove('shadeGreen');
                    row.classList.remove('shadeRed');
                }
            } else {
                const btn = this.$refs['btn' + index].$el;
                const row = btn.parentElement.parentElement;
                row.classList.remove('shadeGreen');
                row.classList.remove('shadeRed');
            }
        },

        customFilter(_, queryText, itemText) {
            const cleanedQuery = queryText
                .replace(/[&@\\/#?!|^_,`+=()$~%.'";:*?<>{}]/g, ' ')
                .toLocaleLowerCase();
            const queryTokens = cleanedQuery.split(' ');
            const fillers = ['to', 'and'];
            for (const token of queryTokens) {
                if (fillers.includes(token)) {
                    continue;
                }
                if (itemText.toLocaleLowerCase().indexOf(token) === -1) {
                    return false;
                }
            }
            return true;
        },

        futureAllowed(val) {
            return Date.parse(val) >
                this.curDate - new Date().getTimezoneOffset() * 60000
                ? true
                : false;
        },

        volumeFilterBlur() {
            if (
                this.volumeThreshold === null ||
                this.volumeThreshold < 0 ||
                this.volumeThreshold === ''
            ) {
                this.volumeThreshold = 0;
            }
        },

        volumeFilterFocus(event) {
            if (parseInt(event.srcElement.value) === 0) {
                this.volumeThreshold = '';
                event.srcElement.value = '';
            }
        },

        validateFSC() {
            if (
                isNaN(this.fuelSurcharge) ||
                parseFloat(this.fuelSurcharge) <= 0 ||
                this.fuelSurcharge == null
            ) {
                this.fuelSurcharge = '';
            } else if (parseInt(this.fuelSurcharge) > 0) {
                this.fuelSurcharge = parseFloat('0.' + this.fuelSurcharge);
            }
            document.getElementById('fscField').value = this.fuelSurcharge;
            if (
                isNaN(this.fuelSurcharge) ||
                parseFloat(this.fuelSurcharge) <= 0 ||
                this.fuelSurcharge === ''
            ) {
                this.headers[3].text = 'Avg. Spend (All-In)';
                this.headers[4].text = 'RPM (All-In)';
            } else {
                this.headers[3].text = 'Avg. Spend (W/O Fuel)';
                this.headers[4].text = 'RPM (W/O Fuel)';
            }
        },

        blurFSC(event) {
            if (
                parseFloat(this.fuelSurcharge) <= 0 ||
                this.fuelSurcharge === ''
            ) {
                this.fuelSurcharge = 0;
                document.getElementById('fscField').value = 0;
                this.headers[3].text = 'Avg. Spend (All-In)';
                this.headers[4].text = 'RPM (All-In)';
            } else {
                this.fuelSurcharge = parseFloat(this.fuelSurcharge);
            }
        },

        showDialogOnExport() {
            if (
                isNaN(this.fuelSurcharge) ||
                parseFloat(this.fuelSurcharge) <= 0 ||
                this.fuelSurcharge === ''
            ) {
                this.dialog = true;
            } else {
                this.createProposalExport();
            }
        },

        detailsToShow(item) {
            let result = {};
            Object.assign(result, item);
            for (const key in item) {
                if (
                    !globalVariables.default.calc_details_whitelist.includes(
                        key
                    )
                ) {
                    delete result[key];
                }
            }
            return result;
        },

        getTotalProjectedChangeColor() {
            if (this.theme == 'light') {
                return this.totalChange == 0
                    ? 'blue lighten-5'
                    : this.totalChange < 0
                    ? 'green lighten-5'
                    : 'red lighten-5';
            }
            return this.totalChange == 0
                ? '#454647'
                : this.totalChange < 0
                ? 'rgba(29, 255, 112, 0.3)'
                : 'rgba(255, 74, 64, 0.3)';
        },

        getAutoFixStyle(val) {
            if (this.theme == 'light') {
                return val ? 'background-color: #83c5f7' : '';
            }
            return val ? 'background-color: #3779AB' : '';
        }
    },

    beforeMount() {
        // Set unused crumbs for sidebar
        stateAPI.setStateProperty(this, 'crumbs', this.crumbs);

        // Default to 30 day offset if not from drilldown
        if (
            Math.ceil(
                (this.curDate - this.aggStartDate) / (1000 * 60 * 60 * 24)
            ) < DEFAULT_PROJECTION_PERIOD
        ) {
            this.startDate = this.aggStartDateISO;
            this.num_historical_days = Math.ceil(
                (this.curDate - this.aggStartDate) / (1000 * 60 * 60 * 24)
            );
        } else {
            let d = new Date(
                new Date(
                    new Date(
                        new Date(
                            new Date() -
                                DEFAULT_PROJECTION_PERIOD * 1000 * 60 * 60 * 24
                        ).setHours(5, 0, 0)
                    )
                ) -
                    new Date().getTimezoneOffset() * 60000
            );
            this.startDate = d.toISOString().substring(0, 10);
        }

        // Populate search data
        this.getCustomerList();
        this.selectedTarget = this.prop_id;

        // Default projection date if available
        let defProj = stateAPI.getStateProperty(this, 'defaultProjectionDate');
        if (defProj && Date.parse(defProj) - this.curDate > 0) {
            this.projectDate = defProj;
        } else {
            this.projectDate.setFullYear(new Date().getFullYear(), 11, 31);
        }

        // Send pageView analytics event
        const email = stateAPI.getStateProperty(this, 'email');
        const company = stateAPI.getStateProperty(this, 'company');
        const role = stateAPI.getStateProperty(this, 'role');
        user_analytics.sendEvent(email, company, role, 'pageView', {
            pageSource: 'Calculator'
        });
    },

    updated() {
        const event = new Event('render');
        var list = this.$el.getElementsByClassName('savingsCell');
        for (let savingsCell of list) {
            savingsCell.dispatchEvent(event);
        }
        this.updateAggregates();
        if (this.lanes && this.lanes.length > 0) {
            this.lanes.forEach((lane, ind) => {
                this.highlightLane(ind, lane.projected_savings);
            });
        }
    },

    data: function () {
        return {
            // crumbs for nav
            crumbs: [
                {
                    text: 'Calculator',
                    disabled: true,
                    href: new URL(window.location.href).pathname
                }
            ],

            query_params: {
                search_list: 0,
                analytics: 0,
                savings: 1,
                // Fix this shit this is evil
                is_drilldown: 1,
                agg_by_week: 0
            },
            dataPromise: null,
            selectedTarget: '',
            brokerId: '',
            selected: this.prop_selected,
            headers: globalVariables.default.headers_savings_calculator,
            pageSize: 100,
            pageNumber: 0,
            selectedEquipmentTypes: stateAPI.getStateProperty(
                this,
                'equipment_type_list'
            ),
            index_by: 'laneId',
            usn: this.prop_usn,
            periodDates: '',
            oldPeriodDates: '',
            searchData: [],

            // For table row expansion
            expanded: [],

            // Various Caches
            suggestAllToggles: [],
            suggested: new Set(), // Set of lanes for which suggest toggle is enabled
            volChanged: new Set(), // Set of lanes for which volume has been changed
            laneCache: {},
            totalVol: 0,
            histCogs: 0,
            histRev: 0,
            histVol: 0,

            totalLength: 0,
            totalChange: 0,

            // For aggregate metric calculations
            aggHistCogs: 0,
            aggHistRev: 0,
            aggHistVol: 0,
            aggPromise: null,
            revProj: 0,
            cogsProj: 0,
            baseAggMargin: 0,
            avgMarginPercent: '--',
            avgMarginFlat: '--',

            // startDate is a string in YYYY-MM-DD format
            startDate: null,
            aggStartDate: new Date(
                stateAPI.getStateProperty(this, 'startDate')
            ),
            curDate: new Date(
                new Date() - new Date().getTimezoneOffset() * 60000
            ),
            num_historical_days: 30,
            // Period used to project calculations
            projectDate: new Date(
                new Date(new Date().setMonth(11, 31)).setHours(5, 0, 0)
            )
                .toISOString()
                .split('T')[0], // Also used for projectionPeriod computed property

            // Variables for the date picker slider
            tickLabels: ['7 days', '14 days', '30 days', '60 days', '90 days'],
            tickValues: [null, 7, 14, 30, 60, 90],
            pastOffsetSlider: null, // Model for past date slider
            futureOffset: null, // Feeds into projectionPeriod if defined

            // Volume Threshold Filter
            volumeThreshold: 0,
            volFilterTimeout: null,

            volCache: 0,
            fuelSurcharge: 0,
            includeAcc: 0,
            sortColumn: 'volume',
            sortDesc: true,
            pageSizeShipments: 8,
            pageNumberShipments: 0,
            sortColumnShipments: 'originCloseTime',
            sortDirectionShipments: 'DESC',
            // this may need to change
            isBrokerUser: stateAPI.getStateProperty(this, 'role') == 'broker',
            lanes: [],
            text_values: {},
            isLoading: false,
            menu: false,
            dialog: false,
            fullDetailMetadata: {
                avg_revenue: {
                    name: 'Spend/L All-In',
                    formatter: this.formatDollars
                },
                avg_cogs: {
                    name: 'Cost/L All-In',
                    formatter: this.formatDollars
                },
                avg_margin_dollars: {
                    name: '$ Margin/L',
                    formatter: this.formatDollars
                },
                avg_margin: {
                    name: '% Margin/L',
                    formatter: this.formatPercent
                },
                total_margin: {
                    name: 'Total Margin',
                    formatter: this.formatDollars
                },
                volume: {
                    name: 'Volume',
                    formatter: this.formatDecimal
                }
            }
        };
    }
};
</script>

<style>
.calcTable {
    background-color: var(--v-dataTableBackground-base) !important;
    color: var(--v-dataTableText-base) !important;
}

.calcTable .widthWrap {
    max-width: 50px !important;
    overflow-wrap: normal !important;
    white-space: normal !important;
}

.calcTable .inputRight input {
    text-align: right;
}
.calcTable .inputCenter input {
    text-align: center;
}
.calcTable input {
    text-align: right;
}
.volumeFilter input {
    text-align: center !important;
}
.fscField input {
    text-align: center !important;
}

.styleC {
    background-color: var(--v-styleCBackgroundColor-base);
    padding-left: 2rem !important;
    padding-right: 0 !important;
}
.styleC2 {
    background-color: var(--v-styleCBackgroundColor-base);
    padding-left: 1rem !important;
    padding-right: 0 !important;
}

.styleA {
    background-color: var(--v-styleABackgroundColor-base);
    padding-left: 0 !important;
    text-align: right;
    padding-right: 20px !important;
}

.styleT {
    background-color: var(--v-styleABackgroundColor-base);
}

.styleD {
    background-color: var(--v-styleCBackgroundColor-base);
    padding: 0% !important;
}

.suggestBtn .v-btn__content {
    opacity: 1 !important;
}

.customHeader {
    font-size: 1em !important;
    font-weight: lighter;
    border-bottom: none !important;
}

.shadeGreen > td.styleC {
    background: var(--v-shadeGreenStyleBackgroundColor-base);
}
.shadeRed > td.styleC {
    background-color: var(--v-shadeRedStyleBackgroundColor-base);
}
.shadeGreen > td.styleC2 {
    background: var(--v-shadeGreenStyleBackgroundColor-base);
}
.shadeRed > td.styleC2 {
    background-color: var(--v-shadeRedStyleBackgroundColor-base);
}
.shadeGreen > td.styleD {
    background: var(--v-shadeGreenStyleBackgroundColor-base);
}
.shadeRed > td.styleD {
    background-color: var(--v-shadeRedStyleBackgroundColor-base);
}

.calcTable > .v-data-table__wrapper > table > tbody > tr:hover {
    background: inherit !important;
}

.fscField label {
    max-width: 133% !important;
    transform: translateY(-18px) scale(0.75);
}

.bolden {
    font-weight: bold;
}

.v-input--slider .v-slider__ticks > span {
    font-size: 20px;
    font-weight: bold;
    transform: translate(-50%);
}
.v-input--slider.v-input--slider--ticks-labels .v-input__slot {
    margin-bottom: 10;
}

.savingsSlider > .v-input__control > .v-messages {
    transform: translateY(-30px);
}

.expandedDataTableForShipments tbody tr:nth-of-type(even) {
    background-color: rgba(141, 140, 140, 0.05);
}

.gotoIcon:hover {
    color: #ffffff;
    background-color: #0091ff;
    padding: 3px;
    border-radius: 50%;
}

.gotoIcon {
    padding: 3px;
    border-radius: 50%;
}

.originDestinationWidth td {
    max-width: 5rem;
}

.metricStyle {
    font-weight: 400;
    font-size: 18px;
    color: var(--v-metricStyleColor-base);
}

.dateRangeBtn {
    color: var(--v-dateRangeBtnColor-base) !important;
    background-color: var(--v-dateRangeBtnBackgroundColor-base) !important;
    box-shadow: 0px 0px !important;
}

.cellGrad {
    background-image: linear-gradient(
        to left,
        var(--v-cellGradColorStart-base),
        var(--v-cellGradColorEnd-base)
    );
}
.shadeRed > td.cellGrad {
    background-image: linear-gradient(
        to left,
        var(--v-shadeRedCellGradColorStart-base),
        var(--v-shadeRedCellGradColorEnd-base)
    );
}
.shadeGreen > td.cellGrad {
    background-image: linear-gradient(
        to left,
        var(--v-shadeGreenCellGradColorStart-base),
        var(--v-shadeGreenCellGradColorEnd-base)
    );
}

/* For disabling arrow tickers on volume threshold input*/
/* Chrome, Safari, Edge, Opera */
#CalculatorPage input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
#CalculatorPage input[type='number'] {
    -moz-appearance: textfield;
}
</style>
