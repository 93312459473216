<template>
    <div>
        <v-row class="pb-6 pl-3">
            <v-row class="py-3 pl-2">
                <v-col cols="12" style="color: '#545454'">
                    Your password must have the following:
                </v-col>
            </v-row>
            <v-row
                v-for="(rule, i) in password_creation_rules"
                :key="i"
                class="link text-start"
                style="color: '#545454'"
                dense
            >
                <v-col cols="12">
                    <v-icon
                        style="margin-top: -2px; margin-right: 5px"
                        :color="rule.status ? 'green' : 'red'"
                    >
                        {{ rule.status ? rule_met_icon : rule_fail_icon }}
                    </v-icon>
                    {{ rule.value }}
                </v-col>
            </v-row>
        </v-row>

        <v-text-field
            prepend-inner-icon="mdi-lock"
            label="Password *"
            v-model="password"
            required
            :append-icon="password_visibility ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append="() => (password_visibility = !password_visibility)"
            :type="password_visibility ? 'password' : 'text'"
            :rules="[rules.required]"
            @input="validatePassword"
            @keyup.enter="emitLogin"
        >
        </v-text-field>

        <v-text-field
            prepend-inner-icon="mdi-lock"
            label="Confirm Password *"
            v-model="confirm_password"
            :append-icon="password_visibility ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append="() => (password_visibility = !password_visibility)"
            :type="password_visibility ? 'password' : 'text'"
            required
            :rules="[password === confirm_password || 'Password must match']"
            @input="emitConfirmPassword"
            @keyup.enter="emitLogin"
        >
        </v-text-field>
        <v-text-field
            v-if="this.token_reset"
            v-model="token"
            label="Verification Code"
            type="number"
            class="mt-2"
            outlined
            @input="emitToken"
            @keyup.enter="emitLogin"
            required
        >
        </v-text-field>
    </div>
</template>

<script>
export default {
    name: 'SetPassword',
    props: ['token_reset'],

    data: function () {
        return {
            rules: {
                required: (value) => !!value || 'Required.',
                min: (v) => v.length >= 8 || 'Min 8 characters',
                emailMatch: () =>
                    `The email and password you entered don't match`
            },

            password_creation_rules: [
                {
                    rule: 'lowercase',
                    status: false,
                    value: 'Must contain a lowercase letter.'
                },
                {
                    rule: 'uppercase',
                    status: false,
                    value: 'Must contain an uppercase letter.'
                },
                {
                    rule: 'numerical',
                    status: false,
                    value: 'Must contain a numerical character.'
                },
                {
                    rule: 'special',
                    status: false,
                    value: 'Must contain a special character.'
                },
                {
                    rule: 'length',
                    status: false,
                    value: 'Must be at least 8 characters in length.'
                }
            ],
            rule_met_icon: 'mdi-check',
            rule_fail_icon: 'mdi-close',
            password: '',
            password_visibility: true,
            confirm_password: '',
            token: null
        };
    },

    methods: {
        validateAllCreationRules() {
            var allCreationRulesSatisfied = true;
            for (var i = 0; i < this.password_creation_rules.length; i++) {
                if (!this.password_creation_rules[i].status) {
                    allCreationRulesSatisfied = false;
                }
            }
            return allCreationRulesSatisfied;
        },

        emitConfirmPassword() {
            this.$emit('confirmPasswordUpdate', this.confirm_password);
        },

        emitToken() {
            this.$emit('tokenUpdate', this.token);
        },

        emitLogin(e) {
            //sign in if enter is pressed
            this.$emit('triggerLogin');
        },

        validatePassword(value) {
            this.updateCreationRule('lowercase', /[a-z]/.test(value));
            this.updateCreationRule('uppercase', /[A-Z]/.test(value));
            this.updateCreationRule('numerical', /\d/.test(value));
            this.updateCreationRule(
                'special',
                /[~`!@#$%^&*+=\-[\]\\';,/{}|\\":<>?]/.test(value)
            );
            this.updateCreationRule('length', value.length >= 8);

            this.$emit('setPasswordUpdate', this.password);
            this.$emit('creationRuleUpdate', this.validateAllCreationRules());
        },

        updateCreationRule(rule, set) {
            for (var i = 0; i < this.password_creation_rules.length; i++) {
                if (this.password_creation_rules[i].rule == rule) {
                    this.password_creation_rules[i].status = set;
                }
            }
        }
    }
};
</script>

<style>
.link {
    font-size: 13px;
    color: grey;
}

.login-button {
    background: linear-gradient(90deg, #ff7a10, #ff7a10) !important;
    color: white !important;
    border-radius: 100px !important;
    margin-bottom: 10px;
}
</style>
