<template>
    <v-tooltip
        v-if="!this.is_lane_drilldown"
        top
        open-delay="350"
        max-width="25%"
        :disabled="!show"
    >
        <template v-slot:activator="{ on, attrs }">
            <div
                id="draw-score-circle"
                style="width: 100%; height: 7.5em"
                v-bind="attrs"
                v-on="on"
            ></div>
        </template>
        <span>{{ getTooltipText() }}</span>
    </v-tooltip>
    <v-tooltip v-else top open-delay="350" max-width="25%" :disabled="!show">
        <template v-slot:activator="{ on, attrs }">
            <div
                id="draw-score-circle"
                style="width: 100%; height: 5.5em"
                v-bind="attrs"
                v-on="on"
            ></div>
        </template>
        <span>{{ getTooltipText() }}</span>
    </v-tooltip>
</template>

<script>
import * as Two from 'twojs-ts';
export default {
    name: 'ScoreCircle',
    props: [
        'averageScore',
        'gaugeCardWidth',
        'browserZoom',
        'is_lane_drilldown',
        'has_incomplete_score'
    ],
    computed: {
        isDark() {
            return this.$vuetify.theme.dark;
        }
    },
    watch: {
        averageScore(val) {
            this.score_avg = parseFloat(val);
            document.getElementById('draw-score-circle').innerHTML = '';
            this.displayScore();
        },

        gaugeCardWidth(val) {
            this.dialWidth = val;
            document.getElementById('draw-score-circle').innerHTML = '';
            this.displayScore();
        },

        browserZoom(val) {
            this.zoom = val;
            document.getElementById('draw-score-circle').innerHTML = '';
            this.displayScore();
        },

        isDark() {
            document.getElementById('draw-score-circle').innerHTML = '';
            this.displayScore();
        }
    },

    data: function () {
        return {
            score_avg: 0,
            dialWidth: 0,
            zoom: 0,
            canvasStyle: !this.is_lane_drilldown
                ? 'width: 100%; height: 7.5em'
                : 'width: 100%; height: 5.5em',
            show:
                this.has_incomplete_score == undefined ||
                this.has_incomplete_score == false
                    ? false
                    : true
        };
    },

    components: {},

    methods: {
        displayScore() {
            this.show =
                this.has_incomplete_score == undefined ||
                this.has_incomplete_score == false
                    ? false
                    : true;
            var elem = document.getElementById('draw-score-circle');
            var clientWidth = elem.clientWidth;
            var clientHeight = elem.clientHeight;

            var params = {
                width: clientWidth,
                height: clientHeight
            };
            var two = new Two(params).appendTo(elem);
            var score = parseFloat(this.score_avg).toFixed(0);

            var zoomlevel = this.zoom / 100;
            if (zoomlevel == 0 || zoomlevel > 1) {
                zoomlevel = 1;
            }

            var radius;
            if (0.85 * params.width <= params.height) {
                radius = 0.44 * params.width * zoomlevel;
            } else {
                radius = 0.54 * params.height * zoomlevel;
            }
            radius *= 0.735;

            function drawArc(start, end, color) {
                var points = [];
                for (var degrees = start; degrees < end; degrees++) {
                    var theta = (degrees * Math.PI) / 180;
                    var x = radius * Math.cos(theta);
                    var y = radius * Math.sin(theta);
                    points.push(new Two.Anchor(-x, -y));
                }
                var path = two.makeCurve(points, true);

                path.noFill().linewidth = parseInt(radius * (10 / 130));
                path.cap = path.join = 'round';
                path.stroke = color;
            }

            var ranges = [
                {
                    start: 0,
                    end: 69,
                    color: this.isDark ? 'rgba(242, 55, 86, 1)' : '#F23756'
                },
                {
                    start: 70,
                    end: 79,
                    color: this.isDark ? 'rgba(255, 171, 108, 0.8)' : '#FFAB6C'
                },
                {
                    start: 80,
                    end: 89,
                    color: this.isDark ? 'rgba(26, 171, 255, 0.75)' : '#1AABFF'
                },
                {
                    start: 90,
                    end: 100,
                    color: this.isDark ? 'rgba(42, 192, 142, 0.75)' : '#2AC08E'
                }
            ];

            var scoreText;
            var indicatorText;

            if (
                score == -1 ||
                score == '-1' ||
                score == undefined ||
                score == null ||
                isNaN(score)
            ) {
                score = 'Score not available';
                drawArc(0, 360, '#BDBDBD');
                scoreText = two.makeText(score.toString(), 0, 0, {
                    family: 'Roboto, sans-serif',
                    size: radius * (23 / 130),
                    leading: 50,
                    weight: 300
                });
            } else {
                ranges.forEach(function (item) {
                    if (score <= item['end'] && score >= item['start']) {
                        drawArc(0, 360, item['color']);
                    }
                });
                scoreText = two.makeText(
                    score.toString(),
                    this.has_incomplete_score ? -4 : 0,
                    0,
                    {
                        family: 'Roboto, sans-serif',
                        size: radius * (60 / 130),
                        leading: 50,
                        weight: 300
                    }
                );
            }

            if (this.has_incomplete_score) {
                indicatorText = two.makeText(
                    '!',
                    radius * (60 / 130) * 0.33 * score.toString().length,
                    0,
                    {
                        family: 'Roboto, sans-serif',
                        size: radius * (60 / 130),
                        leading: 50,
                        weight: 300
                    }
                );
                indicatorText.fill = 'red';
            }

            scoreText.fill = this.isDark ? '#E1E1E1' : 'black';

            two.scene.translation.set(two.width / 2, two.height / 2);
            two.update();
        },

        getTooltipText() {
            if (this.is_lane_drilldown) {
                return 'This lane has brokers that are missing metrics for a complete score. Please click on any of the colored score chips below for more details.';
            }
            return 'This broker is missing metrics for a complete score. Please click on any of the colored score chips below for more details.';
        }
    },

    mounted() {
        // this.displayScore();
    }
};
</script>

<style>
.gauge-positioning {
    margin-top: -50px !important;
    margin-bottom: 15px !important;
}
</style>
