<template>
    <div id="app">
        <router-view :key="$route.fullPath"></router-view>
    </div>
</template>

<script>
import * as stateAPI from './stateAPI';

export default {
    name: 'App',

    components: {},

    methods: {
        handleThemeChange(newVal) {
            const htmlTag = document.documentElement;
            if (newVal) {
                htmlTag.classList.add('dark-mode');
            } else {
                htmlTag.classList.remove('dark-mode');
            }
        }
    },

    data: () => ({
        //
    }),

    beforeMount() {
        this.$vuetify.theme.dark = stateAPI.getStateProperty(this, 'darkMode');
        this.handleThemeChange(this.$vuetify.theme.dark);
        this.$watch('$vuetify.theme.dark', this.handleThemeChange);
    }
};
</script>

<style>
html {
    overflow-y: auto;
}

/* width */
html.dark-mode ::-webkit-scrollbar {
    width: 10px;
}

/* Track */
html.dark-mode ::-webkit-scrollbar-track {
    background: #272727;
}

/* Handle */
html.dark-mode ::-webkit-scrollbar-thumb {
    background: #3f3f3f;
}

/* Handle on hover */
html.dark-mode ::-webkit-scrollbar-thumb:hover {
    background: #4c4c4c;
}

.theme--dark.v-data-table {
    background-color: var(--v-dataTableBackground-base) !important;
}

.main-container {
    padding: 48px 0px 0px 43px !important;
}
</style>
