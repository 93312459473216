<template>
    <v-card elevation="5" style="height: 100%" class="d-flex flex-column">
        <v-card-title
            class="font-weight-light"
            style="margin-bottom: 10px; overflow-y: hidden; white-space: nowrap"
        >
            Key Metrics
            <v-spacer></v-spacer>
            <v-row class="font-weight-light text-body-2">
                <v-col cols="12">
                    <v-row ref="oldDateRange" dense>
                        <v-spacer></v-spacer>
                        <template v-if="showAggTabs && tabIndex != 2">
                            <v-tabs
                                color="#0091ff"
                                right
                                optional
                                style="width: 100% !important"
                                v-model="keyMetricsToggle"
                                @change="
                                    $emit('periodTabChange', keyMetricsToggle)
                                "
                                ref="selectorTabs"
                                class="selectorDateTabs"
                            >
                                <v-tab
                                    style="max-width: 10px !important"
                                    :disabled="queryInProgress"
                                    >14D</v-tab
                                >
                                <v-divider vertical inset></v-divider>
                                <v-tab
                                    style="max-width: 10px !important"
                                    :disabled="queryInProgress"
                                    >30D</v-tab
                                >
                                <v-divider vertical inset></v-divider>
                                <v-tab
                                    style="max-width: 10px !important"
                                    :disabled="queryInProgress"
                                    >60D</v-tab
                                >
                            </v-tabs>
                        </template>
                        <template v-else-if="tabIndex == 2">
                            <span
                                style="padding-top: 14px; padding-bottom: 14px"
                                >{{
                                    'Projection Period: ' + getProjectionDates()
                                }}</span
                            >
                        </template>
                        <template v-else>
                            <template
                                v-if="!isPeriodNull && isComparisonEnabled"
                            >
                                {{ oldPeriodDates }} vs.
                            </template>
                            {{ periodDates }}
                        </template>
                    </v-row>
                </v-col>
            </v-row>
        </v-card-title>
        <v-tabs-items v-model="tabIndex">
            <v-tab-item v-for="item in tabItems" :key="item.tab">
                <v-card flat class="pt-0 pb-4">
                    <v-card-actions class="pa-0">
                        <v-container
                            v-if="!(tabIndex == 2 && projectionPeriod < 15)"
                        >
                            <v-row v-if="isShipperDashboard">
                                <v-col
                                    :cols="isShipperDashboard ? 4 : 1.7"
                                    v-for="(metric, i) in filterMetrics(
                                        keyMetrics
                                    )"
                                    :key="i"
                                    class="justify-center"
                                >
                                    <v-col>
                                        <v-row
                                            class="justify-center metric-value"
                                        >
                                            {{ metric.value }}
                                            <v-icon
                                                medium
                                                :color="
                                                    getMetricColor(metric.color)
                                                "
                                                v-text="metric.icon"
                                            ></v-icon>
                                            <span
                                                class="caption font-italic font-weight-light"
                                            >
                                                {{ metric.change }}
                                            </span>
                                        </v-row>
                                        <v-row
                                            class="justify-center font-weight-light"
                                            >{{ metric.title }}</v-row
                                        >
                                    </v-col>
                                </v-col>
                            </v-row>

                            <v-row v-if="!isShipperDashboard">
                                <v-col
                                    cols="1.7"
                                    v-for="(metric, i) in filterMetrics(
                                        keyMetrics
                                    )"
                                    :key="i"
                                    class="justify-center"
                                >
                                    <v-col>
                                        <v-row
                                            class="justify-center metric-value"
                                        >
                                            {{ metric.value }}
                                            <v-icon
                                                medium
                                                :color="
                                                    getMetricColor(metric.color)
                                                "
                                                v-text="metric.icon"
                                            ></v-icon>
                                            <span
                                                class="caption font-italic font-weight-light"
                                            >
                                                {{ metric.change }}
                                            </span>
                                        </v-row>
                                        <v-row
                                            class="justify-center font-weight-light"
                                            >{{ metric.title }}</v-row
                                        >
                                    </v-col>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-container v-else>
                            <v-row
                                class="justify-center align-center text-subtitle-1 font-weight-light"
                                style="height: 10rem"
                            >
                                Not enough data to project.
                            </v-row>
                        </v-container>
                    </v-card-actions>
                </v-card>
            </v-tab-item>
        </v-tabs-items>
        <v-spacer></v-spacer>
        <v-card-actions id="kmTabStyle" class="ma-0 pa-0">
            <v-tabs v-model="tabIndex" color="blue" grow id="kmtabs">
                <v-tabs-slider color="transparent"></v-tabs-slider>
                <v-tab
                    key="financials"
                    class="moneyTab text-body-1 text-capitalize"
                    active-class="moneyTabActive"
                >
                    Financials
                    <v-icon class="pl-1" dense> mdi-currency-usd </v-icon>
                </v-tab>
                <v-tab
                    key="performance"
                    class="performanceTab text-body-1 text-capitalize"
                    active-class="performanceTabActive"
                >
                    Performance
                    <v-icon class="pl-1" small> mdi-poll </v-icon>
                </v-tab>
                <v-tab
                    key="projections"
                    class="projectionTab text-body-1 text-capitalize"
                    active-class="projectionTabActive"
                >
                    Projections
                    <v-icon class="pl-1" dense> mdi-trending-up </v-icon>
                </v-tab>
            </v-tabs>
        </v-card-actions>
    </v-card>
</template>
<script>
import * as stateAPI from '../stateAPI';
import * as utils from '../utils';

export default {
    /**
     * This component is for displaying the key metrics
     */
    name: 'KeyMetrics',
    props: [
        'is_loading',
        'key_metrics',
        'is_dashboard_km',
        'show_agg_tabs',
        'is_period_null',
        'is_comparison_enabled',
        'query_in_progress',
        'key_metrics_toggle',
        'old_period_dates',
        'period_dates'
    ],

    watch: {
        key_metrics_toggle(val) {
            this.keyMetricsToggle = val;
        },

        updateKeyMetrics(val) {
            this.keyMetrics = val[0];
        },

        is_loading(val) {
            this.isLoading = val;
        },

        is_period_null(val) {
            this.isPeriodNull = val;
        },

        is_comparison_enabled(val) {
            this.isComparisonEnabled = val;
        },

        query_in_progress(val) {
            this.queryInProgress = val;
        },

        old_period_dates(val) {
            this.oldPeriodDates = val;
        },

        period_dates(val) {
            this.periodDates = val;
        },

        show_agg_tabs(val) {
            this.showAggTabs = val;
        }
    },

    methods: {
        filterMetrics(km) {
            var result = [];
            for (const entry of km) {
                if (this.tabItems[this.tabIndex].content.has(entry.id)) {
                    result.push(entry);
                }
            }
            return result;
        },

        getProjectionDates() {
            let start = new Date(
                stateAPI.getStateProperty(this, 'startDate')
            ).toLocaleDateString();
            let end = stateAPI
                .getStateProperty(this, 'defaultProjectionDate')
                .split('-');
            return start + '-' + end[1] + '/' + end[2] + '/' + end[0];
        },

        // TODO - this is a hack for now, but this should be fixed
        getMetricColor(colorTabKey) {
            if (colorTabKey == 'projection') {
                return this.$vuetify.theme.dark ? '#0091ff' : 'blue darken-4';
            }

            return colorTabKey;
        }
    },

    data: function () {
        return {
            isLoading: this.is_loading,
            keyMetrics: this.key_metrics,
            isShipperDashboard: this.is_dashboard_km,
            showAggTabs: this.show_agg_tabs,
            isPeriodNull: this.is_period_null,
            isComparisonEnabled: this.is_comparison_enabled,
            queryInProgress: this.query_in_progress,
            keyMetricsToggle: this.key_metrics_toggle,
            periodDates: this.period_dates,
            oldPeriodDates: this.old_period_dates,
            tabIndex: 0,
            tabItems: [
                {
                    tab: 'financials',
                    content: new Set([
                        'avg_margin',
                        'avg_margin_dollars',
                        'total_volume',
                        'total_spend',
                        'total_margin',
                        'avg_spend',
                        'avg_cost'
                    ])
                },
                {
                    tab: 'performance',
                    content: new Set([
                        'avg_clt',
                        'avg_prebook',
                        'avg_blt',
                        'avg_otp',
                        'avg_otd',
                        'prebook_percent'
                    ])
                },
                {
                    tab: 'projections',
                    content: new Set([
                        'proj_avg_margin',
                        'proj_avg_margin_dollars',
                        'proj_total_volume',
                        'proj_total_spend',
                        'proj_total_margin',
                        'proj_avg_spend',
                        'proj_avg_cost'
                    ])
                }
            ],
            projectionPeriod: utils.PROJECTION_PERIOD
        };
    }
};
</script>
<style>
#kmTabStyle {
    vertical-align: flex-end;
    align-items: flex-end;
    align-content: flex-end;
}

.moneyTab {
    border-right: 0.5px solid;
    border-top: 0.5px solid;
}

.moneyTabActive {
    border-right: 0px solid;
    border-top: 0px solid;
}

.performanceTab {
    border-right: 0.5px solid;
    border-left: 0.5px solid;
    border-top: 0.5px solid;
}

.performanceTabActive {
    border-right: 0px solid;
    border-left: 0px solid;
    border-top: 0px solid;
}

.projectionTab {
    border-left: 0.5px solid;
    border-top: 0.5px solid;
}

.projectionTabActive {
    border-left: 0px solid;
    border-top: 0px solid;
}
</style>
