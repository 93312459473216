var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{attrs:{"cols":"5"}},[_c('v-autocomplete',{attrs:{"dense":"","items":_vm.hourVals,"hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var parent = ref.parent;
var item = ref.item;
var on = ref.on;
var attr = ref.attr;
return [_c('v-list-item',_vm._g(_vm._b({staticStyle:{"min-height":"32px"}},'v-list-item',attr,false),on),[_vm._v(_vm._s(item))])]}}]),model:{value:(_vm.value[0]),callback:function ($$v) {_vm.$set(_vm.value, 0, $$v)},expression:"value[0]"}})],1),_c('v-col',{staticClass:"px-0 mx-0 d-flex justify-center",attrs:{"cols":"1"}},[_c('div',{staticClass:"pt-2"},[_vm._v(":")])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-autocomplete',{attrs:{"dense":"","items":_vm.minuteVals,"hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var parent = ref.parent;
var item = ref.item;
var on = ref.on;
var attr = ref.attr;
return [_c('v-list-item',_vm._g(_vm._b({staticStyle:{"min-height":"32px"}},'v-list-item',attr,false),on),[_vm._v(_vm._s(item))])]}}]),model:{value:(_vm.value[1]),callback:function ($$v) {_vm.$set(_vm.value, 1, $$v)},expression:"value[1]"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }