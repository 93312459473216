<template>
    <v-container
        fluid
        :style="{
            background: $vuetify.theme.themes[theme].background,
            height: '100vh'
        }"
        dense
    >
        <v-row dense class="align-center pl-4">
            <v-icon
                large
                :color="$vuetify.theme.themes[theme].headerIcon"
                class="mr-4"
                >mdi-state-machine</v-icon
            >
            <template>
                {{ lane_equipment_type + ': ' + laneName }}
            </template>
            <v-spacer></v-spacer>
            <v-chip
                v-for="(keyval, index) in Array.from(filterChips)"
                :key="index"
                color="#FF6F00"
                text-color="white"
                class="mr-2"
                close
                @click:close="resetFilters(keyval[0])"
            >
                {{ keyval[1] }}
            </v-chip>
            <v-autocomplete
                :filter="customFilter"
                :items="dropdownSBList"
                :label="
                    'Search for a ' + (!isBrokerUser ? 'Broker' : 'Shipper')
                "
                hide-details
                dense
                item-text="name"
                item-value="id"
                v-model="selectedSB"
                style="max-width: 300px"
                @input="searchselectedSB"
                class="pl-2"
            >
                <template>
                    <v-icon
                        :color="$vuetify.theme.themes[theme].headerIcon"
                        style="margin-right: 10px"
                        >mdi-lan-connect</v-icon
                    >
                </template>
            </v-autocomplete>

            <Filters
                @applySelectedFilters="handleEmmittedFilters"
                :time_period="timePeriod"
                :volume_threshold="volumeThreshold"
                :is_lane_drilldown="true"
                :is_analytics="true"
                :updateData="[this.filters, this.isComparisonEnabled]"
            />
        </v-row>
        <v-row dense v-resize="onResize">
            <v-col cols="2">
                <v-card ref="gaugeCard" elevation="5" style="height: 100%">
                    <v-card-text
                        class="d-flex flex-column align-center justify-center"
                        style="height: 100%"
                    >
                        <div
                            class="text-h1"
                            :style="`display: flex; align-items: center; margin-bottom:14px; color:${getScoreColor(
                                this.averageScore
                            )}`"
                        >
                            <!-- <v-icon class="score-icon mr-4" large color="white">mdi-gauge</v-icon> -->
                            {{ formatDecimal(this.averageScore, 0) }}
                        </div>
                        <p style="font-size: 16px; margin-bottom: 0px">
                            Truce Score
                        </p>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="10">
                <KeyMetrics
                    ref="km"
                    :is_loading="isLoading"
                    :key_metrics="key_metrics"
                    :is_dashboard_km="false"
                    :updateKeyMetrics="[keyMetrics]"
                    :show_agg_tabs="showAggTabs"
                    @periodTabChange="handleKeyMetricTabChange"
                    :is_period_null="isPeriodNull"
                    :is_comparison_enabled="isComparisonEnabled"
                    :query_in_progress="queryInProgress"
                    :key_metrics_toggle="keyMetricsToggle"
                    :old_period_dates="oldPeriodDates"
                    :period_dates="periodDates"
                />
            </v-col>
        </v-row>

        <!-- LANE DRILLDOWN GRAPHS -->
        <v-row dense>
            <v-col cols="6">
                <v-card elevation="5" height="100%" style="overflow: hidden">
                    <v-card-title
                        class="font-weight-light pb-0"
                        style="white-space: nowrap"
                    >
                        <v-row>
                            <v-col cols="4" class="pb-0 lg-4 md-2"
                                >Spend and Truck Cost</v-col
                            >
                            <v-spacer />
                            <v-col cols="6" class="text-right pr-0">
                                <v-btn
                                    style="vertical-align: top"
                                    plain
                                    :ripple="false"
                                    @click="changeCostSpendGraph('LINE')"
                                    :class="
                                        (costSpendGraphIndex === 0
                                            ? 'selectedTab'
                                            : '') + ' text-none'
                                    "
                                    >Time</v-btn
                                >
                                <v-divider
                                    vertical
                                    class="border-opacity-50"
                                ></v-divider>
                                <v-btn
                                    style="vertical-align: top"
                                    plain
                                    :ripple="false"
                                    @click="changeCostSpendGraph('BAR')"
                                    :class="
                                        (costSpendGraphIndex === 1
                                            ? 'selectedTab'
                                            : '') + ' text-none'
                                    "
                                    >Day of Week</v-btn
                                >
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-actions class="justify-center graphSlide">
                        <Graph
                            class="costSpendGraph pr-2"
                            :rawData="costSpendVolumeData"
                            graphName="costSpendLine"
                            :timeRange="timePeriod"
                            :isBroker="false"
                        ></Graph>
                        <Graph
                            class="costSpendGraph pr-2"
                            :rawData="costVolumeBarData"
                            graphName="costSpendBar"
                            :timeRange="timePeriod"
                            :isBroker="false"
                        ></Graph>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="6">
                <v-card elevation="5" height="100%" style="overflow: hidden">
                    <v-card-title
                        class="font-weight-light pb-0"
                        style="white-space: nowrap"
                    >
                        <v-row>
                            <v-col cols="4" class="pb-0 lg-4 md-2"
                                >Volume</v-col
                            >
                            <v-spacer />
                            <v-col cols="6" class="text-right pr-0">
                                <v-btn
                                    style="vertical-align: top"
                                    plain
                                    :ripple="false"
                                    @click="changeVolumeGraph('LINE')"
                                    :class="
                                        (volumeGraphIndex === 0
                                            ? 'selectedTab'
                                            : '') + ' text-none'
                                    "
                                    >Time</v-btn
                                >
                                <v-divider
                                    vertical
                                    class="border-opacity-50"
                                ></v-divider>
                                <v-btn
                                    style="vertical-align: top"
                                    plain
                                    :ripple="false"
                                    @click="changeVolumeGraph('BAR')"
                                    :class="
                                        (volumeGraphIndex === 1
                                            ? 'selectedTab'
                                            : '') + ' text-none'
                                    "
                                    >Day of Week</v-btn
                                >
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-actions class="justify-center pt-0 graphSlide">
                        <Graph
                            class="volumeGraph pr-2"
                            :rawData="costSpendVolumeData"
                            graphName="volumeLine"
                            :timeRange="timePeriod"
                            :isBroker="false"
                        ></Graph>
                        <Graph
                            class="volumeGraph pr-2"
                            :rawData="costVolumeBarData"
                            graphName="volumeBar"
                            :timeRange="timePeriod"
                            :isBroker="false"
                        ></Graph>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <v-row dense>
            <v-col cols="12">
                <TableBundle
                    ref="bundle"
                    :is_loading="false"
                    :is_broker_user="isBrokerUser"
                    :is_lane_drilldown="true"
                    :is_lane_data="isLaneData"
                    :displayed_data="displayedData"
                    :has_incomplete_score="hasIncompleteScore"
                    @parseURL="queryForNewData"
                    @handleFilters="handleEmmittedFilters"
                    @focusLanes="handleFocusLanes"
                    @favoriteLanes="handleFavoriteLanes"
                    :time_period="timePeriod"
                    :start_date="startDate"
                    :end_date="endDate"
                    :customer_direct_enabled="isCustomerDirectEnabled"
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import TableBundle from '../containers/TableBundle.vue';
import Filters from '../components/Filters';
import KeyMetrics from '../components/KeyMetrics';
import Graph from '../components/Graph';
import * as user_analytics from '../analytics/sendAnalyticsEvent';
import * as format from '../formatShipmentData';
import * as stateAPI from '../stateAPI';
import * as utils from '../utils';
import * as computeKeyMetrics from '../computeKeyMetrics';
import _ from 'lodash';

export default {
    name: 'Drilldown',
    props: ['drilldown_object', 'has_incomplete_score', 'crumbs_prop'],

    components: {
        TableBundle,
        Filters,
        KeyMetrics,
        Graph
    },

    watch: {
        // Drilldown_object has 3 required attributes: origin, destination, equipmenttype
        drilldown_object: {
            handler() {
                // New Modal Opened
                this.queryForNewData();
            },
            deep: true
        }
    },

    computed: {
        theme() {
            return this.$vuetify.theme.dark ? 'dark' : 'light';
        },
        laneName() {
            return (
                this.drilldown_object.origin +
                ' -> ' +
                this.drilldown_object.destination
            );
        },
        lane_equipment_type() {
            return this.drilldown_object.equipmenttype;
        },
        selectedSB: {
            get() {
                return this.drilldown_object.sb_id != undefined
                    ? this.drilldown_object.sb_id
                    : null;
            },
            set(newSBId) {
                this.drilldown_object.sb_id = newSBId;
                return newSBId;
            }
        }
    },

    methods: {
        formatScore: format.formatScore,
        formatPercent: format.formatPercent,
        formatDollars: format.formatDollars,
        formatDecimal: format.formatDecimal,

        getScoreColor(score) {
            const ranges = [
                { start: 0, end: 70, color: '#F23756' },
                { start: 70, end: 80, color: '#FFAB6C' },
                { start: 80, end: 90, color: '#1AABFF' },
                { start: 90, end: 100, color: '#2AC08E' }
            ];
            for (const range of ranges) {
                if (
                    Math.round(score) >= range.start &&
                    Math.round(score) < range.end
                ) {
                    return range.color;
                }
            }
            return '#F23756';
        },

        resetFilters(objType) {
            utils.resetFilters(
                objType,
                this.endDate,
                this.timePeriod,
                this.tickLabels,
                this.filterChips,
                this.volumeThreshold,
                this.isCustomerDirectEnabled,
                this
            );

            this.filters.isCustomerDirectEnabled = this.isCustomerDirectEnabled;
            if (this.filterChips.size == 0) {
                this.showFilterChips = false;
                this.showAggTabs = true;
                this.queryForNewData();
            } else {
                this.handleEmmittedFilters(
                    this.endDate,
                    this.timePeriod,
                    this.volumeThreshold,
                    this.selectedEquipmentTypes,
                    false,
                    this.filters
                );
            }
        },

        handleEmmittedFilters(
            date = this.endDate,
            timePeriod = this.timePeriod,
            volumeThreshold = this.volumeThreshold,
            equipmentTypes = this.selectedEquipmentTypes,
            isComparisonEnabled = this.isComparisonEnabled,
            filters = this.filters
        ) {
            const milliToDay = 1000 * 60 * 60 * 24;

            // store filters state
            this.filters = filters;

            // set time period
            this.timePeriod = timePeriod;
            stateAPI.setStateProperty(this, 'storeTimePeriod', this.timePeriod);

            // set end date
            this.endDate = new Date(new Date(date).setHours(5, 0, 0));
            stateAPI.setStateProperty(this, 'storeEndDate', this.endDate);

            // calculate start date
            var start_offset = parseInt(timePeriod);
            this.startDate = new Date(
                new Date(
                    new Date(this.endDate).getTime() - start_offset * milliToDay
                ).setHours(5, 0, 0)
            );
            this.load = false;

            this.isPeriodOneDay =
                this.endDate.toLocaleDateString() ==
                this.startDate.toLocaleDateString()
                    ? true
                    : false;

            // calculate delta end date
            this.deltaEndDate = new Date(
                new Date(
                    new Date(this.endDate).getTime() -
                        parseInt(timePeriod) * milliToDay
                ).setHours(5, 0, 0)
            );

            // calculate delta start date
            this.deltaStartDate = new Date(
                new Date(
                    this.deltaEndDate.getTime() -
                        (parseInt(timePeriod) - 1) * milliToDay
                ).setHours(5, 0, 0)
            );

            // set volume threshold
            this.volumeThreshold = volumeThreshold;

            // set equipment types
            this.selectedEquipmentTypes = equipmentTypes;

            // set customer direct flag
            this.isCustomerDirectEnabled = filters.isCustomerDirectEnabled;
            stateAPI.setStateProperty(
                this,
                'storeIsCustomerDirectEnabled',
                this.isCustomerDirectEnabled
            );

            // set new filter chips - call from utils
            // dont display timePeriod if enddate is the only filter chip
            if (
                !(this.filterChips.size == 1 && this.filterChips.has('enddate'))
            ) {
                this.setFilterChips(
                    date,
                    timePeriod,
                    volumeThreshold,
                    equipmentTypes,
                    filters.isCustomerDirectEnabled
                );
            }
            // toggle off tabs
            this.showFilterChips = this.filterChips.size == 0 ? false : true;
            this.showAggTabs = !this.showFilterChips;
            this.keyMetricsToggle = null;
            stateAPI.setStateProperty(
                this,
                'storeKeyMetricsToggle',
                this.keyMetricsToggle
            );

            this.isComparisonEnabled = isComparisonEnabled;
            stateAPI.setStateProperty(
                this,
                'storeIsComparisonEnabled',
                this.isComparisonEnabled
            );

            this.queryForNewData();
        },

        setFilterChips(
            date,
            timePeriod,
            volumeThreshold,
            selectedEquipmentTypes,
            isCustomerDirectEnabled
        ) {
            var newFilterChips = new Map();

            const defaultStartDate = new Date(
                new Date(stateAPI.getStateProperty(this, 'startDate')).setHours(
                    5,
                    0,
                    0
                )
            );

            const defaultEndDate = new Date();
            const defaultTimePeriod = (
                (new Date(defaultEndDate).getTime() -
                    new Date(defaultStartDate).getTime()) /
                    (24 * 60 * 60 * 1000) +
                1
            ).toFixed(0);

            if (
                new Date(date).toLocaleDateString() !=
                    defaultEndDate.toLocaleDateString() ||
                new Date(this.startDate).toLocaleDateString() !=
                    defaultStartDate.toLocaleDateString()
            ) {
                newFilterChips = utils.updateFilterChips(
                    'dateRange',
                    'Date Range: ',
                    this.isPeriodOneDay
                        ? [new Date(date).toLocaleDateString()]
                        : [
                              new Date(this.startDate).toLocaleDateString() +
                                  ' - ' +
                                  new Date(date).toLocaleDateString()
                          ],
                    newFilterChips.size != 0 ? newFilterChips : this.filterChips
                );
            }

            if (volumeThreshold > 0) {
                newFilterChips = utils.updateFilterChips(
                    'volumeThreshold',
                    'Volume: ',
                    [parseInt(volumeThreshold)],
                    newFilterChips.size != 0 ? newFilterChips : this.filterChips
                );
            }

            var unselectedEquipmentTypes = new Set(this.EQUIPMENT_TYPE_LIST);
            // Update filter chips based on equipment types selected in filter menu.

            if (
                unselectedEquipmentTypes.size != selectedEquipmentTypes.length
            ) {
                for (let i = 0; i < selectedEquipmentTypes.length; i++) {
                    const equipmentType = selectedEquipmentTypes[i];
                    // This equipment type was selected, so prune it from the unselected set.
                    unselectedEquipmentTypes.delete(equipmentType);
                    // if (this.isApplyClicked) {
                    newFilterChips = utils.updateFilterChips(
                        'equipment' + '_' + equipmentType,
                        'Equip. Type: ',
                        [equipmentType],
                        newFilterChips.size != 0
                            ? newFilterChips
                            : this.filterChips
                    );
                    // }
                }
            }

            if (isCustomerDirectEnabled) {
                newFilterChips = utils.updateFilterChips(
                    'isCustomerDirectEnabled',
                    'Customer Direct',
                    [''],
                    newFilterChips.size != 0 ? newFilterChips : this.filterChips
                );
            }

            this.filterChips = newFilterChips;
        },

        handleKeyMetricTabChange(tabNumber) {
            this.isLoading = true;
            this.keyMetricsToggle = tabNumber;
            stateAPI.setStateProperty(
                this,
                'storeKeyMetricsToggle',
                this.keyMetricsToggle
            );
            var tp = this.tabValues[tabNumber];
            if (tp == undefined || tp == null) {
                const today = new Date(new Date().setHours(5, 0, 0));
                const start = new Date(
                    new Date(
                        stateAPI.getStateProperty(this, 'startDate')
                    ).setHours(1, 0, 0)
                );
                tp = (
                    (today.getTime() - start.getTime()) /
                        (24 * 60 * 60 * 1000) +
                    1
                ).toFixed(0);
                this.endDate = today;
                this.startDate = start;
            }

            this.handleKeyMetricTabSelected(tp);
        },

        handleKeyMetricTabSelected(timePeriod) {
            // calculate delta end date
            this.deltaEndDate = new Date(
                new Date(
                    new Date(this.endDate).getTime() -
                        parseInt(timePeriod) * 1000 * 60 * 60 * 24
                ).setHours(5, 0, 0)
            );

            // calculate start date
            this.startDate = new Date(
                new Date(
                    new Date(this.endDate).getTime() -
                        (parseInt(timePeriod) - 1) * 1000 * 60 * 60 * 24
                ).setHours(5, 0, 0)
            );
            this.deltaStartDate = new Date(
                new Date(
                    this.deltaEndDate.getTime() -
                        (parseInt(timePeriod) - 1) * 1000 * 60 * 60 * 24
                ).setHours(5, 0, 0)
            );

            //show tabs
            this.showAggTabs = true;
            this.showFilterChips = true;

            //set filter param
            const tickValIdx = this.tickValues.indexOf(timePeriod);
            this.filters.timeSlider = tickValIdx != -1 ? tickValIdx : 5;

            // set timePeriod
            this.timePeriod = timePeriod;

            this.setFilterChips(
                this.endDate,
                timePeriod,
                this.volumeThreshold,
                this.selectedEquipmentTypes
            );

            this.queryForNewData();
        },

        computeKeyMetricsFilters() {
            const showChange =
                this.filterChips.size == 0 && this.keyMetricsToggle == null
                    ? false
                    : this.isComparisonEnabled;
            computeKeyMetrics.getKeyMetricsForTabs(
                this.key_metrics,
                showChange,
                this,
                true
            );
        },

        onResize() {
            this.gaugeCardWidth = this.$refs.gaugeCard.$el.clientWidth;
            this.browserZoom = Math.round(window.devicePixelRatio * 100);
        },

        queryForNewData(isShipmentTableChange = false) {
            this.queryInProgress = isShipmentTableChange ? false : true;
            this.isLaneData = false;

            utils
                .getData(this, 'drilldown', isShipmentTableChange)
                .then((result) => {
                    utils.formatDates(
                        this.startDate,
                        this.endDate,
                        this.timePeriod,
                        this
                    );
                    this.computeKeyMetricsFilters();
                    this.metricTrendTypeTabs = this.metricTrendTypeIndex;
                    this.allowSecondLoad = true;
                    this.$refs.bundle.displayedData = this.displayedData;
                    this.queryInProgress = false;
                    this.$emit('stopLoading');
                });
        },

        changeMetricTrend(metricType, index) {
            if (
                !this.disableMetricTrendCPM &&
                !this.disableMetricTrendMPL &&
                !this.disableMetricTrendVOL
            ) {
                this.metricTrendType = metricType;
                this.metricTrendTypeIndex = index;
                if (metricType == 'CPM') {
                    this.metricTrends = _.cloneDeep(this.cpmGraphArray);
                } else if (metricType == 'MPL') {
                    this.metricTrends = _.cloneDeep(this.mplGraphArray);
                } else if (metricType == 'VOL') {
                    this.metricTrends = _.cloneDeep(this.volGraphArray);
                }
            }
        },

        changeCostSpendGraph(toSet) {
            this.costSpendGraphType = toSet;
            const graphs = document.querySelectorAll('.costSpendGraph');
            if (toSet == 'LINE') {
                this.costSpendGraphIndex = 0;
                const translate = 0;
                graphs.forEach(
                    (graphs) =>
                        (graphs.style.transform = `translateX(${translate}%)`)
                );
            } else if (toSet == 'BAR') {
                this.costSpendGraphIndex = 1;
                const translate = -100;
                graphs.forEach(
                    (graphs) =>
                        (graphs.style.transform = `translateX(${translate}%)`)
                );
            }
        },

        changeVolumeGraph(toSet) {
            this.volumeGraphType = toSet;
            const graphs = document.querySelectorAll('.volumeGraph');
            if (toSet == 'LINE') {
                this.volumeGraphIndex = 0;
                const translate = 0;
                graphs.forEach(
                    (graphs) =>
                        (graphs.style.transform = `translateX(${translate}%)`)
                );
            } else if (toSet == 'BAR') {
                this.volumeGraphIndex = 1;
                const translate = -100;
                graphs.forEach(
                    (graphs) =>
                        (graphs.style.transform = `translateX(${translate}%)`)
                );
            }
        },

        customFilter(_, queryText, itemText) {
            const cleanedQuery = queryText
                .replace(/[&@\\/#?!|^_,`+=()$~%.'";:*?<>{}]/g, ' ')
                .toLocaleLowerCase();
            const queryTokens = cleanedQuery.split(' ');
            const fillers = ['to', 'and'];
            for (const token of queryTokens) {
                if (fillers.includes(token)) {
                    continue;
                }
                if (itemText.toLocaleLowerCase().indexOf(token) === -1) {
                    return false;
                }
            }
            return true;
        },

        searchselectedSB() {
            this.isLoading = true;
            this.queryForNewData();
        },

        handleFocusLanes(val) {
            this.isLoading = true;
            this.focusLanes = val;
            this.queryForNewData(true);
        },

        handleFavoriteLanes(val) {
            this.isLoading = true;
            this.favoriteLanes = val;
            this.queryForNewData(true);
        }
    },

    beforeMount() {
        this.volumeThreshold = 0;

        const kmt = stateAPI.getStateProperty(this, 'storeKeyMetricsToggle');
        if (kmt == null || kmt == undefined) {
            this.handleEmmittedFilters(
                this.endDate,
                this.timePeriod,
                this.volumeThreshold,
                this.selectedEquipmentTypes,
                this.isComparisonEnabled,
                this.filters
            );
        } else {
            this.handleKeyMetricTabChange(kmt);
        }

        // Send pageView analytics event
        const email = stateAPI.getStateProperty(this, 'email');
        const company = stateAPI.getStateProperty(this, 'company');
        const role = stateAPI.getStateProperty(this, 'role');
        user_analytics.sendEvent(email, company, role, 'pageView', {
            pageSource: 'Lane Drilldown'.replaceAll(' ', '')
        });
    },

    mounted() {
        this.gaugeCardWidth = this.$refs.gaugeCard.$el.clientWidth;
        this.browserZoom = Math.round(window.devicePixelRatio * 100);
    },

    data: function () {
        return {
            drilldownObject: this.drilldown_object,
            allowSecondLoad: false,
            keyMetricsToggle: null,
            dataFreshnessLabel: '',
            showAggTabs: true,
            defaultTabValue: null,
            percentColor: '#0091ff',
            percentToDollars: false,
            averageScore: 0,

            index_by: 'brokerId',
            query_params: {},
            index_by_lane: 'laneId',
            query_params_lane: {},
            index_by_shipper: 'shipperId',
            query_params_shipper: {},
            displayedData: [], // TODO this is also used in filters.
            originalData: [], // TODO this is also used in filters.
            isLoading: true,
            key_metrics: [
                {
                    value: '0$',
                    title: 'Total Spend',
                    icon: '',
                    color: '',
                    id: 'total_spend',
                    change: ''
                },
                {
                    value: '0$',
                    title: 'Spend Per Load',
                    icon: '',
                    color: '',
                    id: 'avg_spend',
                    change: ''
                },
                {
                    value: '0$',
                    title: 'Truck Cost Per Load',
                    icon: '',
                    color: '',
                    id: 'avg_cost',
                    change: ''
                },
                {
                    value: '$0',
                    title: 'Margin Per Load',
                    icon: '',
                    color: '',
                    id: 'avg_margin_dollars',
                    change: ''
                },
                {
                    value: '0%',
                    title: 'Avg. Margin',
                    icon: '',
                    color: '',
                    id: 'avg_margin',
                    change: ''
                },
                {
                    value: '$0',
                    title: 'Total Margin',
                    icon: '',
                    color: '',
                    id: 'total_margin',
                    change: ''
                },
                {
                    value: '0',
                    title: 'Volume',
                    icon: '',
                    color: '',
                    id: 'total_volume',
                    change: ''
                },
                {
                    value: '0 Days',
                    title: 'Avg. CLT',
                    icon: '',
                    color: '',
                    id: 'avg_clt',
                    change: ''
                },
                {
                    value: '0 Days',
                    title: 'Avg. BLT',
                    icon: '',
                    color: '',
                    id: 'avg_blt',
                    change: ''
                },
                {
                    value: '0 Days',
                    title: 'Avg. Pre-Book',
                    icon: '',
                    color: '',
                    id: 'avg_prebook',
                    change: ''
                },
                {
                    value: '0%',
                    title: 'Prebook',
                    icon: '',
                    color: '',
                    id: 'prebook_percent',
                    change: ''
                },
                {
                    value: '0%',
                    title: 'On-Time Pickup',
                    icon: '',
                    color: '',
                    id: 'avg_otp',
                    change: ''
                },
                {
                    value: '0%',
                    title: 'On-Time Delivery',
                    icon: '',
                    color: '',
                    id: 'avg_otd',
                    change: ''
                },
                {
                    value: '0$',
                    title: 'Total Spend',
                    icon: '',
                    color: '',
                    id: 'proj_total_spend',
                    change: ''
                },
                {
                    value: '0$',
                    title: 'Spend Per Load',
                    icon: '',
                    color: '',
                    id: 'proj_avg_spend',
                    change: ''
                },
                {
                    value: '0$',
                    title: 'Truck Cost Per Load',
                    icon: '',
                    color: '',
                    id: 'proj_avg_cost',
                    change: ''
                },
                {
                    value: '0$',
                    title: 'Margin Per Load',
                    icon: '',
                    color: '',
                    id: 'proj_avg_margin_dollars',
                    change: ''
                },
                {
                    value: '0%',
                    title: 'Avg. Margin',
                    icon: '',
                    color: '',
                    id: 'proj_avg_margin',
                    change: ''
                },
                {
                    value: '0$',
                    title: 'Total Margin',
                    icon: '',
                    color: '',
                    id: 'proj_total_margin',
                    change: ''
                },
                {
                    value: '0',
                    title: 'Volume',
                    icon: '',
                    color: '',
                    id: 'proj_total_volume',
                    change: ''
                }
            ],

            crumbs: this.crumbs_prop,
            date: new Date().toISOString().substr(0, 10),

            periodDates: '', // TODO this is also used in filters.
            oldPeriodDates: '', // TODO this is also used in filters.
            // TODO(pass this as a prop to Filters.vue and modify those variables directly)
            keyMetrics: null,

            selectedEquipmentTypes: stateAPI.getStateProperty(
                this,
                'equipment_type_list'
            ),
            EQUIPMENT_TYPE_LIST: stateAPI.getStateProperty(
                this,
                'equipment_type_list'
            ),
            timePeriod: stateAPI.getStateProperty(this, 'storeTimePeriod'),
            tickLabels: [
                'Last 7',
                'Last 14',
                'Last 30',
                'Last 60',
                'Last 90',
                'All'
            ],
            tickValues: [7, 14, 30, 60, 90],

            // Chips v-model indexed by filter type (e.g. "equipment_Dry Van") with value (e.g. "Equip. Type: Dry Van").
            filterChips: new Map(),
            volumeThreshold: stateAPI.getStateProperty(
                this,
                'storeVolumeThreshold'
            ),

            gaugeCardWidth: 0,
            gaugeCardHeight: 0,
            browserZoom: 100,
            showStartDate: true,
            isPeriodOneDay: false,
            isPeriodNull: true,
            startDate: new Date(
                new Date(stateAPI.getStateProperty(this, 'startDate')).setHours(
                    5,
                    0,
                    0
                )
            ),
            isBrokerUser: stateAPI.getStateProperty(this, 'role') == 'broker',
            isAdminUser: stateAPI.getStateProperty(this, 'role') == 'admin',
            endDate: new Date(
                new Date(
                    stateAPI.getStateProperty(this, 'storeEndDate')
                ).setHours(5, 0, 0)
            ),
            deltaStartDate: null,
            deltaEndDate: null,
            //TODO: remove this variable once null check is in backend, it is not applicable
            metricTrendType: 'CPM',
            metricTrendTypeTabs: 'CPM',
            metricTrendTypeIndex: 0,

            // Cost Spend Graphs
            costSpendGraphType: 'LINE',
            costSpendGraphIndex: 0,
            // Volume Graphs
            volumeGraphType: 'LINE',
            volumeGraphIndex: 0,

            showFilterChips: false,
            isComparisonEnabled: stateAPI.getStateProperty(
                this,
                'storeIsComparisonEnabled'
            ),
            tabValues: [14, 30, 60],
            filters: {
                dates: stateAPI.getStateProperty(this, 'storeDates'),
                timeSlider: stateAPI.getStateProperty(this, 'storeTimeSlider'),
                volume: stateAPI.getStateProperty(this, 'storeVolumeThreshold'),
                equipment: stateAPI.getStateProperty(
                    this,
                    'equipment_type_list'
                ),
                isCustomerDirectEnabled: stateAPI.getStateProperty(
                    this,
                    'storeIsCustomerDirectEnabled'
                )
            },
            cpmGraphArray: [],
            mplGraphArray: [],
            volGraphArray: [],
            metricTrends: null,
            isDashboard: false,
            disableMetricTrendCPM: true,
            disableMetricTrendMPL: true,
            disableMetricTrendVOL: true,
            showVolumeGraph: false,
            costSpendVolumeData: [],
            costVolumeBarData: [],
            cpmLaneCpmNetworkData: [],
            onTimeGraphData: [],
            carouselModel: 0,
            isLaneData: false,
            selectedLanes: {},
            dropdownSBList: [],
            hasIncompleteScore: this.has_incomplete_score,
            load: true,
            queryInProgress: false,
            isCustomerDirectEnabled: stateAPI.getStateProperty(
                this,
                'storeIsCustomerDirectEnabled'
            ),
            focusLanes: [],
            favoriteLanes: []
        };
    }
};
</script>
<style scoped>
.score-icon {
    background-color: rgba(255, 255, 255, 0.3) !important;
    color: white !important;
    padding: 10px !important;
    border-radius: 17px !important;
}
</style>
