<template>
    <v-tooltip top open-delay="1000" :disabled="true">
        <template v-slot:activator="{ on, attrs }">
            <div
                id="draw-gauge"
                style="width: 100%; height: 16em"
                v-bind="attrs"
                v-on="on"
            ></div>
        </template>
        <span>Score</span>
    </v-tooltip>
</template>

<script>
import * as Two from 'twojs-ts';
export default {
    name: 'Gauge',
    props: ['averageScore', 'gaugeCardWidth', 'browserZoom'],
    computed: {
        isDark() {
            return this.$vuetify.theme.dark;
        }
    },
    watch: {
        averageScore(val) {
            this.score_avg = parseFloat(val);
            document.getElementById('draw-gauge').innerHTML = '';
            this.displayGauge();
        },

        gaugeCardWidth(val) {
            this.dialWidth = val;
            document.getElementById('draw-gauge').innerHTML = '';
            this.displayGauge();
        },

        browserZoom(val) {
            this.zoom = val;
            document.getElementById('draw-gauge').innerHTML = '';
            this.displayGauge();
        },

        isDark() {
            document.getElementById('draw-gauge').innerHTML = '';
            this.displayGauge();
        }
    },

    data: function () {
        return {
            score_avg: '--',
            dialWidth: 0,
            zoom: 0
        };
    },

    components: {},

    methods: {
        getScoreText(score) {
            if (
                score == null ||
                isNaN(score) ||
                score == 'N/A' ||
                score == '--'
            ) {
                return 'Score not available';
            }

            var ranges = [
                { start: 0, end: 69, text: 'Your score is bad' },
                { start: 70, end: 79, text: 'Your score is fair' },
                { start: 80, end: 89, text: 'Your score is good' },
                { start: 90, end: 100, text: 'Your score is excellent' }
            ];

            var tempRangeText;
            ranges.forEach((range) => {
                if (score >= range.start && score <= range.end) {
                    tempRangeText = range.text;
                }
            });

            return tempRangeText;
        },

        displayGauge() {
            // Gonna do this the old-fashioned way.
            var elem = document.getElementById('draw-gauge');
            var clientWidth = elem.clientWidth;
            var clientHeight = elem.clientHeight;
            var params = {
                width: clientWidth,
                height: clientHeight
            };
            var two = new Two(params).appendTo(elem);
            var score = parseFloat(this.score_avg).toFixed(0);
            var score_text = this.getScoreText(score);
            const scoreAdjustment = 55;

            var zoomlevel = this.zoom / 100;
            if (zoomlevel == 0 || zoomlevel > 1) {
                zoomlevel = 1;
            }

            var radius;
            if (0.85 * params.width <= params.height) {
                radius = 0.44 * params.width * zoomlevel;
            } else {
                radius = 0.54 * params.height * zoomlevel;
            }

            function drawArc(start, end, color) {
                var points = [];
                for (var degrees = start; degrees < end; degrees++) {
                    var theta = (degrees * Math.PI) / 180;
                    var x = radius * Math.cos(theta);
                    var y = radius * Math.sin(theta);
                    points.push(new Two.Anchor(-x, -y));
                }
                var path = two.makeCurve(points, true);

                path.noFill().linewidth = parseInt(radius * (10 / 130));
                path.cap = 'round';
                path.stroke = color;
            }

            var ranges = [
                {
                    start: 0,
                    end: 69,
                    color: this.isDark ? 'rgba(242, 55, 86, 1)' : '#F23756'
                },
                {
                    start: 70,
                    end: 79,
                    color: this.isDark ? 'rgba(255, 171, 108, 0.8)' : '#FFAB6C'
                },
                {
                    start: 80,
                    end: 89,
                    color: this.isDark ? 'rgba(26, 171, 255, 0.75)' : '#1AABFF'
                },
                {
                    start: 90,
                    end: 100,
                    color: this.isDark ? 'rgba(42, 192, 142, 0.75)' : '#2AC08E'
                }
            ];

            var startOffset = -35;
            var endAngle = 180 - startOffset;
            var total = endAngle - startOffset;
            var cursorColor;
            ranges.forEach((item) => {
                var start = item['start'];
                if (start == 70 || start == 80 || start == 90) {
                    start += 2;
                }

                var end = item['end'];
                if (end == 69 || end == 79 || end == 89) {
                    end += 2;
                }

                var startPct =
                    start == 0
                        ? start / (100 - scoreAdjustment)
                        : (start - scoreAdjustment) / (100 - scoreAdjustment);
                var endPct = (end - scoreAdjustment) / (100 - scoreAdjustment);
                drawArc(
                    startPct * total + startOffset,
                    item.end == 100 - scoreAdjustment || endPct == 1
                        ? endPct * total + startOffset
                        : endPct * total + startOffset - 3,
                    item['color']
                );
            });

            ranges.forEach(function (item) {
                if (isNaN(score) || parseInt(score) == -1) {
                    score = 'N/A';
                }
                if (score <= item['end'] && score >= item['start']) {
                    cursorColor = item['color'];
                }
            });

            var scoreText;
            var scoreDescText;

            if (score >= 0) {
                scoreText = two.makeText(
                    score.toString(),
                    0,
                    radius * (-25 / 130),
                    {
                        family: 'Roboto, sans-serif',
                        size: radius * (60 / 130),
                        leading: 50,
                        weight: 300
                    }
                );
                scoreText.fill = this.isDark ? '#D9D9D9' : 'black';

                scoreDescText = two.makeText(
                    score_text,
                    0,
                    radius * (30 / 130),
                    {
                        family: 'Roboto, sans-serif',
                        size: radius * (20 / 130),
                        leading: 50,
                        weight: 200
                    }
                );

                var cursor = two.makeCircle(0, 0, radius * (8 / 130));
                cursor.fill = this.isDark ? '#E1E1E1' : 'white';
                cursor.stroke = cursorColor;
                cursor.linewidth = parseInt(radius * (6 / 130));
                cursor.cap = cursor.join = 'round';

                var adjustedScore =
                    score < scoreAdjustment ? scoreAdjustment : score;
                if (adjustedScore > scoreAdjustment && adjustedScore < 100) {
                    adjustedScore *= 1.03;
                }
                var cursorDegrees =
                    ((adjustedScore - scoreAdjustment) /
                        (100 - scoreAdjustment)) *
                        total +
                    startOffset;
                const startScore = ranges.flatMap((range) => range.start);
                if (
                    !startScore.includes(adjustedScore) &&
                    adjustedScore != scoreAdjustment &&
                    adjustedScore != 100
                ) {
                    cursorDegrees -= 3;
                }
                var cursorRadians = (cursorDegrees * Math.PI) / 180;
                var cursorX = radius * Math.cos(cursorRadians);
                var cursorY = radius * Math.sin(cursorRadians);
                cursor.translation.set(-cursorX, -cursorY);
            } else {
                scoreDescText = two.makeText(
                    score_text,
                    0,
                    radius * (-10 / 130),
                    {
                        family: 'Roboto, sans-serif',
                        size: radius * (20 / 130),
                        leading: 50,
                        weight: 300
                    }
                );
            }

            scoreDescText.fill = this.isDark ? '#E1E1E1' : 'black';

            two.scene.translation.set(two.width / 2, two.height / 1.65);

            two.update();
        }
    }
};
</script>

<style>
.gauge-positioning {
    margin-top: -50px !important;
    margin-bottom: 15px !important;
}
</style>
