var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{style:({
        background: _vm.$vuetify.theme.themes[_vm.theme].exportBackgroundColor
    }),attrs:{"fluid":"","dense":""}},[_c('v-form',{ref:"exportForm",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v(" Export Shipments ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-autocomplete',{staticClass:"pl-2",attrs:{"items":_vm.shipperBrokerList,"item-text":"name","item-value":"id","label":_vm.getLabel(),"required":""},model:{value:(_vm.sbValue),callback:function ($$v) {_vm.sbValue=$$v},expression:"sbValue"}})],1),_c('v-col',{attrs:{"cols":"6"}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Start Date*","prepend-icon":"mdi-calendar","readonly":"","required":"","rules":[
                                        function (v) { return !!v || 'Start date is required'; }
                                    ]},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startMenu),callback:function ($$v) {_vm.startMenu=$$v},expression:"startMenu"}},[_c('v-date-picker',{attrs:{"max":_vm.maxDate},on:{"input":function($event){_vm.startMenu = false}},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
                                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"disabled":_vm.startDate == '',"label":"End Date*","prepend-icon":"mdi-calendar","readonly":"","required":"","rules":[
                                        function (v) { return !!v || 'End date is required'; },
                                        _vm.validateDates
                                    ]},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.endMenu),callback:function ($$v) {_vm.endMenu=$$v},expression:"endMenu"}},[_c('v-date-picker',{attrs:{"min":_vm.startDate,"max":_vm.maxDate},on:{"input":function($event){_vm.endMenu = false}},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticStyle:{"float":"right","color":"white"},attrs:{"disabled":!_vm.valid,"depressed":"","color":_vm.$vuetify.theme.themes[_vm.theme].filterBtnBackground},on:{"click":_vm.exportShipments}},[_vm._v(" Export ")])],1)],1)],1),_c('SplashScreen',{staticStyle:{"z-index":"220"},attrs:{"is_loading":_vm.isLoading}}),_c('v-snackbar',{attrs:{"color":_vm.snackColor,"top":"","timeout":"3000"},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }