var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{style:({ background: _vm.$vuetify.theme.themes[_vm.theme].background })},[_c('NavBar',{attrs:{"prop_username":_vm.usn}}),_c('SideBar'),_c('v-main',{staticClass:"main-container"},[_c('v-container',{staticStyle:{"width":"75%"},attrs:{"fluid":""}},[_c('v-snackbar',{attrs:{"timeout":_vm.snackBarTimeout,"centered":true},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"blue","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"height":"35rem","loading":_vm.isLoading}},[_c('v-card-actions',[_c('v-row',[_c('v-col',{staticStyle:{"height":"35rem"},attrs:{"cols":"2"}},[_c('v-navigation-drawer',{attrs:{"permanent":""}},[_c('v-list',[_c('v-list-item',[_c('v-list-item-avatar',[_c('v-avatar',{attrs:{"color":"primary"}},[_c('span',{staticClass:"white--text text-h6"},[_vm._v(" "+_vm._s(_vm.shortName)+" ")])])],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.firstName + ' ' + _vm.lastName)+" ")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.email)+" ")])],1)],1)],1),_c('v-divider'),_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-item-group',{attrs:{"color":"primary","mandatory":""},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},_vm._l((_vm.items),function(item,i){return _c('v-list-item',{key:i},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(
                                                                item.icon
                                                            )}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(
                                                                item.text
                                                            )}})],1)],1)}),1)],1)],1)],1),_c('v-col',{staticStyle:{"height":"35rem"},attrs:{"cols":"10"}},[(_vm.selectedItem == 0)?[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"text-h5 pt-6"},[_vm._v("Profile")]),_c('v-card-text',[_c('v-row',{staticClass:"pt-8"},[_c('v-col',{staticClass:"pl-8",attrs:{"cols":"3","align-self":"center"}},[_c('v-subheader',[_vm._v("First Name: ")])],1),_c('v-col',{attrs:{"cols":"7"}},[_c('v-text-field',{attrs:{"value":_vm.firstName,"disabled":""}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pl-8",attrs:{"cols":"3","align-self":"center"}},[_c('v-subheader',[_vm._v("Last Name: ")])],1),_c('v-col',{attrs:{"cols":"7"}},[_c('v-text-field',{attrs:{"value":_vm.lastName,"disabled":""},on:{"update:value":function($event){_vm.lastName=$event}}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pl-8",attrs:{"cols":"3","align-self":"center"}},[_c('v-subheader',[_vm._v("Email: ")])],1),_c('v-col',{attrs:{"cols":"7"}},[_c('v-text-field',{attrs:{"value":_vm.email,"disabled":""}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pl-8",attrs:{"cols":"3","align-self":"center"}},[_c('v-subheader',[_vm._v("Phone Number: ")])],1),_c('v-col',{attrs:{"cols":"7"}},[_c('v-text-field',{attrs:{"value":_vm.phoneNumber,"disabled":""}})],1)],1)],1)],1)]:_vm._e(),(_vm.selectedItem == 1)?[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"text-h5 pt-6"},[_vm._v(" Settings "),_c('v-spacer'),_c('v-switch',{attrs:{"inset":"","label":((_vm.formFields.darkMode
                                                            ? 'Disable'
                                                            : 'Enable') + " Dark Mode")},on:{"change":function($event){return _vm.toggleDarkMode()}},model:{value:(
                                                        _vm.formFields.darkMode
                                                    ),callback:function ($$v) {_vm.$set(_vm.formFields, "darkMode", $$v)},expression:"\n                                                        formFields.darkMode\n                                                    "}})],1),_c('v-card-text',[_c('v-row',{staticClass:"pt-4"},[_c('v-col',{staticClass:"pl-8 text-right",attrs:{"cols":"3","align-self":"center"}},[_c('v-subheader',{staticClass:"px-0"},[_vm._v("Aggregate Start Date: ")])],1),_c('v-col',{attrs:{"cols":"7"}},[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                                var on = ref.on;
                                                                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Select Date","prepend-icon":"mdi-calendar","readonly":""},model:{value:(
                                                                        _vm.formFields.aggregationStartDate
                                                                    ),callback:function ($$v) {_vm.$set(_vm.formFields, "aggregationStartDate", $$v)},expression:"\n                                                                        formFields.aggregationStartDate\n                                                                    "}},'v-text-field',
                                                                        attrs
                                                                    ,false),
                                                                        on
                                                                    ))]}}],null,false,1528819877),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","allowed-dates":_vm.allowedDates,"reactive":""},model:{value:(
                                                                    _vm.formFields.aggregationStartDate
                                                                ),callback:function ($$v) {_vm.$set(_vm.formFields, "aggregationStartDate", $$v)},expression:"\n                                                                    formFields.aggregationStartDate\n                                                                "}})],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pl-8 text-right",attrs:{"cols":"3","align-self":"center"}},[_c('v-subheader',{staticClass:"px-0"},[_vm._v("Projection End Date: ")])],1),_c('v-col',{attrs:{"cols":"7"}},[_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                                var on = ref.on;
                                                                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Select Date","prepend-icon":"mdi-calendar","readonly":""},model:{value:(
                                                                        _vm.formFields.defaultProjectionDate
                                                                    ),callback:function ($$v) {_vm.$set(_vm.formFields, "defaultProjectionDate", $$v)},expression:"\n                                                                        formFields.defaultProjectionDate\n                                                                    "}},'v-text-field',
                                                                        attrs
                                                                    ,false),
                                                                        on
                                                                    ))]}}],null,false,1288818175),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","allowed-dates":_vm.futureOnly,"reactive":""},model:{value:(
                                                                    _vm.formFields.defaultProjectionDate
                                                                ),callback:function ($$v) {_vm.$set(_vm.formFields, "defaultProjectionDate", $$v)},expression:"\n                                                                    formFields.defaultProjectionDate\n                                                                "}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}}),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"color":"green","text":""},on:{"click":_vm.saveChanges}},[_vm._v(" Save ")])],1),_c('v-col',{attrs:{"cols":"1"}})],1)],1)],1)]:_vm._e(),(_vm.selectedItem == 2)?[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"text-h5 pt-6"},[_vm._v("Notification Settings")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-text',[_c('v-simple-table',{staticClass:"notificationDataTable",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((_vm.switches),function(item){return _c('tr',{key:item.name},[_c('td',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.description)+" ")]),_c('td',[_c('v-switch',{attrs:{"color":"green","inset":"","label":"Subscribed","value":item.subscribed,"disabled":_vm.isLoading},on:{"change":function($event){return _vm.modifySubscriptionStatus(
                                                                                                item.value,
                                                                                                item.subscribed
                                                                                            )}},model:{value:(
                                                                                            item.subscribed
                                                                                        ),callback:function ($$v) {_vm.$set(item, "subscribed", $$v)},expression:"\n                                                                                            item.subscribed\n                                                                                        "}})],1)])}),0)]},proxy:true}],null,false,2845588484)})],1)],1)],1)],1)],1)],1)]:_vm._e()],2)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }