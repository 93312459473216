<template>
    <v-app :style="{ background: $vuetify.theme.themes[theme].background }">
        <NavBar :prop_username="usn" />
        <SideBar />
        <v-main class="main-container">
            <v-container fluid style="width: 70%">
                <v-row>
                    <v-col cols="12">
                        <div
                            class="text-h4 font-weight-light text-left pt-14"
                            style="color: grey"
                        >
                            Notifications
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <template v-if="!this.notificationsFound">
                            <div class="text-center" style="color: grey">
                                No new notifications!
                            </div>
                        </template>
                        <template v-else>
                            <v-expansion-panels>
                                <template
                                    v-for="(item, index) in notifications"
                                >
                                    <v-subheader
                                        v-if="item.groupDate"
                                        :key="index"
                                        class="pl-0 pr-0"
                                    >
                                        <v-divider class="mr-4"></v-divider>
                                        {{ item.groupDate }}
                                        <v-divider class="ml-4"></v-divider>
                                    </v-subheader>
                                    <v-expansion-panel
                                        v-else
                                        :key="item.notificationId"
                                        class="mb-3"
                                        @click="updateStatus(item)"
                                    >
                                        <v-expansion-panel-header>
                                            <v-row align="center">
                                                <v-col cols="1">
                                                    <v-icon
                                                        large
                                                        :color="
                                                            getMessageType(
                                                                item.notificationType
                                                            ).color
                                                        "
                                                    >
                                                        {{
                                                            getMessageType(
                                                                item.notificationType
                                                            ).icon
                                                        }}
                                                    </v-icon>
                                                </v-col>

                                                <v-col cols="2">
                                                    <v-list-item-content>
                                                        <v-list-item-title
                                                            v-text="
                                                                item.subject
                                                            "
                                                        ></v-list-item-title>

                                                        <v-list-item-subtitle
                                                            class="text--primary"
                                                        >
                                                            {{
                                                                getMessageType(
                                                                    item.notificationType
                                                                ).text
                                                            }}
                                                            from
                                                            <i>{{
                                                                item.sender
                                                            }}</i>
                                                        </v-list-item-subtitle>
                                                        <v-list-item-subtitle
                                                            class="grey--text"
                                                        >
                                                            Created at
                                                            {{
                                                                new Date(
                                                                    item.notificationCreationDate
                                                                ).toLocaleTimeString(
                                                                    [],
                                                                    {
                                                                        hour: '2-digit',
                                                                        minute: '2-digit'
                                                                    }
                                                                )
                                                            }}
                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-col>

                                                <v-col
                                                    cols="8"
                                                    class="wrap-text"
                                                >
                                                    <v-list-item-content
                                                        class="text-truncate"
                                                    >
                                                        <v-list-item-subtitle
                                                            class="grey--text"
                                                        >
                                                            {{
                                                                item.message.substring(
                                                                    0,
                                                                    120
                                                                )
                                                            }}
                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-col>
                                                <v-col
                                                    col="1"
                                                    class="text-center"
                                                >
                                                    <v-icon
                                                        color="#FFD200"
                                                        large
                                                        v-if="
                                                            item.status == 'NEW'
                                                        "
                                                    >
                                                        mdi-exclamation-thick
                                                    </v-icon>
                                                </v-col>
                                            </v-row>
                                        </v-expansion-panel-header>

                                        <v-expansion-panel-content>
                                            <v-card-text
                                                v-text="item.message"
                                            ></v-card-text>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </template>
                            </v-expansion-panels>
                        </template>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </v-app>
</template>
<script>
import NavBar from '../components/NavBar';
import SideBar from '../components/SideBar';
import * as stateAPI from '../stateAPI';
import * as user_analytics from '../analytics/sendAnalyticsEvent';
import * as format from '../formatShipmentData';
import * as utils from '../utils';
import * as fetchNotifications from '../fetchNotifications';
export default {
    name: 'Notifications',

    components: { NavBar, SideBar },

    props: ['prop_usn'],

    computed: {
        theme() {
            return this.$vuetify.theme.dark ? 'dark' : 'light';
        }
    },

    methods: {
        getMessageType(type) {
            if (type != undefined || type != null) {
                return this.messageType[type.toLowerCase()];
            } else {
                return this.messageType['message'];
            }
        },

        getNotifications(query_params) {
            fetchNotifications.getNotifications(query_params).then((result) => {
                this.notificationsFound = true;

                // if there are no notifications then set to empty array
                if (result.length == 0) {
                    this.notifications = [];
                    this.notificationsFound = false;
                    return;
                }

                var notificationDict = {};
                result.forEach((notification) => {
                    const creationDate =
                        notification.notificationCreationDate.substring(0, 10);

                    if (!notificationDict[creationDate]) {
                        notificationDict[creationDate] = [notification];
                    } else {
                        notificationDict[creationDate].push(notification);
                    }

                    this.notifications = new Array();

                    for (const [key, value] of Object.entries(
                        notificationDict
                    )) {
                        const dateParts = key.split('-');
                        const dateKey = new Date(
                            dateParts[0],
                            parseInt(dateParts[1]) - 1,
                            dateParts[2]
                        ).toDateString();
                        this.notifications.push({
                            groupDate: dateKey
                        });
                        this.notifications = this.notifications.concat(value);
                    }
                });
            });
        },

        updateStatus(notification) {
            if (notification.status == 'NEW') {
                const query_params = {
                    notification_ids: [notification.notificationId]
                };
                fetchNotifications
                    .updateNotification(query_params)
                    .then((result) => {
                        notification.status = 'ACCESSED';
                    });
            }
        }
    },

    beforeMount() {
        const query_params = {
            email: this.email,
            start_date: this.startDate,
            end_date: this.endDate,
            new: this.new
        };

        this.getNotifications(query_params);
        stateAPI.setStateProperty(this, 'crumbs', this.crumbs);

        // Send pageView analytics event
        const email = stateAPI.getStateProperty(this, 'email');
        const company = stateAPI.getStateProperty(this, 'company');
        const role = stateAPI.getStateProperty(this, 'role');
        user_analytics.sendEvent(email, company, role, 'pageView', {
            pageSource: 'Notifications'
        });
    },

    data: function () {
        return {
            usn: stateAPI.getStateProperty(this, 'username'),
            email: stateAPI.getStateProperty(this, 'email'),
            crumbs: [
                {
                    text: 'Notifications',
                    disabled: true,
                    href: new URL(window.location.href).pathname
                }
            ],
            startDate: new Date(
                new Date(stateAPI.getStateProperty(this, 'startDate')) -
                    new Date().getTimezoneOffset() * 60000
            )
                .toISOString()
                .substring(0, 10),
            endDate: new Date().toISOString().split('T')[0],
            new: 0,
            messageType: {
                message: {
                    icon: 'mdi-message',
                    color: '#0091FF',
                    text: 'Message'
                },
                alert: {
                    icon: 'mdi-alert-decagram',
                    color: '#FF5059',
                    text: 'Alert'
                },
                insight: { icon: 'mdi-cog', color: '#7E57C2', text: 'Insight' }
            },
            notifications: [],
            notificationsFound: true
        };
    }
};
</script>
