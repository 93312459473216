var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1 dataTable",attrs:{"headers":!_vm.showSelect ? _vm.headers : _vm.truncated_headers,"items":_vm.displayedData,"expanded":_vm.expanded,"item-key":!_vm.isLaneData ? (_vm.isBrokerUser ? 'shipperId' : 'brokerId') : 'laneId',"show-expand":!_vm.isShipperDashboard,"single-expand":"","show-select":_vm.showSelect,"footer-props":{
        'items-per-page-options': [10, 25, 50, 100]
    },"items-per-page":100,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"server-items-length":_vm.serverItemsLength,"item-class":_vm.itemRowWidth,"loading":_vm.isTableLoading},on:{"update:expanded":function($event){_vm.expanded=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"click:row":_vm.rowClick,"update:options":_vm.emitUpdatedOptions,"update:serverItemsLength":function($event){_vm.serverItemsLength=$event},"update:server-items-length":function($event){_vm.serverItemsLength=$event},"item-expanded":_vm.updatePageNumber,"item-selected":_vm.emitSelectedOptions,"toggle-select-all":function($event){return _vm.emitSelectedOptions($event)}},scopedSlots:_vm._u([{key:"header.name",fn:function(ref){
    var header = ref.header;
return [_c('Tooltip',{attrs:{"header_text":header.text,"header_tool":header.tooltip}})]}},{key:"header.origin",fn:function(ref){
    var header = ref.header;
return [_c('Tooltip',{attrs:{"header_text":header.text,"header_tool":header.tooltip}})]}},{key:"header.destination",fn:function(ref){
    var header = ref.header;
return [_c('Tooltip',{attrs:{"header_text":header.text,"header_tool":header.tooltip}})]}},{key:"header.score",fn:function(ref){
    var header = ref.header;
return [_c('Tooltip',{attrs:{"header_text":header.text,"header_tool":header.tooltip}})]}},{key:"header.equipmenttype",fn:function(ref){
    var header = ref.header;
return [_c('Tooltip',{attrs:{"header_text":header.text,"header_tool":header.tooltip}})]}},{key:"header.total_margin",fn:function(ref){
    var header = ref.header;
return [_c('Tooltip',{attrs:{"header_text":header.text,"header_tool":header.tooltip}})]}},{key:"header.avg_margin",fn:function(ref){
    var header = ref.header;
return [_c('v-tooltip',{attrs:{"top":"","max-width":"25%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(header.tooltip))])])]}},{key:"header.avg_margin_dollars",fn:function(ref){
    var header = ref.header;
return [_c('v-tooltip',{attrs:{"top":"","max-width":"25%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(header.tooltip))])])]}},{key:"header.avg_clt",fn:function(ref){
    var header = ref.header;
return [_c('Tooltip',{attrs:{"header_text":header.text,"header_tool":header.tooltip}})]}},{key:"header.avg_blt",fn:function(ref){
    var header = ref.header;
return [_c('Tooltip',{attrs:{"header_text":header.text,"header_tool":header.tooltip}})]}},{key:"header.avg_prebook",fn:function(ref){
    var header = ref.header;
return [_c('Tooltip',{attrs:{"header_text":header.text,"header_tool":header.tooltip}})]}},{key:"header.otp",fn:function(ref){
    var header = ref.header;
return [_c('Tooltip',{attrs:{"header_text":header.text,"header_tool":header.tooltip}})]}},{key:"header.avg_otd",fn:function(ref){
    var header = ref.header;
return [_c('Tooltip',{attrs:{"header_text":header.text,"header_tool":header.tooltip}})]}},{key:"header.volume",fn:function(ref){
    var header = ref.header;
return [_c('Tooltip',{attrs:{"header_text":header.text,"header_tool":header.tooltip}})]}},{key:"header.avg_cogs",fn:function(ref){
    var header = ref.header;
return [_c('Tooltip',{attrs:{"header_text":header.text,"header_tool":header.tooltip}})]}},{key:"header.avg_revenue",fn:function(ref){
    var header = ref.header;
return [_c('Tooltip',{attrs:{"header_text":header.text,"header_tool":header.tooltip}})]}},{key:"header.avg_miles",fn:function(ref){
    var header = ref.header;
return [_c('Tooltip',{attrs:{"header_text":header.text,"header_tool":header.tooltip}})]}},(!this.isShipperDashboard && !this.showSelect)?{key:"item.gotoDrilldownIcon",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","max-width":"25%","open-delay":"1250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [(_vm.isLaneData)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.updateFavoriteLanes(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.isFavoriteLane(item) ? 'orange' : 'grey',"small":""}},[_vm._v(" "+_vm._s(_vm.isFavoriteLane(item) ? 'mdi-star' : 'mdi-star-outline')+" ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Favorite Lane")])]),_c('v-tooltip',{attrs:{"top":"","max-width":"25%","open-delay":"1250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-icon',{staticClass:"gotoIcon",staticStyle:{"font-size":"18px !important"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.gotoItemDrilldown(item)}}},[_vm._v(" mdi-forwardburger ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getGotoDrilldownTooltip(item)))])])]}}:null,(_vm.isShipperDashboard)?{key:"item.max_ingestion_time",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","max-width":"25%","open-on-hover":false,"open-on-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}},on),[_c('v-icon',{staticStyle:{"font-size":"18px !important"},attrs:{"color":_vm.getDataStalenessColor(item)}},[_vm._v(" mdi-database-refresh-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getDataStalenessTooltip(item)))])])]}}:null,{key:"item.score",fn:function(ref){
    var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":220,"offset-x":"","offset-y":"","transition":"scroll-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [(
                        _vm.showBadgeTooltip(
                            item.avg_carrier_score,
                            item.avg_broker_score,
                            item.avg_load_creation_time
                        )
                    )?_c('v-chip',_vm._g({attrs:{"color":_vm.getColor(item.score),"dark":""}},on),[_c('v-tooltip',{attrs:{"top":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm.formatScore(item.score)))])]}}],null,true)},[_c('span',[_vm._v("Incomplete Score")])])],1):_c('v-chip',_vm._g({attrs:{"color":_vm.getColor(item.score),"dark":""}},on),[_vm._v(" "+_vm._s(_vm.formatScore(item.score))+" ")])]}}],null,true)},[_c('v-card',{staticClass:"pa-1"},[_c('v-card-title',{staticClass:"pb-0"},[_vm._v(" Score Breakdown ")]),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"ma-0 py-2"},[_vm._v(" On-Time Pickup "+_vm._s(_vm.formatScore(item.avg_otp_score) == '--' ? '--' : '')+" "),(
                                        _vm.formatScore(item.avg_otp_score) !=
                                        '--'
                                    )?_c('v-progress-linear',{attrs:{"value":_vm.formatScore(item.avg_otp_score),"color":_vm.getColor(
                                            _vm.formatScore(item.avg_otp_score)
                                        )}}):_vm._e()],1),_c('v-row',{staticClass:"ma-0 py-2"},[_vm._v(" On-Time Delivery "+_vm._s(_vm.formatScore(item.avg_otd_score) == '--' ? '--' : '')+" "),(
                                        _vm.formatScore(item.avg_otd_score) !=
                                        '--'
                                    )?_c('v-progress-linear',{attrs:{"value":_vm.formatScore(item.avg_otd_score),"color":_vm.getColor(
                                            _vm.formatScore(item.avg_otd_score)
                                        )}}):_vm._e()],1),_c('v-row',{staticClass:"ma-0 py-2"},[_vm._v(" Appointment Setting "+_vm._s(_vm.formatScore(item.avg_ltu_score) == '--' ? '--' : '')+" "),(
                                        _vm.formatScore(item.avg_ltu_score) !=
                                        '--'
                                    )?_c('v-progress-linear',{attrs:{"value":_vm.formatScore(item.avg_ltu_score),"color":_vm.getColor(
                                            _vm.formatScore(item.avg_ltu_score)
                                        )}}):_vm._e()],1),_c('v-row',{staticClass:"ma-0 py-2"},[_vm._v(" Pre-Book "+_vm._s(_vm.formatScore(item.avg_prebook_score) == '--' ? '--' : '')+" "),(
                                        _vm.formatScore(
                                            item.avg_prebook_score
                                        ) != '--'
                                    )?_c('v-progress-linear',{attrs:{"value":_vm.formatScore(item.avg_prebook_score),"color":_vm.getColor(
                                            _vm.formatScore(
                                                item.avg_prebook_score
                                            )
                                        )}}):_vm._e()],1),_c('v-row',{staticClass:"ma-0 py-2"},[_vm._v(" Cost Performance "),_c('InfoTooltip',{attrs:{"tooltip_text":_vm.costPerformanceTooltip}}),_c('v-progress-linear',{attrs:{"value":_vm.formatScore(item.avg_cost_score),"color":_vm.getColor(
                                            _vm.formatScore(item.avg_cost_score)
                                        )}})],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"justify-center",attrs:{"cols":"6"}},[_c('v-col',[_c('v-row',{staticClass:"justify-center scorecardMetricValue"},[_vm._v(" "+_vm._s(_vm.formatHoursToBusinessDays( item.avg_clt, 1 ))+" ")]),_c('v-row',{staticClass:"justify-center font-weight-light"},[_vm._v(" "+_vm._s('Avg. CLT')+" ")])],1)],1),_c('v-col',{staticClass:"justify-center",attrs:{"cols":6}},[_c('v-col',[_c('v-row',{staticClass:"justify-center scorecardMetricValue"},[_vm._v(" "+_vm._s(_vm.formatHoursToBusinessDays( item.avg_blt, 1 ))+" ")]),_c('v-row',{staticClass:"justify-center font-weight-light"},[_vm._v(" "+_vm._s('Avg. BLT')+" ")])],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"justify-center",attrs:{"cols":"12"}},[_c('v-col',[_c('v-row',{staticClass:"justify-center scorecardMetricValue"},[_vm._v(" "+_vm._s(_vm.formatHoursToBusinessDays( item.avg_prebook, 1 ))+" ")]),_c('v-row',{staticClass:"justify-center font-weight-light"},[_vm._v(" "+_vm._s('Avg. Prebook')+" ")])],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"justify-center",attrs:{"cols":"6"}},[_c('v-col',[_c('v-row',{staticClass:"justify-center scorecardMetricValue"},[_vm._v(" "+_vm._s(_vm.formatPercent(item.avg_otp))+" ")]),_c('v-row',{staticClass:"justify-center font-weight-light"},[_vm._v(" "+_vm._s('OTP')+" ")])],1)],1),_c('v-col',{staticClass:"justify-center",attrs:{"cols":"6"}},[_c('v-col',[_c('v-row',{staticClass:"justify-center scorecardMetricValue"},[_vm._v(" "+_vm._s(_vm.formatPercent(item.avg_otd))+" ")]),_c('v-row',{staticClass:"justify-center font-weight-light"},[_vm._v(" "+_vm._s('OTD')+" ")])],1)],1)],1)],1)],1)],1)]}},{key:"item.customer_directs",fn:function(ref){
                                        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCustomerDirect(item.customer_directs))+" ")]}},{key:"item.equipmenttype",fn:function(ref){
                                        var item = ref.item;
return [_vm._v(" "+_vm._s(item.shipmentmode && item.shipmentmode == 'IMDL' ? 'IMDL - ' + item.equipmenttype : item.equipmenttype)+" ")]}},{key:"item.total_margin",fn:function(ref){
                                        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDollars(item.total_margin))+" ")]}},{key:"item.avg_margin",fn:function(ref){
                                        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPercent(item.avg_margin, 1))+" ")]}},{key:"item.avg_margin_dollars",fn:function(ref){
                                        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDollars(item.avg_margin_dollars))+" ")]}},{key:"item.avg_clt",fn:function(ref){
                                        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatHoursToBusinessDays(item.avg_clt, 1, false))+" ")]}},{key:"item.avg_blt",fn:function(ref){
                                        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatHoursToBusinessDays(item.avg_blt, 1, false))+" ")]}},{key:"item.avg_prebook",fn:function(ref){
                                        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatHoursToBusinessDays(item.avg_prebook, 1, false))+" ")]}},{key:"item.otp",fn:function(ref){
                                        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPercent(item.otp))+" ")]}},{key:"item.avg_otd",fn:function(ref){
                                        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPercent(item.avg_otd))+" ")]}},(_vm.isShipperDashboard && !_vm.isLaneData)?{key:"item.avg_cogs",fn:function(ref){
                                        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDollars(item.avg_cogs))+" ")]}}:{key:"item.avg_cogs",fn:function(ref){
                                        var item = ref.item;
return [(item.avg_ml_price != null)?_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":300,"offset-x":"","offset-y":"","transition":"scroll-x-transition","height":"100px;"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                        var on = ref.on;
return [_c('v-chip',_vm._g({staticClass:"truckCostShip pa-0 text-center",staticStyle:{"font-weight":"450"},attrs:{"color":"transparent","text-color":_vm.getPercentDifferenceColor(
                            item.avg_ml_price,
                            item.avg_cogs_wo_acc
                        ),"dark":""}},on),[_vm._v(" "+_vm._s(_vm.formatDollars(item.avg_cogs))+" ")])]}}],null,true)},[_c('v-card',{staticClass:"pa-1"},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"justify-center",attrs:{"cols":"6"}},[_c('v-col',[_c('v-row',{staticClass:"justify-center scorecardMetricValue"},[_vm._v(" "+_vm._s(_vm.formatDollars(item.avg_cogs_wo_acc))+" ")]),_c('v-row',{staticClass:"justify-center font-weight-light mt-3"},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"25%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s('Truck Cost*'))])]}}],null,true)},[_c('span',[_vm._v(_vm._s('This truck cost removes accessorials and is the average truck cost of shipments that have a TruceAI truck cost to compare against'))])])],1)],1)],1),_c('v-col',{staticClass:"justify-center",attrs:{"cols":"6"}},[_c('v-col',[_c('v-row',{staticClass:"justify-center scorecardMetricValue"},[_vm._v(" "+_vm._s(_vm.formatDollars(item.avg_ml_price))+" ")]),_c('v-row',{staticClass:"justify-center font-weight-light mt-3"},[_vm._v(" TruceAI Truck Cost ")])],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"justify-center",attrs:{"cols":"5"}},[_c('v-col',[_c('v-row',{staticClass:"justify-center scorecardMetricValue"},[_c('span',{style:(("color: " + (_vm.getPercentDifferenceColor(
                                            item.avg_ml_price,
                                            item.avg_cogs_wo_acc
                                        ))))},[_vm._v(" "+_vm._s(_vm.getPercentDifference( item.avg_ml_price, item.avg_cogs_wo_acc ))+" ")])]),_c('v-row',{staticClass:"justify-center font-weight-light mt-3"},[_vm._v(" "+_vm._s('Difference')+" ")])],1)],1),_c('v-col',{staticClass:"justify-center",attrs:{"cols":"7"}},[_c('v-col',[_c('v-row',{staticClass:"justify-center scorecardMetricValue"},[_vm._v(" "+_vm._s(_vm.formatNumber( item.avg_cogs_wo_acc_count ))+" ")]),_c('v-row',{staticClass:"justify-center font-weight-light mt-3"},[_vm._v(" Benchmarked Shipments ")])],1)],1)],1)],1)],1)],1):[_c('v-chip',{staticClass:"truckCostShip pa-0 text-center",staticStyle:{"opacity":"1 !important"},attrs:{"color":"transparent","disabled":""}},[_vm._v(" "+_vm._s(_vm.formatDollars(item.avg_cogs))+" ")])]]}},{key:"item.avg_revenue",fn:function(ref){
                                        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDollars(item.avg_revenue))+" ")]}},{key:"item.avg_miles",fn:function(ref){
                                        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDecimal(item.avg_miles, 0))+" ")]}},{key:"item.volume",fn:function(ref){
                                        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.volume))+" ")]}},(!_vm.isShipperDashboard)?{key:"expanded-item",fn:function(ref){
                                        var headers = ref.headers;
                                        var item = ref.item;
return [_c('td',{staticClass:"expandedTableCell",attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"expandedDataTableForShipments",staticStyle:{"border-radius":"0px"},attrs:{"headers":_vm.headersShipment,"items":item.shipments,"sort-by":_vm.isShipmentView ? 'originCloseTime' : 'week',"sort-desc":_vm.sortDescShipment,"page":_vm.page,"items-per-page":8,"footer-props":{
                    'items-per-page-options': [8, 15],
                    'disable-items-per-page': true
                },"server-items-length":item.shipment_row_count[0].count,"loading":_vm.isDropdownLoading,"loading-text":"Loading Data ..."},on:{"update:sortDesc":function($event){_vm.sortDescShipment=$event},"update:sort-desc":function($event){_vm.sortDescShipment=$event},"update:page":function($event){_vm.page=$event},"update:options":_vm.emitUpdatedOptionsShipments,"update:serverItemsLength":function($event){return _vm.$set(item.shipment_row_count[0], "count", $event)},"update:server-items-length":function($event){return _vm.$set(item.shipment_row_count[0], "count", $event)}},scopedSlots:_vm._u([{key:"item.week",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatAggregationWeek(item.week))+" ")]}},{key:"item.originCloseTime",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.originCloseTime))+" ")]}},(_vm.formatScore(item.score) != '--')?{key:"item.score",fn:function(ref){
                var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":220,"offset-x":"","offset-y":"","transition":"scroll-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-chip',_vm._g({staticClass:"shipChip pa-0 text-center",attrs:{"outlined":"","color":_vm.getColor(item.score),"text-color":_vm.getShipmentScoreChipColor(),"dark":""}},on),[_vm._v(" "+_vm._s(_vm.formatScore(item.score))+" ")])]}}],null,true)},[_c('v-card',{staticClass:"pa-1"},[_c('v-card-title',{staticClass:"pb-0"},[_vm._v(" Score Breakdown ")]),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"ma-0 py-2"},[_vm._v(" On-Time Pickup "+_vm._s(_vm.formatScore( item.otp_score ) == '--' ? '--' : '')+" "),(
                                                    _vm.formatScore(
                                                        item.otp_score
                                                    ) != '--'
                                                )?_c('v-progress-linear',{attrs:{"value":_vm.formatScore(
                                                        item.otp_score
                                                    ),"color":_vm.getColor(
                                                        _vm.formatScore(
                                                            item.otp_score
                                                        )
                                                    )}}):_vm._e()],1),_c('v-row',{staticClass:"ma-0 py-2"},[_vm._v(" On-Time Delivery "+_vm._s(_vm.formatScore( item.otd_score ) == '--' ? '--' : '')+" "),(
                                                    _vm.formatScore(
                                                        item.otd_score
                                                    ) != '--'
                                                )?_c('v-progress-linear',{attrs:{"value":_vm.formatScore(
                                                        item.otd_score
                                                    ),"color":_vm.getColor(
                                                        _vm.formatScore(
                                                            item.otd_score
                                                        )
                                                    )}}):_vm._e()],1),_c('v-row',{staticClass:"ma-0 py-2"},[_vm._v(" Appointment Setting "+_vm._s(_vm.formatScore( item.ltu_score ) == '--' ? '--' : '')+" "),(
                                                    _vm.formatScore(
                                                        item.ltu_score
                                                    ) != '--'
                                                )?_c('v-progress-linear',{attrs:{"value":_vm.formatScore(
                                                        item.ltu_score
                                                    ),"color":_vm.getColor(
                                                        _vm.formatScore(
                                                            item.ltu_score
                                                        )
                                                    )}}):_vm._e()],1),_c('v-row',{staticClass:"ma-0 py-2"},[_vm._v(" Pre-Book "+_vm._s(_vm.formatScore( item.prebook_score ) == '--' ? '--' : '')+" "),(
                                                    _vm.formatScore(
                                                        item.prebook_score
                                                    ) != '--'
                                                )?_c('v-progress-linear',{attrs:{"value":_vm.formatScore(
                                                        item.prebook_score
                                                    ),"color":_vm.getColor(
                                                        _vm.formatScore(
                                                            item.prebook_score
                                                        )
                                                    )}}):_vm._e()],1),_c('v-row',{staticClass:"ma-0 py-2"},[_vm._v(" Cost Performance "),_c('InfoTooltip',{attrs:{"tooltip_text":_vm.costPerformanceTooltip}}),_c('v-progress-linear',{attrs:{"value":_vm.formatScore(
                                                        item.cost_score
                                                    ),"color":_vm.getColor(
                                                        _vm.formatScore(
                                                            item.cost_score
                                                        )
                                                    )}})],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"justify-center",attrs:{"cols":"6"}},[_c('v-col',[_c('v-row',{staticClass:"justify-center scorecardMetricValue"},[_vm._v(" "+_vm._s(_vm.formatHoursToBusinessDays( item.clt, 1 ))+" ")]),_c('v-row',{staticClass:"justify-center font-weight-light"},[_vm._v(" "+_vm._s('CLT')+" ")])],1)],1),_c('v-col',{staticClass:"justify-center",attrs:{"cols":6}},[_c('v-col',[_c('v-row',{staticClass:"justify-center scorecardMetricValue"},[_vm._v(" "+_vm._s(_vm.formatHoursToBusinessDays( item.blt, 1 ))+" ")]),_c('v-row',{staticClass:"justify-center font-weight-light"},[_vm._v(" "+_vm._s('BLT')+" ")])],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"justify-center",attrs:{"cols":"6"}},[_c('v-col',[_c('v-row',{staticClass:"justify-center scorecardMetricValue"},[_vm._v(" "+_vm._s(_vm.formatHoursToBusinessDays( item.prebook, 1 ))+" ")]),_c('v-row',{staticClass:"justify-center font-weight-light"},[_vm._v(" "+_vm._s('Prebook')+" ")])],1)],1),_c('v-col',{staticClass:"justify-center",attrs:{"cols":6}},[_c('v-col',[_c('v-row',{staticClass:"justify-center scorecardMetricValue"},[_vm._v(" "+_vm._s(_vm.formatOTD( item.destinationDelayMinutes ) == '--' ? _vm.formatOTD( item.destinationDelayMinutes ) : item.destinationDelayMinutes > 0 ? '0%' : '100%')+" ")]),_c('v-row',{staticClass:"justify-center font-weight-light"},[_vm._v(" "+_vm._s('OTD')+" ")])],1)],1)],1)],1)],1)],1)]}}:{key:"item.score",fn:function(ref){
                                                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatScore(item.score))+" ")]}},{key:"item.customer_direct",fn:function(ref){
                                                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCustomerDirect(item.customer_direct))+" ")]}},{key:"item.revenueTotal",fn:function(ref){
                                                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDollars(item.revenueTotal))+" ")]}},{key:"item.revenue",fn:function(ref){
                                                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDollars(item.revenue))+" ")]}},{key:"item.cogsTotal",fn:function(ref){
                                                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDollars(item.cogsTotal))+" ")]}},{key:"item.cogs",fn:function(ref){
                                                    var item = ref.item;
return [(item.ml_price != null)?_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":320,"offset-x":"","offset-y":"","transition":"scroll-x-transition","height":"100px;"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                    var on = ref.on;
return [_c('v-chip',_vm._g({staticClass:"truckCostShip pa-0 text-center",staticStyle:{"font-weight":"450"},attrs:{"color":"transparent","text-color":_vm.getPercentDifferenceColor(
                                        item.ml_price,
                                        item.cogs_wo_acc
                                    ),"dark":""}},on),[_vm._v(" "+_vm._s(_vm.formatDollars(item.cogs))+" ")])]}}],null,true)},[_c('v-card',{staticClass:"pa-1"},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"justify-center",attrs:{"cols":"3.5"}},[_c('v-col',[_c('v-row',{staticClass:"justify-center scorecardMetricValue"},[_vm._v(" "+_vm._s(_vm.formatDollars( item.cogs_wo_acc ))+" ")]),_c('v-row',{staticClass:"justify-center font-weight-light mt-3"},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"25%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                        var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s('Truck Cost*'))])]}}],null,true)},[_c('span',[_vm._v(_vm._s("Doesn't include accessorials"))])])],1)],1)],1),_c('v-col',{staticClass:"justify-center",attrs:{"cols":"5"}},[_c('v-col',[_c('v-row',{staticClass:"justify-center scorecardMetricValue"},[_vm._v(" "+_vm._s(_vm.formatDollars( item.ml_price ))+" ")]),_c('v-row',{staticClass:"justify-center font-weight-light mt-"},[_vm._v(" TruceAI Truck Cost ")])],1)],1),_c('v-col',{staticClass:"justify-center",attrs:{"cols":"3.5"}},[_c('v-col',[_c('v-row',{staticClass:"justify-center scorecardMetricValue"},[_c('span',{style:(("color: " + (_vm.getPercentDifferenceColor(
                                                        item.ml_price,
                                                        item.cogs_wo_acc
                                                    ))))},[_vm._v(" "+_vm._s(_vm.getPercentDifference( item.ml_price, item.cogs_wo_acc ))+" ")])]),_c('v-row',{staticClass:"justify-center font-weight-light mt-3"},[_vm._v(" "+_vm._s('Difference')+" ")])],1)],1)],1)],1)],1)],1):[_c('v-chip',{staticClass:"truckCostShip pa-0 text-center",staticStyle:{"opacity":"1 !important"},attrs:{"color":"transparent","disabled":""}},[_vm._v(" "+_vm._s(_vm.formatDollars(item.cogs))+" ")])]]}},{key:"item.margin",fn:function(ref){
                                                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPercent(item.margin, 1))+" ")]}},{key:"item.avg_margin",fn:function(ref){
                                                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPercent(item.avg_margin, 1))+" ")]}},{key:"item.margin_dollars",fn:function(ref){
                                                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDollars(item.margin_dollars))+" ")]}},{key:"item.avg_margin_dollars",fn:function(ref){
                                                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDollars(item.avg_margin_dollars))+" ")]}},{key:"item.clt",fn:function(ref){
                                                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatHoursToBusinessDays(item.clt, 1, false))+" ")]}},{key:"item.blt",fn:function(ref){
                                                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatHoursToBusinessDays(item.blt, 1, false))+" ")]}},{key:"item.prebook",fn:function(ref){
                                                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatHoursToBusinessDays(item.prebook, 1, false))+" ")]}},{key:"item.preBook",fn:function(ref){
                                                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatHoursToBusinessDays(item.preBook, 1, false))+" ")]}},{key:"item.distanceMiles",fn:function(ref){
                                                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDecimal(item.distanceMiles, 2))+" ")]}},{key:"item.miles",fn:function(ref){
                                                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDecimal(item.miles, 2))+" ")]}},{key:"item.avg_otd",fn:function(ref){
                                                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPercent(item.avg_otd))+" ")]}},{key:"item.volume",fn:function(ref){
                                                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.volume))+" ")]}},{key:"item.destinationDelayMinutes",fn:function(ref){
                                                    var item = ref.item;
return [(
                            _vm.formatOTD(item.destinationDelayMinutes) > 0 ||
                            (_vm.formatOTD(item.destinationDelayMinutes) ==
                                '--' &&
                                item.originDepartureTime == null) ||
                            (_vm.formatOTD(item.destinationDelayMinutes) ==
                                '--' &&
                                item.originDepartureTime != null &&
                                item.destinationDepartureTime != null)
                        )?[_vm._v(" "+_vm._s(_vm.formatOTD(item.destinationDelayMinutes))+" ")]:(
                            _vm.formatOTD(item.destinationDelayMinutes) ==
                                '--' && item.originDepartureTime != null
                        )?[_vm._v(" In Transit ")]:[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-clock-check-outline")])]]}}],null,true)})],1)]}}:null],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})}
var staticRenderFns = []

export { render, staticRenderFns }