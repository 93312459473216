import axios from 'axios';
import aws4 from 'aws4';
import { isProdEnv, isDevEnv } from './utils';

export async function fetchScorecardData(query_params) {
    var shipmentsArray;
    var request;

    const currentUrl = window.location.href;

    if (isProdEnv()) {
        query_params.is_dev_env = 0;
        request = {
            host: 'pc5zxjq713.execute-api.us-east-1.amazonaws.com',
            method: 'POST',
            url: 'https://pc5zxjq713.execute-api.us-east-1.amazonaws.com/dev/postShipment',
            data: {
                query_params: query_params
            }
        };
    } else if (isDevEnv()) {
        query_params.is_dev_env = 1;
        request = {
            host: 'pc5zxjq713.execute-api.us-east-1.amazonaws.com',
            method: 'POST',
            url: 'https://pc5zxjq713.execute-api.us-east-1.amazonaws.com/dev/postShipment',
            data: {
                query_params: query_params
            }
        };
    }

    let signedRequest = aws4.sign(request, {
        // assumes user has authenticated and we have called
        // AWS.config.credentials.get to retrieve keys and
        // session tokens
        secretAccessKey: '',
        accessKeyId: ''
    });

    delete signedRequest.headers['Host'];
    delete signedRequest.headers['Content-Length'];
    let response = axios(signedRequest).then((result) => {
        return result.data.body.records;
    });

    return response;
}
