<template>
    <v-container
        fluid
        dense
        :style="{
            background: $vuetify.theme.themes[theme].exportBackgroundColor
        }"
    >
        <v-form ref="exportForm" v-model="valid" lazy-validation>
            <v-card elevation="0">
                <v-card-title> Export Shipments </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="6">
                            <v-autocomplete
                                v-model="sbValue"
                                :items="shipperBrokerList"
                                item-text="name"
                                item-value="id"
                                :label="getLabel()"
                                required
                                class="pl-2"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="6"></v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-menu
                                v-model="startMenu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="startDate"
                                        label="Start Date*"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        required
                                        :rules="[
                                            (v) =>
                                                !!v || 'Start date is required'
                                        ]"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="startDate"
                                    @input="startMenu = false"
                                    :max="maxDate"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="6">
                            <v-menu
                                v-model="endMenu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        :disabled="startDate == ''"
                                        v-model="endDate"
                                        label="End Date*"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        required
                                        :rules="[
                                            (v) =>
                                                !!v || 'End date is required',
                                            validateDates
                                        ]"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="endDate"
                                    @input="endMenu = false"
                                    :min="startDate"
                                    :max="maxDate"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer> </v-spacer>
                    <v-btn
                        :disabled="!valid"
                        depressed
                        :color="
                            $vuetify.theme.themes[theme].filterBtnBackground
                        "
                        style="float: right; color: white"
                        @click="exportShipments"
                    >
                        Export
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
        <SplashScreen style="z-index: 220" :is_loading="isLoading" />
        <v-snackbar v-model="snackbar" :color="snackColor" top timeout="3000">
            {{ snackText }}
        </v-snackbar>
    </v-container>
</template>

<script>
import { fetchShipmentData } from '../fetchShipments';
import {
    formatDate,
    formatDecimal,
    formatHoursToBusinessDays,
    formatOTD,
    formatPercent,
    formatScore
} from '../formatShipmentData';
import * as stateAPI from '../stateAPI';
import SplashScreen from './SplashScreen';

export default {
    name: 'Export',

    components: { SplashScreen },

    computed: {
        theme() {
            return this.$vuetify.theme.dark ? 'dark' : 'light';
        }
    },

    beforeMount() {
        this.shipperBrokerList = [{ id: '1', name: 'All' }].concat(
            stateAPI.getStateProperty(
                this,
                this.userRole == 'broker' ? 'shipper_list' : 'broker_list'
            )
        );

        this.sbValue = '1';

        let currDate = new Date();
        const offset = currDate.getTimezoneOffset();
        currDate = new Date(currDate.getTime() - offset * 60 * 1000);
        this.maxDate = currDate.toISOString().split('T')[0];
    },

    methods: {
        exportShipments() {
            // Escape early if validation fails
            if (!this.$refs.exportForm.validate()) {
                return;
            }
            this.isLoading = true;
            const client_data_name = this.shipperBrokerList.filter((row) => {
                return row.id == this.sbValue;
            })[0].name;

            let query_params = {
                is_broker_user: this.userRole == 'broker' ? 1 : 0,
                is_drilldown: 0,
                include_accessorials: 1,
                is_export: 1,
                start_date: this.startDate,
                end_date: this.endDate
            };

            if (this.userRole == 'broker') {
                query_params.brokerId = this.userId;
                query_params.index_by = 'shipperId';
                if (this.sbValue != '1') {
                    query_params.shipperId = this.sbValue;
                    query_params.is_drilldown = 1;
                }
            } else {
                query_params.shipperId = this.userId;
                query_params.index_by = 'brokerId';
                if (this.sbValue != '1') {
                    query_params.brokerId = this.sbValue;
                    query_params.is_drilldown = 1;
                }
            }

            fetchShipmentData(query_params)
                .then((result) => {
                    const data = result.shipments;
                    var csvData = [];

                    csvData.push(this.headers);

                    data.forEach((row) => {
                        const formattedRow = [];
                        formattedRow.push(row.shipperName);
                        formattedRow.push(row.brokerName);
                        formattedRow.push(row.shipperPrimaryReference);
                        formattedRow.push(row.origin_city);
                        formattedRow.push(row.origin_state);
                        formattedRow.push(row.destination_city);
                        formattedRow.push(row.destination_state);
                        const miles = formatDecimal(row.miles, 1, true);
                        formattedRow.push(
                            miles == 0 || miles == 0.0 ? '' : miles
                        );
                        formattedRow.push(row.equipmentType);
                        formattedRow.push(formatDate(row.originCloseTime));
                        const score = formatScore(row.score, true);
                        formattedRow.push(score == 0 ? '' : score);
                        const revLhFuel = formatDecimal(
                            row.revenue_lh_fuel,
                            2,
                            true
                        );
                        formattedRow.push(
                            revLhFuel == 0 || revLhFuel == '0.00'
                                ? ''
                                : revLhFuel
                        );
                        const revAcc = formatDecimal(row.revenue_acc, 2, true);
                        formattedRow.push(
                            revAcc == 0 || revAcc == '0.00' ? '' : revAcc
                        );
                        const revTot = formatDecimal(
                            row.revenue_total,
                            2,
                            true
                        );
                        formattedRow.push(
                            revTot == 0 || revTot == '0.00' ? '' : revTot
                        );
                        const cogsLhFuel = formatDecimal(
                            row.cogs_lh_fuel,
                            2,
                            true
                        );
                        formattedRow.push(
                            cogsLhFuel == 0 || cogsLhFuel == '0.00'
                                ? ''
                                : cogsLhFuel
                        );
                        const cogsAcc = formatDecimal(row.cogs_acc, 2, true);
                        formattedRow.push(
                            cogsAcc == 0 || cogsAcc == '0.00' ? '' : cogsAcc
                        );
                        const cogsTot = formatDecimal(row.cogs_total, 2, true);
                        formattedRow.push(
                            cogsTot == 0 || cogsTot == '0.00' ? '' : cogsTot
                        );
                        const aiRate = formatDecimal(row.ml_price, 2, true);
                        formattedRow.push(
                            aiRate == 0 || aiRate == '0.00' ? '' : aiRate
                        );
                        const marginDollars = formatDecimal(
                            row.margin_dollars,
                            2,
                            true
                        );
                        formattedRow.push(
                            marginDollars == 0 || marginDollars == '0.00'
                                ? ''
                                : marginDollars
                        );
                        // Dividing by 100 to account for formatting issue when using percent button in Excel
                        const margin = formatPercent(
                            row.margin,
                            1,
                            false,
                            true
                        );
                        formattedRow.push(
                            margin != '' && margin != 0 && margin != 0.0
                                ? margin / 100
                                : ''
                        );
                        const clt = formatHoursToBusinessDays(
                            row.clt,
                            0,
                            false,
                            true
                        );
                        formattedRow.push(clt == 0 ? '' : clt);
                        const blt = formatHoursToBusinessDays(
                            row.blt,
                            0,
                            false,
                            true
                        );
                        formattedRow.push(blt == 0 ? '' : blt);
                        const prebook = formatHoursToBusinessDays(
                            row.prebook,
                            0,
                            false,
                            true
                        );
                        formattedRow.push(prebook == 0 ? '' : prebook);
                        const otd = formatOTD(
                            row.destinationDelayMinutes,
                            true
                        );
                        formattedRow.push(otd == 0 ? '' : otd);
                        csvData.push(formattedRow);
                    });

                    let filename = `${client_data_name}_${this.startDate}_${this.endDate}.csv`;

                    // Export
                    let csvContent =
                        'data:text/csv;charset=utf-8,' +
                        csvData.map((e) => e.join(',')).join('\n');
                    const encodedUri = encodeURI(csvContent);
                    const link = document.createElement('a');
                    link.setAttribute('href', encodedUri);
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);

                    link.click();
                    this.isLoading = false;
                    this.$emit('exportDialogState', false);
                })
                .catch((error) => {
                    this.isLoading = false;
                    this.snackbar = true;
                    this.snackText =
                        'An error occurred during export. If error persists please contact a system administrator.';
                    this.snackColor = 'red darken-2';
                });
        },

        validateDates() {
            const firstDate = new Date(this.startDate);
            const secondDate = new Date(this.endDate);
            const diff = secondDate.getTime() - firstDate.getTime();

            // check start date is before end date
            if (diff < 0) {
                return 'End date must come after start date';
            }

            // check range is within 90 days
            return diff / (1000 * 60 * 60 * 24) <= this.maxRange
                ? true
                : 'Exceeds max range';
        },

        getLabel() {
            const client = this.userRole == 'broker' ? 'Shipper' : 'Broker';
            return `Select ${client}`;
        }
    },

    data: function () {
        return {
            userRole: stateAPI.getStateProperty(this, 'role'),
            userId: stateAPI.getStateProperty(this, 'user_id'),
            startDate: '',
            startMenu: false,
            endDate: '',
            endMenu: false,
            shipperBrokerList: null,
            sbValue: null,
            headers: [
                'Shipper Name',
                'Broker Name',
                'Shipment',
                'Origin City',
                'Origin State',
                'Destination City',
                'Destination State',
                'Distance (mi)',
                'Equipment Type',
                'Ship Date',
                'Score',
                'Shipper Spend (LH + Fuel) ($)',
                'Shipper Spend Accessorials ($)',
                'Total Shipper Spend ($)',
                'Truck Cost (LH + Fuel) ($)',
                'Truck Cost Accessorials ($)',
                'Total Truck Cost ($)',
                'TruceAI Truck Cost ($)',
                'Margin ($)',
                'Margin (%)',
                'CLT (Days)',
                'BLT (Days)',
                'Pre-Book (Days)',
                'Minutes Late'
            ],
            valid: true,
            maxDate: '',
            isLoading: false,
            snackbar: false,
            snackText: '',
            snackColor: '',
            maxRange: 60
        };
    }
};
</script>
