<template>
    <v-app :style="{ background: $vuetify.theme.themes[theme].background }">
        <NavBar :prop_username="usn" />
        <SideBar />
        <v-main class="main-container">
            <v-container fluid style="width: 87%">
                <v-row>
                    <v-breadcrumbs
                        ref="breadcrumbs"
                        :items="crumbs"
                        divider=">"
                        large
                        class="mb-0 pt-4 mt-3 mr-4"
                    ></v-breadcrumbs>
                    <v-spacer></v-spacer>
                    <v-col>
                        <p
                            class="text-end mb-0 pt-4 mr-4 font-weight-light"
                            style="color: grey"
                        >
                            {{ periodDates }}
                        </p>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12">
                        <v-card height="100%" elevation="5">
                            <v-card-title class="font-weight-light">
                                <v-icon
                                    large
                                    :color="
                                        $vuetify.theme.themes[theme].headerIcon
                                    "
                                    class="mr-4"
                                    >mdi-google-analytics</v-icon
                                >Network Analytics
                                <v-spacer></v-spacer>
                                <v-chip
                                    v-for="(keyval, index) in Array.from(
                                        filterChips
                                    )"
                                    :key="index"
                                    :color="
                                        $vuetify.theme.themes[theme].chipColor
                                    "
                                    text-color="white"
                                    class="mr-2"
                                    close
                                    @click:close="resetFilters(keyval[0])"
                                >
                                    {{ keyval[1] }}
                                </v-chip>
                                <v-autocomplete
                                    :filter="customerFilter"
                                    :items="searchData"
                                    :label="`Filter user data by ${
                                        isBrokerUser ? 'Shipper' : 'Broker'
                                    }`"
                                    hide-details
                                    dense
                                    item-text="name"
                                    item-value="id"
                                    v-model="selectedTarget"
                                    style="max-width: 300px"
                                    class="mx-2"
                                >
                                    <template>
                                        <v-icon
                                            color="blue darken-4"
                                            style="margin-right: 10px"
                                            >mdi-lan-connect</v-icon
                                        >
                                    </template>
                                </v-autocomplete>

                                <Filters
                                    @applySelectedFilters="
                                        handleEmmittedFilters
                                    "
                                    :time_period="timePeriod"
                                    :volume_threshold="volumeThreshold"
                                    :is_analytics="true"
                                    :updateData="[this.filters]"
                                />
                            </v-card-title>
                        </v-card>
                    </v-col>
                </v-row>
                <br />

                <v-row>
                    <!-- Column 1 -->
                    <v-col cols="6">
                        <v-card elevation="6" ref="mapCard">
                            <v-card-title class="font-weight-light">
                                Filter by Origin and Destination
                            </v-card-title>
                            <v-card-text>
                                <v-row>
                                    <v-col
                                        class="pr-0"
                                        style="
                                            display: flex;
                                            flex-direction: column;
                                        "
                                        cols="3"
                                    >
                                        <v-select
                                            style="
                                                max-width: 300px;
                                                max-height: 60px;
                                            "
                                            label="Origin"
                                            :items="originSelectItems"
                                            v-model="originSelect"
                                            item-text="label"
                                            item-value="value"
                                            return-object
                                            hide-details
                                            @focus="
                                                addSelectListeners(
                                                    $event,
                                                    'origin'
                                                )
                                            "
                                        >
                                            <template #item="{ item }">
                                                <div class="selectorItem">
                                                    {{ item.label }}
                                                </div>
                                            </template>
                                        </v-select>
                                        <v-select
                                            style="
                                                max-width: 300px;
                                                justify-self: flex-start;
                                            "
                                            label="Destination"
                                            :items="destinationSelectItems"
                                            v-model="destinationSelect"
                                            item-text="label"
                                            item-value="value"
                                            return-object
                                            hide-details
                                            @focus="
                                                addSelectListeners(
                                                    $event,
                                                    'destination'
                                                )
                                            "
                                        >
                                            <template #item="{ item }">
                                                <div class="selectorItem">
                                                    {{ item.label }}
                                                </div>
                                            </template>
                                        </v-select>
                                        <div
                                            style="justify-self: flex-end"
                                            class="mb-2"
                                        >
                                            <div class="text-h5">Legend</div>
                                            <div class="text-subtitle-2">
                                                <v-icon
                                                    :color="
                                                        this.series.regions[0]
                                                            .scale.origin
                                                    "
                                                    style="margin-bottom: 2px"
                                                    >mdi-square-rounded</v-icon
                                                >
                                                Origin
                                            </div>
                                            <div class="text-subtitle-2">
                                                <v-icon
                                                    :color="
                                                        this.series.regions[0]
                                                            .scale.destination
                                                    "
                                                    style="margin-bottom: 2px"
                                                    >mdi-square-rounded</v-icon
                                                >
                                                Destination
                                            </div>
                                            <div class="text-subtitle-2">
                                                <v-icon
                                                    :color="
                                                        this.series.regions[0]
                                                            .scale.internal
                                                    "
                                                    style="margin-bottom: 2px"
                                                    >mdi-square-rounded</v-icon
                                                >
                                                Internal
                                            </div>
                                        </div>
                                    </v-col>
                                    <v-col cols="9" ref="mapcol">
                                        <vuevectormap
                                            :key="mapKey"
                                            map="us_lcc_en"
                                            ref="map"
                                            :width="mapWidth"
                                            :height="mapHeight"
                                            :showTooltip="false"
                                            :regionsSelectable="false"
                                            :zoomButtons="false"
                                            :zoomOnScroll="false"
                                            :series="series"
                                            :labels="labels"
                                            :regionLabelStyle="regionLabelStyle"
                                            :regionStyle="regionStyle"
                                        ></vuevectormap>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>

                        <v-card elevation="5" class="mt-6">
                            <v-card-title
                                class="font-weight-light pb-0"
                                style="overflow-y: hidden; white-space: nowrap"
                            >
                                Margin per Load vs. Network
                            </v-card-title>
                            <v-card-actions class="justify-center">
                                <div style="width: 100%">
                                    <Graph
                                        graphName="mplAnalytics"
                                        :rawData="lineGraphData"
                                        :timeRange="timePeriod"
                                        :isBroker="isBrokerUser"
                                    ></Graph>
                                </div>
                            </v-card-actions>
                        </v-card>

                        <v-card elevation="5" class="mt-6">
                            <v-card-title
                                class="font-weight-light pb-0"
                                style="overflow-y: hidden; white-space: nowrap"
                            >
                                Truck Cost Per Mile vs. Network
                            </v-card-title>
                            <v-card-actions class="justify-center">
                                <div style="width: 100%">
                                    <Graph
                                        graphName="cpmChart"
                                        :rawData="lineGraphData"
                                        :timeRange="timePeriod"
                                        :isBroker="isBrokerUser"
                                    ></Graph>
                                </div>
                            </v-card-actions>
                        </v-card>

                        <v-card elevation="5" class="mt-6">
                            <v-card-title
                                class="font-weight-light pb-0"
                                style="overflow-y: hidden; white-space: nowrap"
                            >
                                Prebook vs. Network
                            </v-card-title>
                            <v-card-actions class="justify-center">
                                <div style="width: 100%">
                                    <Graph
                                        graphName="pbChart"
                                        :rawData="lineGraphData"
                                        :timeRange="timePeriod"
                                        :isBroker="isBrokerUser"
                                    ></Graph>
                                </div>
                            </v-card-actions>
                        </v-card>
                    </v-col>

                    <!-- Column 2 -->
                    <v-col cols="6">
                        <v-row>
                            <v-col cols="6">
                                <v-card elevation="5" style="height: 100%">
                                    <v-card-title
                                        class="font-weight-light pb-0"
                                        style="
                                            overflow-y: hidden;
                                            white-space: nowrap;
                                            font-size: 1.2vw;
                                        "
                                    >
                                        Network Lowest CPM: St to St
                                    </v-card-title>
                                    <v-card-actions class="justify-center pr-5">
                                        <div style="width: 100%">
                                            <v-data-table
                                                :headers="headers"
                                                :items="ranksIncrease"
                                                hide-default-footer
                                                class="elevation-0 cpmDataTable"
                                            >
                                                <template
                                                    v-slot:item.rank="{ item }"
                                                >
                                                    <v-chip
                                                        color="#66BB6A"
                                                        dark
                                                    >
                                                        {{ item.rank }}
                                                    </v-chip>
                                                </template>
                                            </v-data-table>
                                        </div>
                                    </v-card-actions>
                                </v-card>
                            </v-col>

                            <v-col cols="6">
                                <v-card elevation="5" style="height: 100%">
                                    <v-card-title
                                        class="font-weight-light pb-0"
                                        style="
                                            overflow-y: hidden;
                                            white-space: nowrap;
                                            font-size: 1.2vw;
                                        "
                                    >
                                        Network Highest CPM: St to St
                                    </v-card-title>
                                    <v-card-actions class="justify-center pr-5">
                                        <div style="width: 100%">
                                            <v-data-table
                                                :headers="headers"
                                                :items="ranksDecrease"
                                                hide-default-footer
                                                class="elevation-0 cpmDataTable"
                                            >
                                                <template
                                                    v-slot:item.rank="{ item }"
                                                >
                                                    <v-chip color="red" dark>
                                                        {{ item.rank }}
                                                    </v-chip>
                                                </template>
                                            </v-data-table>
                                        </div>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                        </v-row>

                        <v-card elevation="5" class="mt-6">
                            <v-card-title
                                class="font-weight-light pb-0"
                                style="overflow-y: hidden; white-space: nowrap"
                            >
                                % of Shipments by Distance
                            </v-card-title>
                            <v-card-actions class="justify-center pr-5">
                                <div style="width: 100%">
                                    <Graph
                                        graphName="distanceChart"
                                        :rawData="stackedGraphData"
                                        :timeRange="timePeriod"
                                        :isBroker="isBrokerUser"
                                    ></Graph>
                                </div>
                            </v-card-actions>
                        </v-card>

                        <v-card elevation="5" class="mt-6">
                            <v-card-title
                                class="font-weight-light pb-0"
                                style="overflow-y: hidden; white-space: nowrap"
                            >
                                Shipper Spend Per Mile vs. Network
                            </v-card-title>
                            <v-card-actions class="justify-center">
                                <div style="width: 100%">
                                    <Graph
                                        graphName="spmAnalytics"
                                        :rawData="lineGraphData"
                                        :timeRange="timePeriod"
                                        :isBroker="isBrokerUser"
                                    ></Graph>
                                </div>
                            </v-card-actions>
                        </v-card>

                        <v-card elevation="5" class="mt-6">
                            <v-card-title
                                class="font-weight-light pb-0"
                                style="overflow-y: hidden; white-space: nowrap"
                            >
                                % Shipments CLT &#60; 2 vs. Network
                            </v-card-title>
                            <v-card-actions class="justify-center">
                                <div style="width: 100%">
                                    <Graph
                                        graphName="cltChart"
                                        :rawData="lineGraphData"
                                        :timeRange="timePeriod"
                                        :isBroker="isBrokerUser"
                                    ></Graph>
                                </div>
                            </v-card-actions>
                        </v-card>
                        <v-card elevation="5" class="mt-4">
                            <v-card-title
                                class="font-weight-light pb-0"
                                style="overflow-y: hidden; white-space: nowrap"
                            >
                                Volume by Day of Week
                            </v-card-title>
                            <v-card-actions class="justify-center pr-5">
                                <Graph
                                    graphName="dowVolumeBar"
                                    :rawData="pieGraphData"
                                    :timeRange="timePeriod"
                                    :isBroker="isBrokerUser"
                                ></Graph>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
        <SplashScreen :is_loading="isLoading" />
    </v-app>
</template>
<script>
import NavBar from '../components/NavBar';
import SideBar from '../components/SideBar';
import Filters from '../components/Filters';
import Graph from '../components/Graph';
import SplashScreen from '../components/SplashScreen';
import chroma from 'chroma-js';
import * as fetch from '../fetchShipments';
import * as stateAPI from '../stateAPI';
import * as user_analytics from '../analytics/sendAnalyticsEvent';
import * as format from '../formatShipmentData';
import * as utils from '../utils';
import * as stateConstants from '../stateConstants';

/*
This handler is needed to suppress vuevectormap's incessant bitching when fed a showTooltip prop of false,
which functions properly but results in a stream of console errors when mousing over the map.
It's hardcoded to stop propagation of this event and this event only.
*/
window.onerror = (msg, url) => {
    if (
        msg ===
            "Uncaught TypeError: Cannot read properties of undefined (reading 'text')" &&
        url ===
            'webpack-internal:///./node_modules/jsvectormap/dist/js/jsvectormap.min.js'
    ) {
        // For some reason we need to pass true instead of false to stop error propagation here
        return true;
    }
};

export default {
    name: 'Analytics',

    components: { NavBar, SideBar, Filters, Graph, SplashScreen },

    props: ['prop_usn'],

    computed: {
        originSelectItems() {
            let result = [{ label: 'All', value: '' }];
            const filteredRegions = Object.entries(
                stateConstants.regions
            ).filter((item) => {
                return (
                    item[1].filter((jtem) => this.originStates.includes(jtem))
                        .length > 0
                );
            });
            for (const region of filteredRegions) {
                result.push({ label: region[0], value: region[1] });
            }
            return result;
        },
        destinationSelectItems() {
            let result = [{ label: 'All', value: '' }];
            const filteredRegions = Object.entries(
                stateConstants.regions
            ).filter((item) => {
                return (
                    item[1].filter((jtem) =>
                        this.destinationStates.includes(jtem)
                    ).length > 0
                );
            });
            for (const region of filteredRegions) {
                result.push({ label: region[0], value: region[1] });
            }
            return result;
        },
        labels() {
            // Labels for markers and regions
            return {
                markers: {
                    render(marker, index) {
                        return marker.name;
                    }
                },
                regions: {
                    render(code) {
                        const rlb = stateConstants.regionLabels;
                        const state = code.split('-')[1];
                        return state in rlb ? rlb[state].label : null;
                    },
                    offsets(code) {
                        const rlb = stateConstants.regionLabels;
                        const state = code.split('-')[1];
                        return state in rlb ? rlb[state].offset : null;
                    }
                }
            };
        },
        theme() {
            return this.$vuetify.theme.dark ? 'dark' : 'light';
        }
    },

    watch: {
        originSelect(cur, prev) {
            if (cur.label !== 'All') {
                this.addRegionTag(cur.label, 'origin');
            }
            if (prev.label !== 'All') {
                this.deleteRegionTag(prev.label, 'origin');
            }
            this.getAnalyticsData();
        },

        destinationSelect(cur, prev) {
            if (cur.label !== 'All') {
                this.addRegionTag(cur.label, 'destination');
            }
            if (prev.label !== 'All') {
                this.deleteRegionTag(prev.label, 'destination');
            }
            this.getAnalyticsData();
        },

        selectedTarget(val) {
            if (val) {
                this.getAnalyticsData();
            }
        }
    },

    methods: {
        resetFilters(objType) {
            utils.resetFilters(
                objType,
                this.endDate,
                this.timePeriod,
                this.tickLabels,
                this.filterChips,
                this.volumeThreshold,
                false,
                this
            );

            if (this.filterChips.size == 0) {
                this.showFilterChips = false;
                this.showAggTabs = true;
                this.getAnalyticsData();
            } else {
                this.handleEmmittedFilters(
                    this.endDate,
                    this.timePeriod,
                    this.volumeThreshold,
                    this.selectedEquipmentTypes,
                    false,
                    this.filters
                );
            }
        },

        handleEmmittedFilters(
            date,
            timePeriod,
            volumeThreshold,
            equipmentTypes,
            isComparisonEnabled = false,
            filters
        ) {
            const milliToDay = 1000 * 60 * 60 * 24;

            // store filters state
            this.filters = filters;

            // set time period
            this.timePeriod = timePeriod;

            // set end date
            this.endDate = new Date(date);

            // calculate start date
            this.startDate = new Date(
                new Date(this.endDate).getTime() - timePeriod * milliToDay
            );

            this.isPeriodOneDay =
                this.endDate.toLocaleDateString() ==
                this.startDate.toLocaleDateString()
                    ? true
                    : false;

            // set volume threshold
            this.volumeThreshold = volumeThreshold;

            // set equipment types
            this.selectedEquipmentTypes = equipmentTypes;

            // set new filter chips - call from utils
            // dont display timePeriod if enddate is the only filter chip
            if (
                !(this.filterChips.size == 1 && this.filterChips.has('enddate'))
            ) {
                this.setFilterChips(
                    date,
                    timePeriod,
                    volumeThreshold,
                    equipmentTypes
                );
            }

            // toggle off tabs
            this.showFilterChips = this.filterChips.size == 0 ? false : true;
            this.showAggTabs = !this.showFilterChips;
            if (this.showFilterChips == false) {
                this.keyMetricToggle = null;
            }
            this.isComparisonEnabled = isComparisonEnabled;

            this.getAnalyticsData();

            // this.computeKeyMetricsFilters();
        },

        setFilterChips(
            date,
            timePeriod,
            volumeThreshold,
            selectedEquipmentTypes
        ) {
            var newFilterChips = new Map();

            const defaultStartDate = new Date(
                stateAPI.getStateProperty(this, 'startDate')
            );

            const defaultEndDate = new Date();

            if (
                new Date(date).toLocaleDateString() !=
                    defaultEndDate.toLocaleDateString() ||
                new Date(this.startDate).toLocaleDateString() !=
                    defaultStartDate.toLocaleDateString()
            ) {
                newFilterChips = utils.updateFilterChips(
                    'dateRange',
                    'Date Range: ',
                    this.isPeriodOneDay
                        ? [new Date(date).toLocaleDateString()]
                        : [
                              new Date(this.startDate).toLocaleDateString() +
                                  ' - ' +
                                  new Date(date).toLocaleDateString()
                          ],
                    newFilterChips.size != 0 ? newFilterChips : this.filterChips
                );
            }

            if (volumeThreshold > 0) {
                newFilterChips = utils.updateFilterChips(
                    'volumeThreshold',
                    'Volume: ',
                    [parseInt(volumeThreshold)],
                    newFilterChips.size != 0 ? newFilterChips : this.filterChips
                );
            }

            var unselectedEquipmentTypes = new Set(this.EQUIPMENT_TYPE_LIST);
            // Update filter chips based on equipment types selected in filter menu.

            if (
                unselectedEquipmentTypes.size != selectedEquipmentTypes.length
            ) {
                for (let i = 0; i < selectedEquipmentTypes.length; i++) {
                    const equipmentType = selectedEquipmentTypes[i];
                    // This equipment type was selected, so prune it from the unselected set.
                    unselectedEquipmentTypes.delete(equipmentType);
                    // if (this.isApplyClicked) {
                    newFilterChips = utils.updateFilterChips(
                        'equipment' + '_' + equipmentType,
                        'Equip. Type: ',
                        [equipmentType],
                        newFilterChips.size != 0
                            ? newFilterChips
                            : this.filterChips
                    );
                    // }
                }
            }

            this.filterChips = newFilterChips;
        },

        resetGraphs() {
            //clear ranks data
            this.ranksIncrease = [];
            this.ranksDecrease = [];
        },

        getAnalyticsData() {
            this.isLoading = true;
            if (this.isBrokerUser) {
                this.query_params.brokerId = stateAPI.getStateProperty(
                    this,
                    'user_id'
                );
            } else {
                this.query_params.shipperId = stateAPI.getStateProperty(
                    this,
                    'user_id'
                );
            }
            if (this.selectedTarget && this.selectedTarget !== 'All') {
                this.query_params[
                    this.isBrokerUser ? 'shipperId' : 'brokerId'
                ] = this.selectedTarget;
            } else {
                delete this.query_params[
                    this.isBrokerUser ? 'shipperId' : 'brokerId'
                ];
            }
            this.query_params.agg_by_week = 0;
            this.query_params.start_date = new Date(
                new Date(this.startDate) -
                    new Date().getTimezoneOffset() * 60000
            )
                .toISOString()
                .substring(0, 10);
            this.query_params.end_date = this.getAnalyticsEndDate();
            this.query_params.volume_threshold = this.volumeThreshold;
            this.query_params.equipment_type = this.selectedEquipmentTypes;
            this.query_params.metric_trend_type = this.metricTrendType;
            this.query_params.include_accessorials = this.includeAcc ? 1 : 0;
            this.query_params.user_type = this.isBrokerUser
                ? 'broker'
                : 'shipper';
            this.query_params.is_broker_user = this.isBrokerUser;

            this.query_params.search_list = 0;
            this.query_params.analytics = 1;
            this.query_params.get_locations = 0;

            if (this.originSelect.value !== '') {
                this.query_params.origin_state_filter = this.originSelect.value;
            } else {
                delete this.query_params.origin_state_filter;
            }
            if (this.destinationSelect.value !== '') {
                this.query_params.destination_state_filter =
                    this.destinationSelect.value;
            } else {
                delete this.query_params.destination_state_filter;
            }
            fetch.fetchAnalyticsData(this.query_params).then((result) => {
                this.lineGraphData = result.line_graphs;
                this.stackedGraphData = result.stacked_graph;
                this.resetGraphs();

                var list_decrease_data = result.list_decrease;
                for (var k = 0; k < list_decrease_data.length; k++) {
                    this.ranksIncrease.push({
                        rank: k + 1,
                        lane: list_decrease_data[k].state_to_state,
                        cpm: format.formatDollars(
                            list_decrease_data[k].cpm_per_lane,
                            2
                        )
                    });
                }

                var list_increase_data = result.list_increase;
                for (var q = 0; q < list_increase_data.length; q++) {
                    this.ranksDecrease.push({
                        rank: q + 1,
                        lane: list_increase_data[q].state_to_state,
                        cpm: format.formatDollars(
                            list_increase_data[q].cpm_per_lane,
                            2
                        )
                    });
                }

                this.pieGraphData = result.pie_graph;
                utils.formatDates(
                    this.startDate,
                    this.endDate,
                    this.timePeriod,
                    this
                );
                this.isLoading = false;
            });
        },

        colorMapRegion(region, color) {
            for (const state of stateConstants.regions[region]) {
                this.series.regions[0].values[`US-${state}`] = color;
            }
            // This is required to render the map
            this.mapKey += 1;
        },

        addRegionTag(regionName, tag) {
            if (!(regionName in this.regionColorTags)) {
                this.regionColorTags[regionName] = new Set();
            }
            this.regionColorTags[regionName].add(tag);
            this.recolorMap();
        },

        deleteRegionTag(regionName, tag) {
            if (regionName in this.regionColorTags) {
                this.regionColorTags[regionName].delete(tag);
                if (this.regionColorTags[regionName].size === 0) {
                    delete this.regionColorTags[regionName];
                }
            }
            this.recolorMap();
        },

        recolorMap() {
            this.mapDefaultGrayScale();
            for (const [region, tags] of Object.entries(this.regionColorTags)) {
                if (tags.has('origin') && tags.has('destination')) {
                    this.colorMapRegion(region, 'internal');
                } else if (tags.has('origin')) {
                    this.colorMapRegion(region, 'origin');
                } else if (tags.has('destination')) {
                    this.colorMapRegion(region, 'destination');
                }
            }
        },

        mapDefaultGrayScale() {
            let colors = chroma.scale(['#5C6BC0', '#98a2d9']).colors(3);
            const hardcodedColorIndices = {
                'South East': 1,
                West: 1,
                'North East': 2,
                'South West': 2,
                Midwest: 0,
                Other: 0
            };
            let index = 0;
            for (const [region, states] of Object.entries(
                stateConstants.regions
            )) {
                this.series.regions[0].scale[`default${index}`] =
                    colors[hardcodedColorIndices[region]];
                this.colorMapRegion(region, `default${index}`);
                index += 1;
            }
        },

        mapDefaultFlat() {
            let index = 0;
            for (const [region, states] of Object.entries(
                stateConstants.regions
            )) {
                this.series.regions[0].scale[`default${index}`] = '#9FA8DA';
                this.colorMapRegion(region, `default${index}`);
                index += 1;
            }
        },

        getRegionFromState(stateCode) {
            return Object.entries(stateConstants.regions).filter((region) =>
                region[1].includes(stateCode)
            )[0][0];
        },

        highlightRegion(regionName, selectType) {
            const states = stateConstants.regions[regionName];
            let baseColor = this.series.regions[0].scale[selectType];
            if (this.regionColorTags[regionName]) {
                if (
                    selectType === 'destination' &&
                    this.regionColorTags[regionName].has('origin')
                ) {
                    baseColor = this.series.regions[0].scale['internal'];
                } else if (
                    selectType === 'origin' &&
                    this.regionColorTags[regionName].has('destination')
                ) {
                    baseColor = this.series.regions[0].scale['internal'];
                }
            }
            // This might need adjustment when reimplementing dark mode
            const backgroundColor = 'white';
            const strokeColor = chroma.mix(
                baseColor,
                backgroundColor,
                '0.35',
                'hsl'
            );
            for (const state of states) {
                let element = document.querySelector(
                    `path[data-code="US-${state}"]`
                );
                this.highlight(element, strokeColor);
                this.highlighted.push(element);
            }
        },

        removeHighlight() {
            for (const element of this.highlighted) {
                this.dim(element);
            }
            this.highlighted = [];
            this.recolorMap();
        },

        highlight(element, color) {
            element.setAttribute('stroke', color);
            element.setAttribute('stroke-width', '0.5%');
        },

        dim(element) {
            element.setAttribute('stroke', 'none');
            element.setAttribute('stroke-width', '0%');
        },

        addSelectListeners(event, selectType) {
            // This is some evil shit but it works and i don't have any better ideas
            setTimeout(() => {
                let listNodes = document.querySelectorAll(
                    '.v-select-list .v-list-item .selectorItem'
                );
                for (const node of listNodes) {
                    node.parentNode.selectType = selectType;
                    node.parentNode.addEventListener(
                        'mouseover',
                        this.hoverSelect
                    );
                    node.parentNode.addEventListener(
                        'mouseleave',
                        this.removeHighlight
                    );
                }
            }, 150);
        },

        hoverSelect(event) {
            let region = event.currentTarget.firstChild.innerHTML.trim();
            if (region !== 'All') {
                this.highlightRegion(region, event.currentTarget.selectType);
            }
        },

        getCustomerList() {
            if (this.isBrokerUser) {
                this.query_params.shipperId = this.shipperId;
                this.query_params.brokerId = stateAPI.getStateProperty(
                    this,
                    'user_id'
                );
            } else {
                this.query_params.brokerId = this.brokerId;
                this.query_params.shipperId = stateAPI.getStateProperty(
                    this,
                    'user_id'
                );
            }
            this.query_params.start_date = this.startDate;
            this.query_params.end_date = new Date(
                new Date(this.curDate) - new Date().getTimezoneOffset() * 60000
            )
                .toISOString()
                .substring(0, 10);
            this.query_params.equipment_type = this.selectedEquipmentTypes;
            this.query_params.include_accessorials = this.includeAcc ? 1 : 0;
            this.query_params.volume_threshold = this.volumeThreshold;

            // These parameters don't do anything but are required by the lambda
            this.query_params.is_drilldown = 0;
            this.query_params.page_number = 0;
            this.query_params.page_size = 100;
            this.query_params.order_by = 'volume';
            this.query_params.index_by = 'brokerId';
            this.query_params.order_by_direction = 'ASC';
            this.query_params.shipment_page_number = 0;
            this.query_params.shipment_page_size = 8;
            this.query_params.shipment_order_by_direction = 'DESC';
            this.query_params.shipment_order_by = 'originCloseTime';

            this.query_params.user_type = this.isBrokerUser
                ? 'broker'
                : 'shipper';
            this.query_params.is_broker_user = this.isBrokerUser;
            this.query_params.search_list = 1;
            this.query_params.analytics = 0;
            this.query_params.savings = 1;
            fetch.fetchShipmentData(this.query_params).then((result) => {
                if (result.search_list.length !== 0) {
                    this.searchData = [{ id: 'All', name: 'All' }].concat(
                        result.search_list
                    );
                }
            });
            this.query_params.search_list = 0;
            this.query_params.analytics = 1;
            this.query_params.savings = 0;
        },

        getValidLocations() {
            if (this.isBrokerUser) {
                this.query_params.brokerId = stateAPI.getStateProperty(
                    this,
                    'user_id'
                );
            } else {
                this.query_params.shipperId = stateAPI.getStateProperty(
                    this,
                    'user_id'
                );
            }
            this.query_params.agg_by_week = 0;
            this.query_params.start_date = new Date(
                new Date(this.startDate) -
                    new Date().getTimezoneOffset() * 60000
            )
                .toISOString()
                .substring(0, 10);
            this.query_params.end_date = this.getAnalyticsEndDate();
            this.query_params.volume_threshold = this.volumeThreshold;
            this.query_params.equipment_type = this.selectedEquipmentTypes;
            this.query_params.metric_trend_type = this.metricTrendType;
            this.query_params.include_accessorials = this.includeAcc ? 1 : 0;
            this.query_params.user_type = this.isBrokerUser
                ? 'broker'
                : 'shipper';
            this.query_params.is_broker_user = this.isBrokerUser;

            this.query_params.get_locations = 1;

            fetch.fetchAnalyticsData(this.query_params).then((result) => {
                this.originStates = result.origin_states;
                this.destinationStates = result.destination_states;
            });
            this.query_params.get_locations = 0;
        },

        customerFilter(_, queryText, itemText) {
            const cleanedQuery = queryText
                .replace(/[&@\\/#?!|^_,`+=()$~%.'";:*?<>{}]/g, ' ')
                .toLocaleLowerCase();
            const queryTokens = cleanedQuery.split(' ');
            const fillers = ['to', 'and'];
            for (const token of queryTokens) {
                if (fillers.includes(token)) {
                    continue;
                }
                if (itemText.toLocaleLowerCase().indexOf(token) === -1) {
                    return false;
                }
            }
            return true;
        },

        // Get date for 3 business day delay
        getAnalyticsEndDate() {
            const endDay = new Date(this.endDate).getDay();
            var endDate = new Date(this.endDate);
            var newEndDate;

            if (endDay < 4) {
                newEndDate = endDate.setDate(endDate.getDate() - 5);
            } else {
                newEndDate = endDate.setDate(endDate.getDate() - 4);
            }

            return new Date(
                new Date(newEndDate) - new Date().getTimezoneOffset() * 60000
            )
                .toISOString()
                .substring(0, 10);
        },

        scaleMap() {
            // Scales map width based on parent card size. Height is left constant.
            if (this.$refs && this.$refs.mapCard) {
                this.mapWidth = `${parseInt(
                    this.$refs.mapCard.$el.offsetWidth * 0.7
                )}px`;
                this.mapHeight = `400px`;
                this.mapKey += 1;
            }
        }
    },

    beforeMount() {
        this.getAnalyticsData();
        this.crumbs = [
            {
                text: 'Network Analytics',
                disabled: true,
                href: new URL(window.location.href).pathname
            }
        ];
        stateAPI.setStateProperty(this, 'crumbs', this.crumbs);

        // Retrieve valid origins and destinations
        this.getValidLocations();

        // Populate customer search/select
        this.getCustomerList();

        // Send pageView analytics event
        const email = stateAPI.getStateProperty(this, 'email');
        const company = stateAPI.getStateProperty(this, 'company');
        const role = stateAPI.getStateProperty(this, 'role');
        user_analytics.sendEvent(email, company, role, 'pageView', {
            pageSource: 'Analytics'
        });

        // Color map with default
        this.mapDefaultGrayScale();
    },

    mounted() {
        window.addEventListener('resize', this.scaleMap);
        this.scaleMap();
    },

    data: function () {
        return {
            query_params: {},
            curDate: new Date(
                new Date() - new Date().getTimezoneOffset() * 60000
            ),
            endDate: new Date(),
            selectedEquipmentTypes: stateAPI.getStateProperty(
                this,
                'equipment_type_list'
            ),
            // EQUIPMENT_TYPE_LIST: globalVariables.default.equipment_type_list,
            EQUIPMENT_TYPE_LIST: stateAPI.getStateProperty(
                this,
                'equipment_type_list'
            ),
            usn: this.prop_usn,
            date: new Date().toISOString().substring(0, 10),
            periodDates: '',
            oldPeriodDates: '',
            filterChips: new Map(),
            volumeThreshold: 0,
            metricTrendType: 'CPM',
            startDate: new Date(stateAPI.getStateProperty(this, 'startDate')),
            includeAcc: 0,
            searchData: [],
            selectedTarget: 'All',
            timePeriod: null,
            tickLabels: ['90', '60', '30', '7', '1'],
            isBrokerUser: stateAPI.getStateProperty(this, 'role') == 'broker',
            crumbs: [
                {
                    text: 'Network Analytics',
                    disabled: true,
                    href: new URL(window.location.href).pathname
                }
            ],
            filters: {
                dates: null,
                timeSlider: 5,
                volume: 0,
                equipment: stateAPI.getStateProperty(
                    this,
                    'equipment_type_list'
                )
            },
            lineGraphData: {},
            stackedGraphData: {},
            pieGraphData: {},

            // Rankings
            headers: [
                {
                    text: 'Rank',
                    align: 'start',
                    sortable: false,
                    value: 'rank'
                },
                {
                    text: 'State -> State',
                    align: 'start',
                    sortable: false,
                    value: 'lane'
                },
                { text: 'CPM', align: 'start', sortable: false, value: 'cpm' }
            ],
            ranksIncrease: [],
            ranksDecrease: [],
            isLoading: true,

            // Map props
            mapKey: 0,
            mapWidth: '',
            mapHeight: '',
            regionStyle: {
                initial: { fill: 'Gray' },
                hover: { 'fill-opacity': 1 }
            },
            regionLabelStyle: {
                initial: {
                    fill: 'white',
                    'font-family': "'Roboto', sans-serif",
                    'font-size': '12'
                }
            },
            series: {
                regions: [
                    {
                        attribute: 'fill',
                        scale: {
                            // default: 'Gray',
                            origin: '#D82760',
                            destination: '#FFA000',
                            internal: '#4DB6AC',
                            default1: '#a9a9a9',
                            default2: '#abb3b6',
                            default3: '#abb8bd',
                            default4: '#acbec4',
                            default5: '#acc3ca',
                            default6: '#adc8d1',
                            default7: '#adcdd8',
                            default8: '#add3df',
                            default9: '#add8e6',
                            default10: '#aaaeb0'
                        },
                        values: {}
                    }
                ]
            },
            regionColorTags: {},
            highlighted: [],

            // Dropdown selection props
            originSelect: { label: 'All', value: '' },
            destinationSelect: { label: 'All', value: '' },
            originStates: [],
            destinationStates: []
        };
    }
};
</script>
<style>
path[data-code] {
    cursor: default;
}
text[data-code] {
    cursor: default;
}
.cpmDataTable {
    background-color: var(--v-dataTableBackground-base) !important;
    color: var(--v-dataTableText-base) !important;
}
</style>
