var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{style:({ background: _vm.$vuetify.theme.themes[_vm.theme].background })},[_c('NavBar'),_c('SideBar'),_c('v-main',{staticClass:"main-container"},[_c('v-container',{staticStyle:{"width":"85%"},attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"end"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"text-h4 font-weight-light text-left pt-14",staticStyle:{"color":"grey"}},[_vm._v(" Favorites ")])]),_c('v-col',{staticClass:"align-center",attrs:{"cols":"4"}},[_c('v-tabs',{ref:"selectorTabs",staticStyle:{"width":"100% !important","background":"transparent !important"},attrs:{"color":"#0091ff","centered":"","optional":"","background-color":"transparent"},on:{"change":function($event){return _vm.periodTabChange()}},model:{value:(_vm.keyMetricsTabIndex),callback:function ($$v) {_vm.keyMetricsTabIndex=$$v},expression:"keyMetricsTabIndex"}},[_c('v-tab',{staticStyle:{"max-width":"10px !important"},attrs:{"disabled":_vm.queryInProgress}},[_vm._v("14D")]),_c('v-divider',{attrs:{"vertical":"","inset":""}}),_c('v-tab',{staticStyle:{"max-width":"10px !important"},attrs:{"disabled":_vm.queryInProgress}},[_vm._v("30D")]),_c('v-divider',{attrs:{"vertical":"","inset":""}}),_c('v-tab',{staticStyle:{"max-width":"10px !important"},attrs:{"disabled":_vm.queryInProgress}},[_vm._v("60D")])],1)],1),_c('v-col',{attrs:{"cols":"4"}},[_c('p',{staticClass:"text-end mb-0 mr-4 pt-2 font-weight-light",style:({
                            color: _vm.theme == 'light' ? '#545454' : 'white'
                        })},[_vm._v(" "+_vm._s(_vm.periodDates)+" ")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1 favoritesDataTable",attrs:{"headers":_vm.headers,"items":_vm.items,"custom-sort":_vm.favoriteTableSort},scopedSlots:_vm._u([{key:"header.name",fn:function(ref){
                        var header = ref.header;
return [_c('Tooltip',{attrs:{"header_text":header.text,"header_tool":header.tooltip}})]}},{key:"header.origin",fn:function(ref){
                        var header = ref.header;
return [_c('Tooltip',{attrs:{"header_text":header.text,"header_tool":header.tooltip}})]}},{key:"header.destination",fn:function(ref){
                        var header = ref.header;
return [_c('Tooltip',{attrs:{"header_text":header.text,"header_tool":header.tooltip}})]}},{key:"header.score",fn:function(ref){
                        var header = ref.header;
return [_c('Tooltip',{attrs:{"header_text":header.text,"header_tool":header.tooltip}})]}},{key:"header.equipmenttype",fn:function(ref){
                        var header = ref.header;
return [_c('Tooltip',{attrs:{"header_text":header.text,"header_tool":header.tooltip}})]}},{key:"header.total_margin",fn:function(ref){
                        var header = ref.header;
return [_c('Tooltip',{attrs:{"header_text":header.text,"header_tool":header.tooltip}})]}},{key:"header.avg_otd",fn:function(ref){
                        var header = ref.header;
return [_c('Tooltip',{attrs:{"header_text":header.text,"header_tool":header.tooltip}})]}},{key:"header.volume",fn:function(ref){
                        var header = ref.header;
return [_c('Tooltip',{attrs:{"header_text":header.text,"header_tool":header.tooltip}})]}},{key:"header.avg_cogs",fn:function(ref){
                        var header = ref.header;
return [_c('Tooltip',{attrs:{"header_text":header.text,"header_tool":header.tooltip}})]}},{key:"header.avg_revenue",fn:function(ref){
                        var header = ref.header;
return [_c('Tooltip',{attrs:{"header_text":header.text,"header_tool":header.tooltip}})]}},{key:"header.avg_miles",fn:function(ref){
                        var header = ref.header;
return [_c('Tooltip',{attrs:{"header_text":header.text,"header_tool":header.tooltip}})]}},{key:"header.margin_per_load",fn:function(ref){
                        var header = ref.header;
return [_c('v-tooltip',{attrs:{"top":"","max-width":"25%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{attrs:{"icon":"","x-small":"","color":_vm.marginPerLoadUnit ==
                                                'dollar'
                                                    ? '#0091ff'
                                                    : 'inherit'},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.marginPerLoadUnit = 'dollar'}}},[_c('v-icon',[_vm._v("mdi-currency-usd")])],1),_c('v-btn',{attrs:{"icon":"","x-small":"","color":_vm.marginPerLoadUnit ==
                                                'percent'
                                                    ? '#0091ff'
                                                    : 'inherit'},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.marginPerLoadUnit =
                                                    'percent'}}},[_c('v-icon',[_vm._v("mdi-percent-outline")])],1),_c('br'),_vm._v(" "+_vm._s(header.text)+" ")],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(header.tooltip))])])]}},{key:"header.avg_margin_dollars",fn:function(ref){
                                                    var header = ref.header;
return [_c('v-tooltip',{attrs:{"top":"","max-width":"25%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                    var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(header.tooltip))])])]}},{key:"item.favoriteIcon",fn:function(ref){
                                                    var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","max-width":"25%","open-delay":"1250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                    var on = ref.on;
                                                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.updateFavoriteLanes(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"orange","small":""}},[_vm._v(" mdi-star ")])],1)]}}],null,true)},[_c('span',[_vm._v("Favorite Lane")])])]}},{key:"item.score",fn:function(ref){
                                                    var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":220,"offset-x":"","offset-y":"","transition":"scroll-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                    var on = ref.on;
return [_c('v-chip',_vm._g({attrs:{"color":_vm.getColor(item.score),"dark":""}},on),[_vm._v(" "+_vm._s(_vm.formatScore(item.score))+" ")])]}}],null,true)},[_c('v-card',{staticClass:"pa-1"},[_c('v-card-title',{staticClass:"pb-0"},[_vm._v(" Score Breakdown ")]),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"ma-0 py-2"},[_vm._v(" On-Time Pickup "+_vm._s(_vm.formatScore( item.avg_otp_score ) == '--' ? '--' : '')+" "),(
                                                            _vm.formatScore(
                                                                item.avg_otp_score
                                                            ) != '--'
                                                        )?_c('v-progress-linear',{attrs:{"value":_vm.formatScore(
                                                                item.avg_otp_score
                                                            ),"color":_vm.getColor(
                                                                _vm.formatScore(
                                                                    item.avg_otp_score
                                                                )
                                                            )}}):_vm._e()],1),_c('v-row',{staticClass:"ma-0 py-2"},[_vm._v(" On-Time Delivery "+_vm._s(_vm.formatScore( item.avg_otd_score ) == '--' ? '--' : '')+" "),(
                                                            _vm.formatScore(
                                                                item.avg_otd_score
                                                            ) != '--'
                                                        )?_c('v-progress-linear',{attrs:{"value":_vm.formatScore(
                                                                item.avg_otd_score
                                                            ),"color":_vm.getColor(
                                                                _vm.formatScore(
                                                                    item.avg_otd_score
                                                                )
                                                            )}}):_vm._e()],1),_c('v-row',{staticClass:"ma-0 py-2"},[_vm._v(" Appointment Setting "+_vm._s(_vm.formatScore( item.avg_ltu_score ) == '--' ? '--' : '')+" "),(
                                                            _vm.formatScore(
                                                                item.avg_ltu_score
                                                            ) != '--'
                                                        )?_c('v-progress-linear',{attrs:{"value":_vm.formatScore(
                                                                item.avg_ltu_score
                                                            ),"color":_vm.getColor(
                                                                _vm.formatScore(
                                                                    item.avg_ltu_score
                                                                )
                                                            )}}):_vm._e()],1),_c('v-row',{staticClass:"ma-0 py-2"},[_vm._v(" Pre-Book "+_vm._s(_vm.formatScore( item.avg_prebook_score ) == '--' ? '--' : '')+" "),(
                                                            _vm.formatScore(
                                                                item.avg_prebook_score
                                                            ) != '--'
                                                        )?_c('v-progress-linear',{attrs:{"value":_vm.formatScore(
                                                                item.avg_prebook_score
                                                            ),"color":_vm.getColor(
                                                                _vm.formatScore(
                                                                    item.avg_prebook_score
                                                                )
                                                            )}}):_vm._e()],1),_c('v-row',{staticClass:"ma-0 py-2"},[_vm._v(" Cost Performance "),_c('v-progress-linear',{attrs:{"value":_vm.formatScore(
                                                                item.avg_cost_score
                                                            ),"color":_vm.getColor(
                                                                _vm.formatScore(
                                                                    item.avg_cost_score
                                                                )
                                                            )}})],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"justify-center",attrs:{"cols":"6"}},[_c('v-col',[_c('v-row',{staticClass:"justify-center scorecardMetricValue"},[_vm._v(" "+_vm._s(_vm.formatHoursToBusinessDays( item.avg_clt, 1 ))+" ")]),_c('v-row',{staticClass:"justify-center font-weight-light"},[_vm._v(" "+_vm._s('Avg. CLT')+" ")])],1)],1),_c('v-col',{staticClass:"justify-center",attrs:{"cols":6}},[_c('v-col',[_c('v-row',{staticClass:"justify-center scorecardMetricValue"},[_vm._v(" "+_vm._s(_vm.formatHoursToBusinessDays( item.avg_blt, 1 ))+" ")]),_c('v-row',{staticClass:"justify-center font-weight-light"},[_vm._v(" "+_vm._s('Avg. BLT')+" ")])],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"justify-center",attrs:{"cols":"12"}},[_c('v-col',[_c('v-row',{staticClass:"justify-center scorecardMetricValue"},[_vm._v(" "+_vm._s(_vm.formatHoursToBusinessDays( item.avg_prebook, 1 ))+" ")]),_c('v-row',{staticClass:"justify-center font-weight-light"},[_vm._v(" "+_vm._s('Avg. Prebook')+" ")])],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"justify-center",attrs:{"cols":"6"}},[_c('v-col',[_c('v-row',{staticClass:"justify-center scorecardMetricValue"},[_vm._v(" "+_vm._s(_vm.formatPercent( item.avg_otp ))+" ")]),_c('v-row',{staticClass:"justify-center font-weight-light"},[_vm._v(" "+_vm._s('OTP')+" ")])],1)],1),_c('v-col',{staticClass:"justify-center",attrs:{"cols":"6"}},[_c('v-col',[_c('v-row',{staticClass:"justify-center scorecardMetricValue"},[_vm._v(" "+_vm._s(_vm.formatPercent( item.avg_otd ))+" ")]),_c('v-row',{staticClass:"justify-center font-weight-light"},[_vm._v(" "+_vm._s('OTD')+" ")])],1)],1)],1)],1)],1)],1)]}},{key:"item.equipmenttype",fn:function(ref){
                                                            var item = ref.item;
return [_vm._v(" "+_vm._s(item.shipmentmode && item.shipmentmode == 'IMDL' ? 'IMDL - ' + item.equipmenttype : item.equipmenttype)+" ")]}},{key:"item.total_margin",fn:function(ref){
                                                            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDollars(item.total_margin))+" ")]}},{key:"item.avg_margin",fn:function(ref){
                                                            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPercent(item.avg_margin, 1))+" ")]}},{key:"item.avg_margin_dollars",fn:function(ref){
                                                            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDollars(item.avg_margin_dollars))+" ")]}},{key:"item.margin_per_load",fn:function(ref){
                                                            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.marginPerLoadUnit == 'percent' ? _vm.formatPercent(item.avg_margin, 1) : _vm.formatDollars(item.avg_margin_dollars))+" ")]}},{key:"item.avg_otd",fn:function(ref){
                                                            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPercent(item.avg_otd))+" ")]}},{key:"item.avg_cogs",fn:function(ref){
                                                            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDollars(item.avg_cogs))+" ")]}},{key:"item.avg_revenue",fn:function(ref){
                                                            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDollars(item.avg_revenue))+" ")]}},{key:"item.avg_miles",fn:function(ref){
                                                            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDecimal(item.avg_miles, 0))+" ")]}},{key:"item.volume",fn:function(ref){
                                                            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.volume))+" ")]}},{key:"item.gotoDrilldownIcon",fn:function(ref){
                                                            var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","max-width":"25%","open-delay":"1250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                            var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-icon',{staticClass:"gotoIcon",staticStyle:{"font-size":"20px !important"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.gotoItemLaneDrilldown(item)}}},[_vm._v(" mdi-forwardburger ")])],1)]}}],null,true)},[_c('span',[_vm._v("Lane Drilldown")])])]}}])})],1)],1)],1),_c('v-dialog',{attrs:{"width":"80%","overlay-color":"black"},model:{value:(_vm.laneDrilldown),callback:function ($$v) {_vm.laneDrilldown=$$v},expression:"laneDrilldown"}},[_c('LaneDrilldown',{attrs:{"drilldown_object":_vm.drilldownObject,"prop_broker":_vm.isBrokerUser},on:{"stopLoading":function($event){_vm.isLoading = false}}})],1)],1),_c('SplashScreen',{staticStyle:{"z-index":"220"},attrs:{"is_loading":_vm.isLoading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }