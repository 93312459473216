var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{style:({ background: _vm.$vuetify.theme.themes[_vm.theme].background })},[_c('NavBar',{attrs:{"prop_username":_vm.usn}}),_c('SideBar'),_c('v-main',{staticClass:"main-container"},[_c('v-container',{staticStyle:{"width":"91%"},attrs:{"fluid":"","dense":""}},[(_vm.isAdminUser)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"pb-0 mb-n8",attrs:{"cols":"12"}},[_c('p',{staticClass:"text-end font-weight-light",staticStyle:{"color":"grey"}},[_vm._v(" "+_vm._s(_vm.dataFreshnessLabel)+" ")])])],1):_vm._e(),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"2"}},[_c('v-breadcrumbs',{ref:"breadcrumbs",staticClass:"mb-0 pt-4 mt-3 mr-4",attrs:{"items":_vm.crumbs,"divider":">","large":""}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"8"}},[_c('v-tabs',{staticClass:"mt-3 mb-3",attrs:{"value":_vm.selectDashboardType == 'Broker Dashboard' ||
                            _vm.selectDashboardType == 'Shipper Dashboard'
                                ? 0
                                : 1,"color":"rgba(0, 145, 255, 0.85)","background-color":"transparent","centered":""}},[(!_vm.isBrokerUser)?_c('v-tab',{on:{"click":function($event){return _vm.onBrokerDashboardClick()}}},[_vm._v("Broker Dashboard")]):_vm._e(),(_vm.isBrokerUser)?_c('v-tab',{on:{"click":function($event){return _vm.onShipperDashboardClick()}}},[_vm._v("Shipper Dashboard")]):_vm._e(),_c('v-tab',{on:{"click":function($event){return _vm.onLaneDashboardClick()}}},[_vm._v("Lanes")])],1)],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"2"}},[_c('p',{ref:"daterange",staticClass:"text-end mb-0 pt-4 mt-3 mr-4 font-weight-light",style:({
                            color: _vm.theme == 'light' ? '#545454' : 'white'
                        })},[_vm._v(" "+_vm._s(_vm.periodDates)+" ")])])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"5"}},[_c('v-card-title',{staticClass:"font-weight-light"},[_c('v-icon',{staticStyle:{"margin-right":"10px"},attrs:{"large":"","color":_vm.$vuetify.theme.themes[_vm.theme].headerIcon}},[_vm._v("mdi-gauge")]),(!_vm.isLaneData)?_c('p',{staticClass:"ma-0"},[_vm._v("Dashboard")]):_c('p',{staticClass:"ma-0"},[_vm._v("Lanes")]),_c('v-spacer'),_vm._l((Array.from(
                                    _vm.filterChips
                                )),function(keyval,index){return _c('v-chip',{key:index,staticClass:"mr-2",attrs:{"color":_vm.$vuetify.theme.themes[_vm.theme].chipColor,"text-color":"white","close":""},on:{"click:close":function($event){return _vm.resetFilters(keyval[0])}}},[_vm._v(" "+_vm._s(keyval[1])+" ")])}),_c('Filters',{attrs:{"time_period":_vm.timePeriod,"volume_threshold":_vm.volumeThreshold,"is_lane_drilldown":!this.isLaneData,"is_analytics":false,"updateData":[
                                    this.filters,
                                    this.isComparisonEnabled
                                ]},on:{"applySelectedFilters":_vm.handleEmmittedFilters}})],2)],1)],1)],1),_c('v-row',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-card',{ref:"gaugeCard",staticStyle:{"height":"100%"},attrs:{"elevation":"5"}},[_c('v-card-title',{staticClass:"font-weight-light pb-0"},[_vm._v("Truce Score")]),_c('v-card-actions',{staticClass:"pb-1 pt-0"},[_c('Gauge',{attrs:{"averageScore":this.averageScore,"gaugeCardWidth":this.gaugeCardWidth,"browserZoom":this.browserZoom}})],1)],1)],1),_c('v-col',{attrs:{"cols":"5"}},[_c('KeyMetrics',{ref:"km",attrs:{"is_loading":_vm.isLoading,"key_metrics":_vm.key_metrics,"is_dashboard_km":true,"updateKeyMetrics":[_vm.keyMetrics],"show_agg_tabs":_vm.showAggTabs,"is_period_null":_vm.isPeriodNull,"is_comparison_enabled":_vm.isComparisonEnabled,"query_in_progress":_vm.queryInProgress,"key_metrics_toggle":_vm.keyMetricsToggle,"old_period_dates":_vm.oldPeriodDates,"period_dates":_vm.periodDates},on:{"periodTabChange":_vm.handleKeyMetricTabChange}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-card',{staticStyle:{"height":"100%"},attrs:{"elevation":"5"}},[_c('v-card-title',{staticClass:"font-weight-light pb-0 mtCardTitle",staticStyle:{"overflow-y":"hidden","white-space":"nowrap"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",staticStyle:{"z-index":"2"},attrs:{"cols":"lg-4 md-2"}},[_vm._v("Metric Trends")]),_c('v-col',{staticClass:"mtTabCol",attrs:{"cols":"lg-8 md-2"}},[_c('v-tabs',{staticClass:"metricTrendTabs",attrs:{"color":"#0091ff","right":"","show-arrows":""},model:{value:(_vm.metricTrendTypeTabs),callback:function ($$v) {_vm.metricTrendTypeTabs=$$v},expression:"metricTrendTypeTabs"}},[_c('v-tooltip',{staticStyle:{"margin-left":"auto"},attrs:{"top":"","id":"remove-v-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
return [_c('v-tab',_vm._g({attrs:{"disabled":_vm.disableMetricTrendCPM ||
                                                        _vm.disableMetricTrendMPL ||
                                                        _vm.disableMetricTrendVOL},on:{"click":function($event){return _vm.changeMetricTrend(
                                                            'CPM',
                                                            0
                                                        )}}},on),[_vm._v("S|CPM")])]}}])},[_c('span',[_vm._v("Customer Spend Per Mile and Broker Truck Cost Per Mile")])]),_c('v-divider',{attrs:{"vertical":"","inset":""}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                        var on = ref.on;
return [_c('v-tab',_vm._g({attrs:{"disabled":_vm.disableMetricTrendCPM ||
                                                        _vm.disableMetricTrendMPL ||
                                                        _vm.disableMetricTrendVOL},on:{"click":function($event){return _vm.changeMetricTrend(
                                                            'MPL',
                                                            1
                                                        )}}},on),[_vm._v("MPL")])]}}])},[_c('span',[_vm._v("Margin per Load")])]),_c('v-divider',{attrs:{"vertical":"","inset":""}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                        var on = ref.on;
return [_c('v-tab',_vm._g({attrs:{"disabled":_vm.disableMetricTrendCPM ||
                                                        _vm.disableMetricTrendMPL ||
                                                        _vm.disableMetricTrendVOL},on:{"click":function($event){return _vm.changeMetricTrend(
                                                            'VOL',
                                                            2
                                                        )}}},on),[_vm._v("VOL")])]}}])},[_c('span',[_vm._v("Volume")])])],1)],1)],1)],1),_c('v-card-actions',{staticClass:"justify-center pt-0"},[_c('Graph',{attrs:{"rawData":_vm.metricTrends,"graphName":_vm.metricTrendType,"timeRange":_vm.timePeriod,"isBroker":!_vm.isLaneData}})],1)],1)],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('TableBundle',{ref:"bundle",attrs:{"is_loading":false,"is_broker_user":_vm.isBrokerUser,"is_lane_drilldown":false,"is_lane_data":_vm.isLaneData,"displayed_data":_vm.displayedData,"has_incomplete_score":null,"time_period":_vm.timePeriod,"start_date":_vm.startDate,"end_date":_vm.endDate},on:{"parseURL":_vm.parseURLPathName,"handleFilters":_vm.handleEmmittedFilters,"focusLanes":_vm.handleFocusLanes,"laneClick":_vm.handleLaneClick}})],1)],1)],1),_c('v-dialog',{attrs:{"width":"90%","overlay-color":"black"},model:{value:(_vm.laneDrilldown),callback:function ($$v) {_vm.laneDrilldown=$$v},expression:"laneDrilldown"}},[_c('LaneDrilldown',{attrs:{"drilldown_object":_vm.drilldownObject,"prop_broker":_vm.isBrokerUser},on:{"stopLoading":function($event){_vm.isLoading = false}}})],1)],1),_c('SplashScreen',{staticStyle:{"z-index":"220"},attrs:{"is_loading":_vm.isLoading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }