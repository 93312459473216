import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        // User Info
        username: '',
        name: '',
        email: '',
        phone_number: '',
        company: '',
        role: '',

        crumbs: [],

        user_id: '',
        shipper_primary_ref_list: [],

        broker: '',
        lane: '',
        shipper: '',

        broker_list: [],
        shipper_list: [],
        lane_list: [],
        first_load: true,

        startDate: '',

        equipment_type_list: [
            'Dry Van',
            'Reefer',
            'Flatbed',
            'Power Only',
            'Straight Truck'
        ],

        // filters
        storeEndDate: new Date(),
        storeTimePeriod: '',
        storeVolumeThreshold: 0,
        storeDates: null,
        storeTimeSlider: 5,
        storeIsComparisonEnabled: false,
        storeIsTimePeriodDisabled: false,
        storeKeyMetricsToggle: null,
        storeIsCustomerDirectEnabled: false,

        crumbIds: ['dashboard'],

        favoriteLanes: new Map(),
        darkMode: false
    },

    mutations: {
        setStateProperty(state, prop) {
            state[prop.property] = prop.value;
        },

        addToStateList(state, prop) {
            state[prop.property].push(prop.value);
        },

        clearBrokerList(state) {
            state['broker_list'] = [];
        },

        clearLaneList(state) {
            state['lane_list'] = [];
        },

        clearShipperList(state) {
            state['shipper_list'] = [];
        }
    },

    plugins: [createPersistedState()]
});
