<template>
    <v-col cols="4" class="pt-0" style="height: 100%; margin-top: 5px">
        <v-autocomplete
            :filter="customFilter"
            :items="searchData"
            return-object
            v-model="selected"
            rounded
            dense
            chips
            solo
            :label="
                isBrokerUser
                    ? 'Search for shipper or lane!'
                    : 'Search for broker or lane!'
            "
            item-text="name"
            item-value="id"
            :background-color="this.$vuetify.theme.dark ? '#212121' : '#42A5F5'"
            multiple
            dark
        >
            <template v-slot:item="data">
                <template>
                    <template v-if="data.item.group == 'Broker'">
                        <v-icon
                            :color="$vuetify.theme.themes[theme].headerIcon"
                            style="margin-right: 10px"
                            >mdi-lan-connect</v-icon
                        >
                    </template>
                    <template v-else-if="data.item.group == 'Shipper'">
                        <v-icon
                            :color="$vuetify.theme.themes[theme].headerIcon"
                            style="margin-right: 10px"
                            >mdi-warehouse</v-icon
                        >
                    </template>
                    <template v-else>
                        <v-icon
                            :color="$vuetify.theme.themes[theme].headerIcon"
                            style="margin-right: 10px"
                            >mdi-state-machine</v-icon
                        >
                    </template>

                    <v-list-item-content>
                        <v-list-item-title
                            v-html="data.item.name"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                            v-if="data.item.group == 'Lane'"
                            v-html="data.item.equipmenttype"
                        ></v-list-item-subtitle>
                        <v-list-item-subtitle
                            v-else
                            v-html="data.item.group"
                        ></v-list-item-subtitle>
                    </v-list-item-content>
                </template>
            </template>
        </v-autocomplete>
    </v-col>
</template>
<script>
import * as stateAPI from '../stateAPI';
export default {
    name: 'SearchBar',
    computed: {
        theme() {
            return this.$vuetify.theme.dark ? 'dark' : 'light';
        }
    },
    data: function () {
        return {
            selected: [],
            searchData: [],
            unsubscribe: null,
            mutationCount: 0,
            isBrokerUser: stateAPI.getStateProperty(this, 'role') == 'broker'
        };
    },

    watch: {
        selected(val) {
            if (val) {
                let selectedObj = val[0];
                let name = selectedObj.name;
                let group = selectedObj.group;
                let id = selectedObj.id;
                let equipment_type = selectedObj.equipmenttype;
                this.navigate(name, group, id, equipment_type);
            }
        },
        searchData(val) {
            var url_string = window.location.href;
            var url = new URL(url_string);
            let pageId = '';
            const ids = ['broker', 'shipper', 'lane'].forEach((elem) => {
                if (url.searchParams.get(elem.toString())) {
                    pageId = elem;
                }
            });
            let id = stateAPI.getStateProperty(this, pageId.toString());
            for (const ind in val) {
                if (val[ind].id && val[ind].id === id) {
                    val.splice(ind, 1);
                }
            }
        }
    },

    methods: {
        navigate(selectedString, type, id, equipmentType) {
            if (type == 'Broker') {
                stateAPI.setStateProperty(this, 'broker', id);
                var url_broker_name = selectedString.replace(/ /g, '_');
                this.$router.push({
                    name: 'drilldown',
                    query: { broker: url_broker_name },
                    params: {}
                });
            } else if (type == 'Shipper') {
                stateAPI.setStateProperty(this, 'shipper', id);
                var url_shipper_name = selectedString.replace(/ /g, '_');
                this.$router.push({
                    name: 'drilldown',
                    query: { shipper: url_shipper_name },
                    params: {}
                });
            } else {
                stateAPI.setStateProperty(this, 'lane', id);
                var originwZip = selectedString.split('-->')[0];
                var destinationwZip = selectedString.split('-->')[1];

                var origin =
                    originwZip.split(',')[0] + ', ' + originwZip.split(',')[1];
                var destination =
                    destinationwZip.split(',')[0] +
                    ', ' +
                    destinationwZip.split(',')[1];

                var url_origin = origin.replace(/ /g, '_');
                var url_dest = destination.replace(/ /g, '_');
                this.$router.push({
                    name: 'lanedrilldown',
                    query: {
                        lane: url_origin + ' -> ' + url_dest,
                        equipment_type: equipmentType
                    },
                    params: {}
                });
            }
        },

        populate(mutation, state) {
            if (mutation == {}) {
                return;
            }
            if (
                mutation.payload != undefined &&
                mutation.payload.property == 'broker_list'
            ) {
                this.searchData = [{ header: 'Brokers' }]
                    .concat(mutation.payload.value)
                    .concat(this.searchData);
                this.mutationCount++;
            } else if (
                mutation.payload != undefined &&
                mutation.payload.property == 'shipper_list'
            ) {
                this.searchData = [{ header: 'Shippers' }]
                    .concat(mutation.payload.value)
                    .concat(this.searchData);
                this.mutationCount++;
            } else if (
                mutation.payload != undefined &&
                mutation.payload.property == 'lane_list'
            ) {
                this.searchData = this.searchData
                    .concat([{ divider: true }, { header: 'Lanes' }])
                    .concat(mutation.payload.value);
                this.mutationCount++;
            }
            if (this.mutationCount == 2) {
                this.unsubscribe();
                stateAPI.setStateProperty(this, 'first_load', false);
            }
        },

        customFilter(item, queryText, itemText) {
            const cleanedQuery = queryText
                .replace(/[&@\\/#?!|^_,`+=()$~%.'";:*?<>{}]/g, ' ')
                .toLocaleLowerCase();
            const queryTokens = cleanedQuery.split(' ');
            const fillers = ['to', 'and'];
            for (const token of queryTokens) {
                if (fillers.includes(token)) {
                    continue;
                }
                if (itemText.toLocaleLowerCase().indexOf(token) === -1) {
                    return false;
                }
            }
            return true;
        }
    },
    mounted: function () {
        if (stateAPI.getStateProperty(this, 'first_load')) {
            this.unsubscribe = stateAPI.subscribeMutation(this, this.populate);
        } else if (!this.isBrokerUser) {
            this.searchData = this.searchData
                .concat([{ header: 'Brokers' }])
                .concat(stateAPI.getStateProperty(this, 'broker_list'))
                .concat([{ divider: true }, { header: 'Lanes' }])
                .concat(stateAPI.getStateProperty(this, 'lane_list'));
        } else if (this.isBrokerUser) {
            this.searchData = this.searchData
                .concat([{ header: 'Shippers' }])
                .concat(stateAPI.getStateProperty(this, 'shipper_list'))
                .concat([{ divider: true }, { header: 'Lanes' }])
                .concat(stateAPI.getStateProperty(this, 'lane_list'));
        }
    }
};
</script>
<style>
.rotate-135 {
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
}
</style>
