<template>
    <div>
        <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-width="450"
            transition="slide-y-transition"
            offset-y
            :close-on-click="false"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    depressed
                    class="ma-4"
                    :color="$vuetify.theme.themes[theme].filterBtnBackground"
                    style="float: right; color: white"
                    v-bind="attrs"
                    v-on="on"
                    >Filters</v-btn
                >
            </template>

            <v-card elevation="0" rounded>
                <v-card-title class="pb-0"
                    >Filters
                    <v-spacer></v-spacer>
                    <v-subheader>
                        Aggregate Start Date:
                        <b class="ml-1">{{ startDate }}</b></v-subheader
                    >
                </v-card-title>
                <v-row class="pr-4 pl-4" align-center justify="center">
                    <v-col cols="12" align="center" justify="center">
                        <v-subheader class="pt-4 pr-1"
                            >Custom Date Range:
                            <v-checkbox
                                class="pl-1"
                                v-model="isTimePeriodDisabled"
                            ></v-checkbox>
                            <template v-if="!isAnalytics">
                                Comparison:
                                <v-checkbox
                                    class="pl-1"
                                    v-model="isComparisonEnabled"
                                ></v-checkbox>
                            </template>
                            <template v-if="!isAnalytics">
                                Customer Direct:
                                <v-checkbox
                                    class="pl-1"
                                    v-model="isCustomerDirectEnabled"
                                ></v-checkbox>
                            </template>
                        </v-subheader>
                    </v-col>
                </v-row>

                <v-row class="pr-4 pl-4" align-center justify="center">
                    <v-col cols="12" align="center" justify="center">
                        <v-date-picker
                            color="#FF6F00"
                            v-model="dates"
                            @input="datePicker = false"
                            :disabled="!isTimePeriodDisabled"
                            width="450"
                            range
                            :allowed-dates="allowedDates"
                        ></v-date-picker>
                    </v-col>
                </v-row>
                <v-row class="pr-12 pl-12 pt-3">
                    <v-col cols="12" align="center" justify="center">
                        <v-slider
                            v-model="timePeriod"
                            :tick-labels="tickLabels"
                            :max="5"
                            step="1"
                            ticks="always"
                            tick-size="5"
                            thumb-color="blue"
                            color="blue"
                            :disabled="isTimePeriodDisabled"
                            height="2"
                            class="mx-n10"
                        >
                        </v-slider>
                    </v-col>
                </v-row>

                <v-row class="pr-12 pl-12 mb-6" v-if="!isAnalytics">
                    <v-col cols="12">
                        <v-text-field
                            label="Volume Threshold"
                            :rules="volumeThresholdValidation"
                            hide-details="auto"
                            v-model="volumeThreshold"
                            type="number"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-subheader>
                    The equipment type filter is available in the lanes view
                    only.
                </v-subheader>
                <v-row class="pr-6 pl-6">
                    <v-col cols="12">
                        <v-select
                            v-model="selectedEquipmentTypes"
                            :items="EQUIPMENT_TYPE_LIST"
                            label="Equipment Type"
                            style="padding-top: 16px"
                            :disabled="isLaneDrilldown && !isAnalytics"
                            multiple
                        ></v-select>
                    </v-col>
                </v-row>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" text x-large @click="menu = false"
                        >Cancel</v-btn
                    >
                    <v-btn
                        color="green"
                        text
                        x-large
                        @click="
                            menu = false;
                            handleFilters();
                        "
                        >Apply</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-menu>
    </div>
</template>
<script>
import * as globalVariables from '../globalVariables';
import * as stateAPI from '../stateAPI';
import * as utils from '../utils';

export default {
    name: 'Filters',
    props: [
        'time_period',
        'updateData',
        'is_lane_drilldown',
        'volume_threshold',
        'is_analytics'
    ],
    watch: {
        updateData(val) {
            if (val[0] != undefined && val[0] != null) {
                this.dates = val[0].dates;
                this.timePeriod = val[0].timeSlider;
                this.volumeThreshold = parseInt(val[0].volume);
                this.selectedEquipmentTypes = val[0].equipment;
                this.isCustomerDirectEnabled = val[0].isCustomerDirectEnabled;
            }

            this.isComparisonEnabled = val[1];
        }
    },
    computed: {
        theme() {
            return this.$vuetify.theme.dark ? 'dark' : 'light';
        }
    },
    methods: {
        handleFilters() {
            var finalPeriod = null;
            var finalDate = new Date();

            if (!this.isTimePeriodDisabled) {
                this.dates = null;
                // handle all time on slider
                if (this.timePeriod == 5) {
                    const today = new Date(new Date().setHours(5, 0, 0));
                    const start = new Date(
                        new Date(this.startDate).setHours(5, 0, 0)
                    );
                    finalPeriod = (
                        (today.getTime() - start.getTime()) /
                            (24 * 60 * 60 * 1000) +
                        1
                    ).toFixed(0);
                } else {
                    // handle rest of period slider
                    finalPeriod = this.tickValues[this.timePeriod];
                }
            } else if (this.isTimePeriodDisabled && this.dates.length == 2) {
                this.timePeriod = 5;
                var d1 = this.dates[0].split('-');
                var d2 = this.dates[1].split('-');
                const firstSelected = new Date(
                    new Date(d1[0], d1[1] - 1, d1[2]).setHours(5, 0, 0)
                );
                const secondSelected = new Date(
                    new Date(d2[0], d2[1] - 1, d2[2]).setHours(5, 0, 0)
                );

                const date =
                    firstSelected <= secondSelected
                        ? secondSelected
                        : firstSelected;
                finalDate = new Date(new Date(date).setHours(5, 0, 0));
                var diff = (
                    (firstSelected.getTime() - secondSelected.getTime()) /
                        (24 * 60 * 60 * 1000) +
                    1
                ).toFixed(0);
                if (firstSelected < secondSelected) {
                    diff = (
                        (secondSelected.getTime() - firstSelected.getTime()) /
                            (24 * 60 * 60 * 1000) +
                        1
                    ).toFixed(0);
                }
                finalPeriod = Math.abs(diff);

                stateAPI.setStateProperty(this, 'storeDates', this.dates);
            } else if (this.isTimePeriodDisabled && this.dates.length == 1) {
                this.timePeriod = 5;
                var d = this.dates[0].split('-');
                const firstSelected = new Date(
                    new Date(d[0], d[1] - 1, d[2]).setHours(5, 0, 0)
                );
                finalDate = new Date(new Date(firstSelected).setHours(5, 0, 0));
                const difference = (
                    (firstSelected.getTime() - firstSelected.getTime()) /
                        (24 * 60 * 60 * 1000) +
                    1
                ).toFixed(0);
                finalPeriod = Math.abs(difference);

                stateAPI.setStateProperty(this, 'storeDates', this.dates);
            }

            //don't filter if it is all default
            if (
                finalDate == new Date().toISOString().substring(0, 10) &&
                this.timePeriod == 5 &&
                this.volumeThreshold <= 0 &&
                this.selectedEquipmentTypes == this.EQUIPMENT_TYPE_LIST
            ) {
                return;
            }

            if (
                this.volumeThreshold < 0 ||
                this.volumeThreshold == null ||
                this.volumeThreshold == undefined ||
                isNaN(this.volumeThreshold) ||
                this.volumeThreshold == ''
            ) {
                this.volumeThreshold = 0;
            }

            // fill filters object for tracking
            var filters = {};
            filters.dates = this.dates;
            filters.timeSlider = this.timePeriod;
            filters.volume = this.volumeThreshold;
            filters.equipment = this.selectedEquipmentTypes;
            filters.isCustomerDirectEnabled = this.isCustomerDirectEnabled;

            stateAPI.setStateProperty(
                this,
                'storeIsTimePeriodDisabled',
                this.isTimePeriodDisabled
            );

            stateAPI.setStateProperty(this, 'storeTimeSlider', this.timePeriod);

            this.$emit(
                'applySelectedFilters',
                finalDate,
                parseInt(finalPeriod) - 1,
                this.volumeThreshold,
                this.selectedEquipmentTypes,
                this.isComparisonEnabled,
                filters
            );
        },
        allowedDates(dateSelected) {
            const date = new Date(new Date(dateSelected).setHours(5, 0, 0));
            const today = new Date(new Date().setHours(5, 0, 0));
            const timePeriod = (
                (today.getTime() - date.getTime()) /
                (24 * 60 * 60 * 1000)
            ).toFixed(0);
            return timePeriod > 0;
        }
    },

    data: function () {
        return {
            isLaneDrilldown: this.is_lane_drilldown,
            selectedEquipmentTypes: stateAPI.getStateProperty(
                this,
                'equipment_type_list'
            ),
            // EQUIPMENT_TYPE_LIST: globalVariables.default.equipment_type_list,
            EQUIPMENT_TYPE_LIST: stateAPI.getStateProperty(
                this,
                'equipment_type_list'
            ),
            timePeriod: stateAPI.getStateProperty(this, 'storeTimeSlider'),
            tickLabels: [
                'Last 7',
                'Last 14',
                'Last 30',
                'Last 60',
                'Last 90',
                'All'
            ],
            tickValues: [7, 14, 30, 60, 90],
            // dates: [
            //   new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            //     .toISOString()
            //     .substr(0, 10),
            // ],
            dates: null,
            menu: false,
            datePicker: false,
            // Chips v-model indexed by filter type (e.g. "equipment_Dry Van") with value (e.g. "Equip. Type: Dry Van").
            volumeThreshold: stateAPI.getStateProperty(
                this,
                'storeVolumeThreshold'
            ),
            volumeThresholdValidation: [
                (value) => value > -1 || 'Must input a positive number'
            ],
            startDate: new Date(
                stateAPI.getStateProperty(this, 'startDate')
            ).toDateString(),
            isTimePeriodDisabled: stateAPI.getStateProperty(
                this,
                'storeIsTimePeriodDisabled'
            ),
            isComparisonEnabled: stateAPI.getStateProperty(
                this,
                'storeIsComparisonEnabled'
            ),
            isAnalytics: this.is_analytics,
            isCustomerDirectEnabled: stateAPI.getStateProperty(
                this,
                'storeIsCustomerDirectEnabled'
            )
        };
    }
};
</script>
<style>
.v-input--slider .v-slider__ticks > span {
    font-size: 20px;
    font-weight: bold;
    transform: translate(-50%);
}
.v-input--slider.v-input--slider--ticks-labels .v-input__slot {
    margin-bottom: 10;
}
</style>
