var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{style:({
        background: _vm.$vuetify.theme.themes[_vm.theme].background,
        height: '100vh'
    }),attrs:{"fluid":"","dense":""}},[_c('v-row',{staticClass:"align-center pl-4",attrs:{"dense":""}},[_c('v-icon',{staticClass:"mr-4",attrs:{"large":"","color":_vm.$vuetify.theme.themes[_vm.theme].headerIcon}},[_vm._v("mdi-state-machine")]),[_vm._v(" "+_vm._s(_vm.lane_equipment_type + ': ' + _vm.laneName)+" ")],_c('v-spacer'),_vm._l((Array.from(_vm.filterChips)),function(keyval,index){return _c('v-chip',{key:index,staticClass:"mr-2",attrs:{"color":"#FF6F00","text-color":"white","close":""},on:{"click:close":function($event){return _vm.resetFilters(keyval[0])}}},[_vm._v(" "+_vm._s(keyval[1])+" ")])}),_c('v-autocomplete',{staticClass:"pl-2",staticStyle:{"max-width":"300px"},attrs:{"filter":_vm.customFilter,"items":_vm.dropdownSBList,"label":'Search for a ' + (!_vm.isBrokerUser ? 'Broker' : 'Shipper'),"hide-details":"","dense":"","item-text":"name","item-value":"id"},on:{"input":_vm.searchselectedSB},model:{value:(_vm.selectedSB),callback:function ($$v) {_vm.selectedSB=$$v},expression:"selectedSB"}},[[_c('v-icon',{staticStyle:{"margin-right":"10px"},attrs:{"color":_vm.$vuetify.theme.themes[_vm.theme].headerIcon}},[_vm._v("mdi-lan-connect")])]],2),_c('Filters',{attrs:{"time_period":_vm.timePeriod,"volume_threshold":_vm.volumeThreshold,"is_lane_drilldown":true,"is_analytics":true,"updateData":[this.filters, this.isComparisonEnabled]},on:{"applySelectedFilters":_vm.handleEmmittedFilters}})],2),_c('v-row',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-card',{ref:"gaugeCard",staticStyle:{"height":"100%"},attrs:{"elevation":"5"}},[_c('v-card-text',{staticClass:"d-flex flex-column align-center justify-center",staticStyle:{"height":"100%"}},[_c('div',{staticClass:"text-h1",style:(("display: flex; align-items: center; margin-bottom:14px; color:" + (_vm.getScoreColor(
                            this.averageScore
                        ))))},[_vm._v(" "+_vm._s(_vm.formatDecimal(this.averageScore, 0))+" ")]),_c('p',{staticStyle:{"font-size":"16px","margin-bottom":"0px"}},[_vm._v(" Truce Score ")])])],1)],1),_c('v-col',{attrs:{"cols":"10"}},[_c('KeyMetrics',{ref:"km",attrs:{"is_loading":_vm.isLoading,"key_metrics":_vm.key_metrics,"is_dashboard_km":false,"updateKeyMetrics":[_vm.keyMetrics],"show_agg_tabs":_vm.showAggTabs,"is_period_null":_vm.isPeriodNull,"is_comparison_enabled":_vm.isComparisonEnabled,"query_in_progress":_vm.queryInProgress,"key_metrics_toggle":_vm.keyMetricsToggle,"old_period_dates":_vm.oldPeriodDates,"period_dates":_vm.periodDates},on:{"periodTabChange":_vm.handleKeyMetricTabChange}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{staticStyle:{"overflow":"hidden"},attrs:{"elevation":"5","height":"100%"}},[_c('v-card-title',{staticClass:"font-weight-light pb-0",staticStyle:{"white-space":"nowrap"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0 lg-4 md-2",attrs:{"cols":"4"}},[_vm._v("Spend and Truck Cost")]),_c('v-spacer'),_c('v-col',{staticClass:"text-right pr-0",attrs:{"cols":"6"}},[_c('v-btn',{class:(_vm.costSpendGraphIndex === 0
                                        ? 'selectedTab'
                                        : '') + ' text-none',staticStyle:{"vertical-align":"top"},attrs:{"plain":"","ripple":false},on:{"click":function($event){return _vm.changeCostSpendGraph('LINE')}}},[_vm._v("Time")]),_c('v-divider',{staticClass:"border-opacity-50",attrs:{"vertical":""}}),_c('v-btn',{class:(_vm.costSpendGraphIndex === 1
                                        ? 'selectedTab'
                                        : '') + ' text-none',staticStyle:{"vertical-align":"top"},attrs:{"plain":"","ripple":false},on:{"click":function($event){return _vm.changeCostSpendGraph('BAR')}}},[_vm._v("Day of Week")])],1)],1)],1),_c('v-card-actions',{staticClass:"justify-center graphSlide"},[_c('Graph',{staticClass:"costSpendGraph pr-2",attrs:{"rawData":_vm.costSpendVolumeData,"graphName":"costSpendLine","timeRange":_vm.timePeriod,"isBroker":false}}),_c('Graph',{staticClass:"costSpendGraph pr-2",attrs:{"rawData":_vm.costVolumeBarData,"graphName":"costSpendBar","timeRange":_vm.timePeriod,"isBroker":false}})],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{staticStyle:{"overflow":"hidden"},attrs:{"elevation":"5","height":"100%"}},[_c('v-card-title',{staticClass:"font-weight-light pb-0",staticStyle:{"white-space":"nowrap"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0 lg-4 md-2",attrs:{"cols":"4"}},[_vm._v("Volume")]),_c('v-spacer'),_c('v-col',{staticClass:"text-right pr-0",attrs:{"cols":"6"}},[_c('v-btn',{class:(_vm.volumeGraphIndex === 0
                                        ? 'selectedTab'
                                        : '') + ' text-none',staticStyle:{"vertical-align":"top"},attrs:{"plain":"","ripple":false},on:{"click":function($event){return _vm.changeVolumeGraph('LINE')}}},[_vm._v("Time")]),_c('v-divider',{staticClass:"border-opacity-50",attrs:{"vertical":""}}),_c('v-btn',{class:(_vm.volumeGraphIndex === 1
                                        ? 'selectedTab'
                                        : '') + ' text-none',staticStyle:{"vertical-align":"top"},attrs:{"plain":"","ripple":false},on:{"click":function($event){return _vm.changeVolumeGraph('BAR')}}},[_vm._v("Day of Week")])],1)],1)],1),_c('v-card-actions',{staticClass:"justify-center pt-0 graphSlide"},[_c('Graph',{staticClass:"volumeGraph pr-2",attrs:{"rawData":_vm.costSpendVolumeData,"graphName":"volumeLine","timeRange":_vm.timePeriod,"isBroker":false}}),_c('Graph',{staticClass:"volumeGraph pr-2",attrs:{"rawData":_vm.costVolumeBarData,"graphName":"volumeBar","timeRange":_vm.timePeriod,"isBroker":false}})],1)],1)],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('TableBundle',{ref:"bundle",attrs:{"is_loading":false,"is_broker_user":_vm.isBrokerUser,"is_lane_drilldown":true,"is_lane_data":_vm.isLaneData,"displayed_data":_vm.displayedData,"has_incomplete_score":_vm.hasIncompleteScore,"time_period":_vm.timePeriod,"start_date":_vm.startDate,"end_date":_vm.endDate,"customer_direct_enabled":_vm.isCustomerDirectEnabled},on:{"parseURL":_vm.queryForNewData,"handleFilters":_vm.handleEmmittedFilters,"focusLanes":_vm.handleFocusLanes,"favoriteLanes":_vm.handleFavoriteLanes}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }