import axios from 'axios';
import aws4 from 'aws4';
import { isProdEnv, isDevEnv } from '../utils';

export async function sendEvent(
    email,
    company,
    role,
    eventType,
    attributes = {}
) {
    /*
    Makes a call to the user_analytics lambda, which then records the entry in a Timestream

    Valid eventTypes and {attributes} are listed below:
        login,
        pageView: {pageSource (BrokerDashboard, ShipperDashboard, LaneDashboard, BrokerDrilldown, ShipperDrilldown, LaneDrilldown, Calculator, Analytics, Account, Notifications)},
        ...'
    No validation is currently implemented
    */

    const currentUrl = window.location.href;

    if (isProdEnv()) {
        const currentUrl = window.location.href;
        if (company === undefined || company === '') {
            company = 'N/A';
        }
        const body = {
            event_params: {
                event_name: eventType,
                email: email,
                company: company,
                role: role,
                custom_event_attributes: attributes,
                event_time: Date.now().toString()
            }
        };
        const request = {
            host: 'h4fiko3zph.execute-api.us-east-1.amazonaws.com',
            method: 'PUT',
            url: 'https://h4fiko3zph.execute-api.us-east-1.amazonaws.com/ua-2/putAnalyticsEvent',
            data: body,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Request-Method': 'PUT'
            }
        };

        let signedRequest = aws4.sign(request, {
            // assumes user has authenticated and we have called
            // AWS.config.credentials.get to retrieve keys and
            // session tokens
            secretAccessKey: '',
            accessKeyId: ''
        });

        delete signedRequest.headers['Host'];
        let response = axios(signedRequest).then((result) => {
            return result;
        });
        return response;
    } else {
        return 'Not in prod, event not sent';
    }
}
