<template>
    <div>
        <v-row class="justify-center" style="color: '#545454'">
            We'll send a verification code to your email.
        </v-row>
        <v-row>
            <v-col cols="8">
                <v-text-field
                    prepend-inner-icon="mdi-account"
                    label="Email"
                    :rules="[rules.required]"
                    :value="email"
                    v-model="email"
                    @input="emitEmail"
                ></v-text-field>
            </v-col>
            <v-col cols="4">
                <v-btn
                    class="mt-3"
                    @click="sendResetEmail"
                    color="#0091ff"
                    outlined
                >
                    Send Email
                </v-btn>
            </v-col>
        </v-row>
        <p v-if="email_sent" style="color: red">
            We've sent you an email with your verification code.
        </p>
    </div>
</template>

<script>
import { Auth } from 'aws-amplify';
export default {
    name: 'ForgotPassword',

    data: function () {
        return {
            rules: {
                required: (value) => !!value || 'Required.',
                min: (v) => v.length >= 8 || 'Min 8 characters',
                emailMatch: () =>
                    `The email and password you entered don't match`
            },

            email: '',
            email_sent: false
        };
    },

    methods: {
        sendResetEmail() {
            if (this.email.length == 0) {
                this.emitError();
                return;
            }

            if (!this.email.includes('@')) {
                this.emitError();
                return;
            }

            Auth.forgotPassword(this.email)
                .catch((err) => console.log("Error Occurred"));

            this.email_sent = true;
            this.emitSuccess();
        },

        emitEmail() {
            this.$emit('emailUpdate', this.email);
        },

        emitError() {
            this.$emit('error', true);
        },

        emitSuccess() {
            this.$emit('emailSent', this.email_sent);
        }
    }
};
</script>

<style>
.link {
    font-size: 13px;
    color: grey;
}

.forgot-password {
    float: right;
    cursor: pointer;
}
</style>
